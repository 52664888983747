

const territoryKeys = {
  NameKey: "territoryName",
  ValueKey: "territoryNumber",
  Value: "territoryNumber"
};

export const FILTER_HIERARCHY_CONFIG = {
  territories: {
    parents: [],
    children: ["regions", "districts", "stores"]
  },
  regions: {
    parents: ["territories"],
    children: ["districts", "stores"]
  },
  districts: {
    parents: ["regions", "territories"],
    children: ["stores"]
  },
  stores: {
    parents: ["districts", "regions", "territories"],
    children: []
  }
};

const companyKeys = {
  NameKey: "companyName",
  ValueKey: "companyNumber",
  Value: "companyNumber"
};

const volumeKeys = {
  NameKey: "volumeName",
  ValueKey: "volumeNumber",
  Value: "volumeNumber"
};

interface IGeoKey {
  NameKey: string;
  ValueKey: string;
  Value: string;
}
interface IGeoKeys {
  [anyKey: string]: IGeoKey;
}
export const GEOKEYS: IGeoKeys = {
  company: companyKeys,
  volumes: volumeKeys,
  territory: territoryKeys,
  territories: territoryKeys,
  regions: {
    NameKey: "regionName",
    ValueKey: "regionNumber",
    Value: "regionNumber"
  },
  districts: {
    NameKey: "districtName",
    ValueKey: "districtNumber",
    Value: "districtNumber"
  },
  stores: {
    NameKey: "storeName",
    ValueKey: "storeNumber",
    Value: "storeNumber"
  }
};

export const AGGREGATION_OPTION_VALUES = {
  Company: 'company',
  Volume: "volumes",
  Territory: "territories",
  Region: "regions",
  District: "districts",
  Store: "stores"
};

export const AGGREGATION_OPTIONS = [
  {
    label: "By Territory",
    value: AGGREGATION_OPTION_VALUES.Territory
  },
  {
    label: "By Region",
    value: AGGREGATION_OPTION_VALUES.Region
  },
  {
    label: "By District",
    value: AGGREGATION_OPTION_VALUES.District
  },
  {
    label: "By Store",
    value: AGGREGATION_OPTION_VALUES.Store
  }
];

export const AGGREGATION_OPTIONS_WITH_VOLUME = [
  {
    label: "By Volume",
    value: AGGREGATION_OPTION_VALUES.Volume
  },
  ...AGGREGATION_OPTIONS
];

export const AGGREGATION_OPTIONS_BY_COMPANY = [
  {
    label: "By Company",
    value: AGGREGATION_OPTION_VALUES.Company
  },
  ...AGGREGATION_OPTIONS
];

export const LOCATION_LABEL = {
  'company': "Company",
  'territories': "Territory",
  'regions': 'Region',
  'districts': 'District',
  'stores': 'Store'
};

export enum AgingVariant {
  None = '',
  Low = 'low',
  Medium = 'medium',
  High = 'high'
}

export interface IAgingItem {
  key: string;
  label: string;
  variant: AgingVariant;
}

export interface IAgingConfig extends IFieldConfig {
  children: IAgingItem[];
}

export interface IFieldConfig {
  label: string;
  metricInfo?: string;
  showVariation?: boolean;
  showLastYear?: boolean;
  valueFormat?: ValueFormat;
  hasDropdown?: boolean;
  hasViewDetails?: boolean;
  viewDetailsModalTitle?: string;
  children?: any;
  hideOnNull?: boolean;
}

interface IFieldsConfig {
  [anyProp: string]: IFieldConfig;
}

type IFieldsConfigExt = IFieldsConfig & {
  aging?: IAgingConfig;
};

export interface IFieldsByView {
  UnitsView: IFieldsConfigExt;
  AmountView: IFieldsConfigExt;
}

interface IAllFieldsConfig {
  Total: IFieldsByView;
  Sfs: IFieldsByView;
  Bopus: IFieldsByView;
  Boss: IFieldsByView;
  OnlinePickup: IFieldsByView;
}

interface IHealthStatusConstants {
  FieldsConfig: IAllFieldsConfig;
}

export enum ValueFormat {
  NONE = "NONE",
  DOLLARS = "DOLLARS",
  PERCENT = "PERCENT",
  DAYS = "DAYS",
  HOURS = "HOURS",
  UNITS = "UNITS"
}

export const HealthStatusDashboardConstants: IHealthStatusConstants = {
  FieldsConfig: {
    Total: {
      UnitsView: {
        valueInQueue: {
          label: "Units in Queue",
          showLastYear: true,
          showVariation: true
        },
        totalDemandActual: {
          label: "Actual",
          showLastYear: true,
          showVariation: true
        },
        totalDemandPlan: {
          label: "Plan"
        },
        totalDemandProjected: {
          label: "Projected"
        },
        totalProcessedActual: {
          label: "Actual",
          showLastYear: true,
          showVariation: true
        },
        totalProcessedPlan: {
          label: "Plan"
        },
        totalProcessedProjected: {
          label: "Projected"
        }
      },
      AmountView: {
        valueInQueue: {
          label: "Dollars in Queue",
          showLastYear: true,
          showVariation: true,
          valueFormat: ValueFormat.DOLLARS
        },
        totalDemandActual: {
          label: "Actual",
          showLastYear: true,
          showVariation: true,
          valueFormat: ValueFormat.DOLLARS
        },
        totalDemandPlan: {
          label: "Plan",
          valueFormat: ValueFormat.DOLLARS
        },
        totalDemandProjected: {
          label: "Projected",
          valueFormat: ValueFormat.DOLLARS
        },
        totalProcessedActual: {
          label: "Actual",
          showLastYear: true,
          showVariation: true,
          valueFormat: ValueFormat.DOLLARS
        },
        totalProcessedPlan: {
          label: "Plan",
          valueFormat: ValueFormat.DOLLARS
        },
        totalProcessedProjected: {
          label: "Projected",
          valueFormat: ValueFormat.DOLLARS
        }
      }
    },
    Sfs: {
      UnitsView: {
        valueInQueue: {
          label: "Units in Queue",
          showLastYear: true,
          showVariation: true
        },
        shippedIn1Day: {
          label: "Shipped in <1 Day",
          showLastYear: true,
          showVariation: true,
          valueFormat: ValueFormat.PERCENT,
          metricInfo:
            "The % of units processed within 1 day of being received. Calculation: # of units processed within 1 day / total number of units processed."
        },
        backlog: {
          label: "Backlog",
          showLastYear: true,
          showVariation: true,
          valueFormat: ValueFormat.DAYS,
          metricInfo:
            "Estimated # of days to work current backlog to zero units. Estimated SFS Backlog in Days TY = Projected number of days to work current backlog at projected processing plan* 3 day processed to plan trend.",
          hideOnNull: true
        },
        aging: {
          label: "Units Aging",
          metricInfo: "Indicates exactly how long ago SFS units were received.",
          children: [{
            key: "in48Hours",
            label: "+48hrs",
            variant: AgingVariant.High
          },
          {
            key: "in24Hours",
            label: "+24hrs",
            variant: AgingVariant.Medium
          },
          {
            key: "in18Hours",
            label: "+18hrs",
            variant: AgingVariant.Low
          },
          {
            key: "in12Hours",
            label: "+12hrs",
            variant: AgingVariant.None
          }]
        },
        actualUph: {
          label: "Units per hour",
          showLastYear: true,
          showVariation: true
        },
        resourceValue: {
          label: "Resource Units",
          showLastYear: true,
          showVariation: true,
          hasViewDetails: true,
          viewDetailsModalTitle: "Resource Reason"
        },
        resourceRate: {
          label: "Resource Rate",
          showLastYear: true,
          showVariation: true,
          hasViewDetails: true,
          viewDetailsModalTitle: "Resource Reason",
          metricInfo:
            "Measures % of SFS units that could not be fulfilled by the store. Calculation: # of units resourced / (# of units resourced + # of units processed)",
          valueFormat: ValueFormat.PERCENT
        },
        deptResourceRate: {
          label: "Dept. Resource Rate",
          valueFormat: ValueFormat.PERCENT,
          hasDropdown: true
        },
        cancelRate: {
          label: "Cancel Rate",
          showLastYear: true,
          showVariation: true,
          valueFormat: ValueFormat.PERCENT
        },
        demandActual: {
          label: "Actual",
          showLastYear: true,
          showVariation: true
        },
        demandPlan: {
          label: "Plan"
        },
        demandProjected: {
          label: "Projected"
        },
        processedActual: {
          label: "Actual",
          showLastYear: true,
          showVariation: true
        },
        processedPlan: {
          label: "Plan"
        },
        processedProjected: {
          label: "Projected"
        }
      },
      AmountView: {
        valueInQueue: {
          label: "Dollars in Queue",
          valueFormat: ValueFormat.DOLLARS,
          showLastYear: true,
          showVariation: true
        },
        shippedIn1Day: {
          label: "Shipped in <1 Day",
          showLastYear: true,
          showVariation: true,
          valueFormat: ValueFormat.PERCENT,
          metricInfo:
            "The % of units processed within 1 day of being received. Calculation: # of units processed within 1 day / total number of units processed."
        },
        backlog: {
          label: "Backlog",
          showLastYear: true,
          showVariation: true,
          valueFormat: ValueFormat.DAYS,
          hideOnNull: true
        },
        aging: {
          label: "Dollars Aging",
          valueFormat: ValueFormat.DOLLARS,
          children: [{
            key: "in48Hours",
            label: "+48hrs",
            variant: AgingVariant.High
          },
          {
            key: "in24Hours",
            label: "+24hrs",
            variant: AgingVariant.Medium
          },
          {
            key: "in18Hours",
            label: "+18hrs",
            variant: AgingVariant.Low
          },
          {
            key: "in12Hours",
            label: "+12hrs",
            variant: AgingVariant.None
          }]
        },
        actualUph: {
          label: "Units per hour",
          showLastYear: true,
          showVariation: true
        },
        resourceValue: {
          label: "Resource Dollars",
          showLastYear: true,
          showVariation: true,
          valueFormat: ValueFormat.DOLLARS,
          hasViewDetails: true,
          viewDetailsModalTitle: "Resource Reason"
        },
        resourceRate: {
          label: "Resource Rate",
          showLastYear: true,
          showVariation: true,
          hasViewDetails: true,
          viewDetailsModalTitle: "Resource Reason",
          valueFormat: ValueFormat.PERCENT,
          metricInfo:
            "Measures % of SFS units that could not be fulfilled by the store. Calculation: # of units resourced / (# of units resourced + # of units processed)"
        },
        deptResourceRate: {
          label: "Dept. Resource Rate",
          valueFormat: ValueFormat.PERCENT,
          hasDropdown: true
        },
        cancelRate: {
          label: "Cancel Rate",
          showLastYear: true,
          showVariation: true,
          valueFormat: ValueFormat.PERCENT
        },
        demandActual: {
          label: "Actual",
          valueFormat: ValueFormat.DOLLARS,
          showLastYear: true,
          showVariation: true
        },
        demandPlan: {
          label: "Plan",
          valueFormat: ValueFormat.DOLLARS
        },
        demandProjected: {
          label: "Projected",
          valueFormat: ValueFormat.DOLLARS
        },
        processedActual: {
          label: "Actual",
          valueFormat: ValueFormat.DOLLARS,
          showLastYear: true,
          showVariation: true
        },
        processedPlan: {
          label: "Plan",
          valueFormat: ValueFormat.DOLLARS
        },
        processedProjected: {
          label: "Projected",
          valueFormat: ValueFormat.DOLLARS
        }
      }
    },
    Bopus: {
      UnitsView: {
        valueInQueue: {
          label: "Units in Queue",
          showLastYear: true,
          showVariation: true
        },
        processedIn1Hour: {
          label: "Processed in <1 Hour",
          showLastYear: true,
          showVariation: true,
          metricInfo:
            "Measures % of BOPUS units that were processed in under 1 hour. Calculation: # of units processed <1 hour/total # of units processed.",
          valueFormat: ValueFormat.PERCENT
        },
        aging: {
          label: "Units Aging",
          metricInfo:
            "Indicates exactly how long ago BOPUS units were received.",
          children: [{
            key: "in12Hours",
            label: "+12hrs",
            variant: AgingVariant.Medium
          },
          {
            key: "in4Hours",
            label: "+4hrs",
            variant: AgingVariant.Low
          },
          {
            key: "in1Hour",
            label: "+1hr",
            variant: AgingVariant.None
          }]
        },
        actualUph: {
          label: "Units per hour",
          showLastYear: true,
          showVariation: true
        },
        cancelValue: {
          label: "Cancel Units",
          showLastYear: true,
          showVariation: true
        },
        cancelRate: {
          label: "Cancel Rate",
          showLastYear: true,
          showVariation: true,
          metricInfo:
            "	Measures % of BOPUS units that could not be fulfilled by the store. BOPUS % Canceled = # of units canceled/ (# of units canceled + # of units processed)",
          valueFormat: ValueFormat.PERCENT,
          hasViewDetails: true,
          viewDetailsModalTitle: "Canceled Reason"
        },
        deptCancelRate: {
          label: "Dept. Cancel Rate",
          hasDropdown: true,
          valueFormat: ValueFormat.PERCENT
        },
        demandActual: {
          label: "Actual",
          showLastYear: true,
          showVariation: true
        },
        demandPlan: {
          label: "Plan"
        },
        demandProjected: {
          label: "Projected"
        },
        processedActual: {
          label: "Actual",
          showLastYear: true,
          showVariation: true
        },
        processedPlan: {
          label: "Plan"
        },
        processedProjected: {
          label: "Projected"
        }
      },
      AmountView: {
        valueInQueue: {
          label: "Dollars in Queue",
          showLastYear: true,
          showVariation: true,
          valueFormat: ValueFormat.DOLLARS
        },
        processedIn1Hour: {
          label: "Processed in <1 Hour",
          showLastYear: true,
          showVariation: true,
          metricInfo:
            "Measures % of BOPUS units that were processed in under 1 hour. Calculation: # of units processed <1 hour/total # of units processed.",
          valueFormat: ValueFormat.PERCENT
        },
        aging: {
          label: "Dollars Aging",
          metricInfo:
            "Indicates exactly how long ago BOPUS units were received.",
          valueFormat: ValueFormat.DOLLARS,
          children: [{
            key: "in12Hours",
            label: "+12hrs",
            variant: AgingVariant.Medium
          },
          {
            key: "in4Hours",
            label: "+4hrs",
            variant: AgingVariant.Low
          },
          {
            key: "in1Hour",
            label: "+1hr",
            variant: AgingVariant.None
          }]
        },
        actualUph: {
          label: "Units per hour",
          showLastYear: true,
          showVariation: true,
          metricInfo: "This is a metric info for actual UPH"
        },
        cancelValue: {
          label: "Cancel Dollars",
          showLastYear: true,
          showVariation: true,
          valueFormat: ValueFormat.DOLLARS
        },
        cancelRate: {
          label: "Cancel Rate",
          showLastYear: true,
          showVariation: true,
          metricInfo:
            "	Measures % of BOPUS units that could not be fulfilled by the store. BOPUS % Canceled = # of units canceled/ (# of units canceled + # of units processed)",
          valueFormat: ValueFormat.PERCENT,
          hasViewDetails: true,
          viewDetailsModalTitle: "Canceled Reason"
        },
        deptCancelRate: {
          label: "Dept. Cancel Rate",
          hasDropdown: true,
          valueFormat: ValueFormat.PERCENT
        },
        demandActual: {
          label: "Actual",
          showLastYear: true,
          showVariation: true,
          valueFormat: ValueFormat.DOLLARS
        },
        demandPlan: {
          label: "Plan",
          valueFormat: ValueFormat.DOLLARS
        },
        demandProjected: {
          label: "Projected",
          valueFormat: ValueFormat.DOLLARS
        },
        processedActual: {
          label: "Actual",
          showLastYear: true,
          showVariation: true,
          valueFormat: ValueFormat.DOLLARS
        },
        processedPlan: {
          label: "Plan",
          valueFormat: ValueFormat.DOLLARS
        },
        processedProjected: {
          label: "Projected",
          valueFormat: ValueFormat.DOLLARS
        }
      }
    },
    Boss: {
      UnitsView: {
        valueInQueue: {
          label: "Units in Queue",
          showLastYear: true,
          showVariation: true
        },
        processedIn6Hours: {
          label: "Processed in <6 Hours",
          showLastYear: true,
          showVariation: true,
          metricInfo:
            "Measures % of BOSS units that were processed on time. BOSS % Processed < 6 Hrs = # of units processed < 6 hours/ total # units processed)",
          valueFormat: ValueFormat.PERCENT
        },
        aging: {
          label: "Units Aging",
          metricInfo: "Indicates exactly how long ago BOSS units were received",
          children: [{
            key: "in6Hours",
            label: "+6hrs",
            variant: AgingVariant.None
          }]
        },
        actualUph: {
          label: "Units per hour",
          showLastYear: true,
          showVariation: true,
          metricInfo: "This is a metric info for actual UPH"
        },
        cancelValue: {
          label: "Cancel Units",
          showLastYear: true,
          showVariation: true
        },
        cancelRate: {
          label: "Cancel Rate",
          showLastYear: true,
          showVariation: true,
          valueFormat: ValueFormat.PERCENT,
          hasViewDetails: true,
          viewDetailsModalTitle: "Canceled Reason"
        },
        deptCancelRate: {
          label: "Dept. Cancel Rate",
          hasDropdown: true,
          valueFormat: ValueFormat.PERCENT
        },
        demandActual: {
          label: "Actual",
          showLastYear: true,
          showVariation: true
        },
        demandPlan: {
          label: "Plan"
        },
        demandProjected: {
          label: "Projected"
        },
        processedActual: {
          label: "Actual",
          showLastYear: true,
          showVariation: true
        },
        processedPlan: {
          label: "Plan"
        },
        processedProjected: {
          label: "Projected"
        },
        valueInTransit: {
          label: "In Transit",
          hideOnNull: true
        }
      },
      AmountView: {
        valueInQueue: {
          label: "Dollars in Queue",
          showLastYear: true,
          showVariation: true,
          valueFormat: ValueFormat.DOLLARS
        },
        processedIn6Hours: {
          label: "Processed in <6 Hours",
          showLastYear: true,
          showVariation: true,
          metricInfo:
            "Measures % of BOSS units that were processed on time. BOSS % Processed < 6 Hrs = # of units processed < 6 hours/ total # units processed)",
          valueFormat: ValueFormat.PERCENT
        },
        aging: {
          label: "Dollars Aging",
          metricInfo: "Indicates exactly how long ago BOSS units were received",
          valueFormat: ValueFormat.DOLLARS,
          children: [{
            key: "in6Hours",
            label: "+6hrs",
            variant: AgingVariant.None
          }]
        },
        actualUph: {
          label: "Units per hour",
          showLastYear: true,
          showVariation: true,
          metricInfo: "This is a metric info for actual UPH"
        },
        cancelValue: {
          label: "Cancel Dollars",
          showLastYear: true,
          showVariation: true,
          valueFormat: ValueFormat.DOLLARS
        },
        cancelRate: {
          label: "Cancel Rate",
          showLastYear: true,
          showVariation: true,
          valueFormat: ValueFormat.PERCENT,
          hasViewDetails: true,
          viewDetailsModalTitle: "Canceled Reason"
        },
        deptCancelRate: {
          label: "Dept. Cancel Rate",
          hasDropdown: true,
          valueFormat: ValueFormat.PERCENT
        },
        demandActual: {
          label: "Actual",
          valueFormat: ValueFormat.DOLLARS,
          showLastYear: true,
          showVariation: true
        },
        demandPlan: {
          label: "Plan",
          valueFormat: ValueFormat.DOLLARS
        },
        demandProjected: {
          label: "Projected",
          valueFormat: ValueFormat.DOLLARS
        },
        processedActual: {
          label: "Actual",
          valueFormat: ValueFormat.DOLLARS,
          showLastYear: true,
          showVariation: true
        },
        processedPlan: {
          label: "Plan",
          valueFormat: ValueFormat.DOLLARS
        },
        processedProjected: {
          label: "Projected",
          valueFormat: ValueFormat.DOLLARS
        },
        valueInTransit: {
          label: "In Transit",
          valueFormat: ValueFormat.DOLLARS,
          hideOnNull: true
        }
      }
    },
    OnlinePickup: {
      UnitsView: {
        onlinePickup: {
          label: "Online Pickup",
          showLastYear: true,
          showVariation: true
        },
        bopusPickup: {
          label: "BOPUS Pickup",
          showLastYear: true,
          showVariation: true
        },
        bossPickup: {
          label: "BOSS Pickup",
          showLastYear: true,
          showVariation: true
        },
        ordersPickedUpActualPerDay: {
          label: "Actual per day",
          showLastYear: true,
          showVariation: true
        },
        ordersPickedUpSameDayRate: {
          label: "Same Day",
          showLastYear: true,
          showVariation: true,
          valueFormat: ValueFormat.PERCENT
        },
        ordersPickedUpNextDayRate: {
          label: "Next Day",
          showLastYear: true,
          showVariation: true,
          valueFormat: ValueFormat.PERCENT
        },
        abandonedOrdersAmount: {
          label: "Dollars",
          showLastYear: true,
          showVariation: true,
          valueFormat: ValueFormat.DOLLARS
        },
        abandonedOrdersPercent: {
          label: "Percent",
          showLastYear: true,
          showVariation: true,
          valueFormat: ValueFormat.PERCENT
        },
        attachmentSalesAmount: {
          label: "Dollars",
          showLastYear: true,
          showVariation: true,
          valueFormat: ValueFormat.DOLLARS
        },
        attachmentSalesPercent: {
          label: "Percent",
          showLastYear: true,
          showVariation: true,
          valueFormat: ValueFormat.PERCENT
        },
        attachmentSalesTransactionCount: {
          label: "Transaction Count",
          showLastYear: true,
          showVariation: true
        }
      },
      AmountView: {
        onlinePickup: {
          label: "Online Pickup",
          showLastYear: true,
          showVariation: true
        },
        bopusPickup: {
          label: "BOPUS Pickup",
          showLastYear: true,
          showVariation: true
        },
        bossPickup: {
          label: "BOSS Pickup",
          showLastYear: true,
          showVariation: true
        },
        ordersPickedUpActualPerDay: {
          label: "Actual per day",
          showLastYear: true,
          showVariation: true
        },
        ordersPickedUpSameDayRate: {
          label: "Same Day",
          showLastYear: true,
          showVariation: true,
          valueFormat: ValueFormat.PERCENT
        },
        ordersPickedUpNextDayRate: {
          label: "Next Day",
          showLastYear: true,
          showVariation: true,
          valueFormat: ValueFormat.PERCENT
        },
        abandonedOrdersAmount: {
          label: "Dollars",
          showLastYear: true,
          showVariation: true,
          valueFormat: ValueFormat.DOLLARS
        },
        abandonedOrdersPercent: {
          label: "Percent",
          showLastYear: true,
          showVariation: true,
          valueFormat: ValueFormat.PERCENT
        },
        attachmentSalesAmount: {
          label: "Dollars",
          showLastYear: true,
          showVariation: true,
          valueFormat: ValueFormat.DOLLARS
        },
        attachmentSalesPercent: {
          label: "Percent",
          showLastYear: true,
          showVariation: true,
          valueFormat: ValueFormat.PERCENT
        },
        attachmentSalesTransactionCount: {
          label: "Transaction Count",
          showLastYear: true,
          showVariation: true
        }
      }
    }
  }
};

export enum NotificationStatus {
  NONE = "NONE",
  VISITED = "VISITED",
  REMOVED = "REMOVED"
}

export const MetricDefinition = {
  AssociateLederboard: [{
    title: "Overall Star Score",
    content: "Average of Associate's speed and accuracy ranks. This metric determines the Associate's overall rank."
  }, {
    title: "Accuracy Score",
    content: "Accuracy is based on number units found during the picking process. This metric contributes to the Associate's overall rank."
  }, {
    title: "Speed Score",
    content: "Speed is based on the picking UPH. This metric contributes to the Associate's overall rank."
  }, {
    title: "Personal Best UPH",
    content: "The quickest UPH the associate has picked a batch in the given timeframe."
  }],
  StoreLeaderboard: [{
    title: "Rank",
    content: "Omni store rank is an average of accuracy and execution ranks."
  },
  // {
  //   title: "Speed Rank",
  //   content: "Average of SFS, UPH and BOPUS UPH rank."
  // },
  {
    title: "Execution Rank",
    content: "Average SFS % Shipped 1 day and BOPUS % worked in under 1 hour rank."
  }]
};

export const SummaryReportIgnoreItems = ['storeNumber', 'storeName', 'districtNumber', 'districtName', 'regionNumber', 'regionName', 'territoryNumber', 'territoryName', 'lastRefreshedTimestamp', 'tip', 'tipRule'];
export const CustomColumns = {
  LOCATION: 'Location',
  TERRITORY: 'T',
  REGION: 'R',
  DISTRICT: 'D'
};

export const CsvHealthCustomTitles = {
  onlinePickup: "CE Score Online Pickup",
  bopusPickup: "CE Score BOPUS Pickup",
  bossPickup: "CE Score BOSS Pickup",
  ordersPickedUpActualPerDay: "Orders Picked up - Actual per day",
  ordersPickedUpSameDayRate: "Orders Picked up - Same Day",
  ordersPickedUpNextDayRate: "Orders Picked up - Next Day",
  abandonedOrdersAmount: "Abandoned orders - Dollars",
  abandonedOrdersPercent: "Abandoned orders - Percent",
  attachmentSalesAmount: "Attachment sales - Dollars",
  attachmentSalesPercent: "Attachment sales - Percent",
  attachmentSalesTransactionCount: "Attachment sales - Transaction Count",
  aging: "BOSS Units Aging for today"
};
