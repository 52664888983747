import * as ActionTypes from '../actionTypes';
import { AGGREGATION_OPTIONS } from '../../constants/omniConstants';
import { createAssociateTableData } from '../../utility/dashboard/associateLeaderboard';
import { createStoreLeaderboardTableData } from '../../utility/dashboard/storeLeaderboard';
import {
  getUpdatedNotificationsData, removeNotificationItem, updateNotificationsData, loadVisitedNotificationIds, markAllRead
} from '../../utility/dashboard/notification';
import { startEndDate } from '../actions/omniAction';
import { DateSelectionTypes } from '../../constants/DateRangePickerConstant';
import { IOmniLeaderboardState, IOmniAssociateCompanyData, ICommonAggregatedData, IGeos } from './types/omni.types';

const defaultDates = startEndDate(DateSelectionTypes.Today);

const initalState: IOmniLeaderboardState = {
  geos: {} as IGeos,
  geosError: null,
  geosLoading: false,
  dateRangePicker: {
    selectedDateType: DateSelectionTypes.Today,
    startDate: defaultDates.startDate,
    endDate: defaultDates.endDate
  },
  filters: {
    territories: [],
    regions: [],
    districts: [],
    stores: [],
    associates: [],
    viewBy: [AGGREGATION_OPTIONS[AGGREGATION_OPTIONS.length - 1]]
  },
  associateLeaderboard: {
    associateDataLoading: false,
    associateData: [],
    sortColumn: '',
    sortDirection: '',
    rowsData: [],
    aggregatedData: {
      company: {} as IOmniAssociateCompanyData,
      territories: {} as ICommonAggregatedData,
      regions: {} as ICommonAggregatedData,
      districts: {} as ICommonAggregatedData,
      stores: {}
    },
    lastUpdatedOn: null,
    metaData: {
      associateMetaDataLoading: false,
      associateMetaSchema: null
    }
  },
  storeLeaderboard: {
    storeDataLoading: false,
    storeData: [],
    sortColumn: '',
    sortDirection: '',
    rowsData: [],
    aggregatedDataLoaded: false,
    aggregatedData: {
      company: {} as IOmniAssociateCompanyData,
      territories: {} as ICommonAggregatedData,
      regions: {} as ICommonAggregatedData,
      districts: {} as ICommonAggregatedData
    },
    lastUpdatedOn: null,
    metaData: {
      storeMetaDataLoading: false,
      storeMetaSchema: null
    }
  },
  notifications: {
    notificationsData: [],
    smartNotifications: [],
    notificationsError: null,
    notificationLocationType: null,
    notificationLocation: null,
    notificationsLoading: false
  },
  showExportLoader: false,
  isMobileSmartAssociate: false
};

export default function omniReducer(state: IOmniLeaderboardState = initalState, action: any) {
  switch (action.type) {
    case ActionTypes.GET_GEO_DATA:
      return {
        ...state,
        geosLoading: true,
        geos: {}
      };
    case ActionTypes.GET_GEO_DATA_SUCCESS:
      return {
        ...state,
        geosLoading: false,
        geos: action.payload
      };
    case ActionTypes.GET_GEO_DATA_FAILED:
      return {
        ...state,
        geosLoading: false,
        geos: {},
        geosError: action.payload
      };
    case ActionTypes.GET_ASSOCIATE_DATA:
      return {
        ...state,
        associateLeaderboard: {
          ...state.associateLeaderboard,
          associateDataLoading: true,
          associateData: [],
          lastUpdatedOn: null,
          rowsData: [],
          aggregatedData: initalState.associateLeaderboard.aggregatedData
        }
      };
    case ActionTypes.GET_ASSOCIATE_DATA_SUCCESS:
      const associateData = action.payload && action.payload.data ? action.payload.data : [];

      return {
        ...state,
        associateLeaderboard: {
          ...state.associateLeaderboard,
          associateDataLoading: false,
          associateData,
          lastUpdatedOn: action.payload && action.payload.lastDataRefreshedTimestamp ? action.payload.lastDataRefreshedTimestamp : null,
          rowsData: createAssociateTableData(associateData, state.associateLeaderboard.aggregatedData, state.filters, state.associateLeaderboard.sortColumn, state.associateLeaderboard.sortDirection, state.isMobileSmartAssociate)
        }
      };
    case ActionTypes.GET_SMART_ASSOCIATE_DATA_SUCCESS:
      const smartAssociateData = action.payload && action.payload.data ? action.payload.data : [];
      const smartAggregatedData = state.associateLeaderboard.aggregatedData;
      const rowsDataArray = createAssociateTableData(smartAssociateData, smartAggregatedData, state.filters, state.associateLeaderboard.sortColumn, state.associateLeaderboard.sortDirection, state.isMobileSmartAssociate);

      return {
        ...state,
        associateLeaderboard: {
          ...state.associateLeaderboard,
          associateDataLoading: false,
          associateData: smartAssociateData,
          lastUpdatedOn: action.payload && action.payload.lastDataRefreshedTimestamp ? action.payload.lastDataRefreshedTimestamp : null,
          rowsData: rowsDataArray
        }

      };
    case ActionTypes.GET_ASSOCIATE_DATA_FAILED:
      return {
        ...state,
        associateLeaderboard: {
          ...state.associateLeaderboard,
          associateDataLoading: false,
          associateData: [],
          rowsData: createAssociateTableData([], state.associateLeaderboard.aggregatedData, state.filters, state.associateLeaderboard.sortColumn, state.associateLeaderboard.sortDirection, state.isMobileSmartAssociate),
          lastUpdatedOn: null
        }
      };
    case ActionTypes.UPDATE_SELECTED_DATE_TYPE:
      return {
        ...state,
        dateRangePicker: {
          ...state.dateRangePicker,
          selectedDateType: action.payload.selectedDayType,
          startDate: action.payload.dateRange ? action.payload.dateRange.startDate : state.dateRangePicker.startDate,
          endDate: action.payload.dateRange ? action.payload.dateRange.endDate : state.dateRangePicker.endDate
        }
      };
    case ActionTypes.UPDATE_START_END_DATE:
      return {
        ...state,
        dateRangePicker: {
          ...state.dateRangePicker,
          selectedDateType: action.payload.selectedDayType,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate
        }
      };
    case ActionTypes.UPDATE_GEO_FILTER_DATA:
      const updatedFilters = {
        ...state.filters,
        ...action.payload
      };
      return {
        ...state,
        filters: updatedFilters,
        associateLeaderboard: {
          ...state.associateLeaderboard,
          rowsData: createAssociateTableData(state.associateLeaderboard.associateData, state.associateLeaderboard.aggregatedData, updatedFilters, state.associateLeaderboard.sortColumn, state.associateLeaderboard.sortDirection, state.isMobileSmartAssociate)
        },
        storeLeaderboard: {
          ...state.storeLeaderboard,
          rowsData: createStoreLeaderboardTableData(state.storeLeaderboard.storeData, state.storeLeaderboard.aggregatedData, updatedFilters, state.storeLeaderboard.sortColumn, state.storeLeaderboard.sortDirection)
        }
      };

    case ActionTypes.GET_ASSOCIATE_LEADERBOARD_METADATA:
      return {
        ...state,
        associateLeaderboard: {
          ...state.associateLeaderboard,
          metaData: {
            associateMetaDataLoading: true,
            associateMetaSchema: null
          }
        },
        isMobileSmartAssociate: false
      };
    case ActionTypes.GET_ASSOCIATE_LEADERBOARD_METADATA_SUCCESS:
      return {
        ...state,
        associateLeaderboard: {
          ...state.associateLeaderboard,
          metaData: {
            associateMetaDataLoading: false,
            associateMetaSchema: action.payload
          }
        },
        isMobileSmartAssociate: action.isMobileSmartAssociate
      };
    case ActionTypes.GET_ASSOCIATE_LEADERBOARD_METADATA_FAILED:
      return {
        ...state,
        associateLeaderboard: {
          ...state.associateLeaderboard,
          metaData: {
            associateMetaDataLoading: false,
            associateMetaSchema: action.payload
          }
        },
        isMobileSmartAssociate: false
      };
    case ActionTypes.GET_STORE_LEADERBOARD_METADATA:
      return {
        ...state,
        storeLeaderboard: {
          ...state.storeLeaderboard,
          metaData: {
            storeMetaDataLoading: true,
            storeMetaSchema: null
          }
        }
      };
    case ActionTypes.GET_STORE_LEADERBOARD_METADATA_SUCCESS:
      return {
        ...state,
        storeLeaderboard: {
          ...state.storeLeaderboard,
          metaData: {
            storeMetaDataLoading: false,
            storeMetaSchema: action.payload
          }
        }
      };
    case ActionTypes.GET_STORE_LEADERBOARD_METADATA_FAILED:
      return {
        ...state,
        storeLeaderboard: {
          ...state.storeLeaderboard,
          metaData: {
            storeMetaDataLoading: false,
            storeMetaSchema: action.payload
          }
        }
      };

    case ActionTypes.GET_STORE_AGGREGATE_DATA_SUCCESS:
      return {
        ...state,
        storeLeaderboard: {
          ...state.storeLeaderboard,
          aggregatedDataLoaded: true,
          aggregatedData: action.payload,
          rowsData: createStoreLeaderboardTableData(state.storeLeaderboard.storeData, action.payload, state.filters, state.storeLeaderboard.sortColumn, state.storeLeaderboard.sortDirection)
        }
      };

    case ActionTypes.GET_ASSOCIATE_AGGREGATE_DATA_SUCCESS:
      return {
        ...state,
        associateLeaderboard: {
          ...state.associateLeaderboard,
          aggregatedDataLoaded: true,
          aggregatedData: action.payload,
          rowsData: createAssociateTableData(associateData, action.payload, state.filters, state.associateLeaderboard.sortColumn, state.associateLeaderboard.sortDirection, state.isMobileSmartAssociate)
        }
      };

    case ActionTypes.GET_STORE_DATA:
      return {
        ...state,
        storeLeaderboard: {
          ...state.storeLeaderboard,
          storeDataLoading: true,
          storeData: [],
          rowsData: [state.storeLeaderboard.aggregatedData.company] || []
        }
      };

    case ActionTypes.GET_STORE_DATA_SUCCESS:
      const { data: storeData, lastDataRefreshedTimestamp } = action.payload;

      return {
        ...state,
        storeLeaderboard: {
          ...state.storeLeaderboard,
          storeDataLoading: false,
          storeData,
          rowsData: createStoreLeaderboardTableData(storeData, state.storeLeaderboard.aggregatedData, state.filters, state.storeLeaderboard.sortColumn, state.storeLeaderboard.sortDirection),
          lastUpdatedOn: lastDataRefreshedTimestamp
        }
      };
    case ActionTypes.GET_STORE_DATA_FAILED:
      return {
        ...state,
        storeLeaderboard: {
          ...state.storeLeaderboard,
          storeDataLoading: false,
          storeData: action.payload
        }
      };
    case ActionTypes.RESET_NOTIFICATION_DATA:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          notificationsLoading: false,
          notificationsData: null,
          smartNotifications: null,
          notificationLocationType: null,
          visited: null
        }
      };
    case ActionTypes.GET_NOTIFICATION_DATA:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          notificationsLoading: true,
          notificationsData: null,
          smartNotifications: null,
          notificationLocationType: null,
          visited: null
        }
      };
    case ActionTypes.GET_NOTIFICATION_SUCCESS:
      const visited = loadVisitedNotificationIds();
      return {
        ...state,
        notifications: {
          notificationLocationType: action.payload.locationType,
          notificationLocation: action.payload.location,
          notificationsLoading: false,
          notificationsData: getUpdatedNotificationsData(action.payload.notifications),
          smartNotifications: state.notifications.smartNotifications,
          visited
        }
      };
    case ActionTypes.GET_NOTIFICATION_FAILED:
      return {
        ...state,
        notifications: {
          notificationsLoading: false,
          notificationsData: null,
          smartNotifications: state.notifications.smartNotifications,
          notificationsError: action.payload.notificationsError,
          notificationLocationType: action.payload.locationType,
          notificationLocation: action.payload.location,
          visited: null
        }
      };
    case ActionTypes.GET_SMART_NOTIFICATION_SUCCESS:
      const visitedNotifications = loadVisitedNotificationIds();
      return {
        ...state,
        notifications: {
          notificationsData: state.notifications.notificationsData,
          notificationLocationType: action.payload.locationType,
          notificationLocation: action.payload.location,
          notificationsLoading: false,
          smartNotifications: getUpdatedNotificationsData(action.payload.smartNotifications),
          visited: visitedNotifications
        }
      };
    case ActionTypes.GET_SMART_NOTIFICATION_FAILED:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          notificationsData: state.notifications.notificationsData,
          smartNotifications: null,
          notificationsLoading: false,
          notificationsError: action.payload.notificationsError,
          notificationLocationType: action.payload.locationType,
          notificationLocation: action.payload.location,
          visited: null
        }
      };
    case ActionTypes.REMOVE_NOTIFICATION_ITEM:
      const notificationsRemoveItem = state.notifications.notificationsData !== null ? state.notifications.notificationsData : [];
      const smartNotificationsRemoveItem = state.notifications.smartNotifications !== null ? state.notifications.smartNotifications : [];
      return {
        ...state,
        notifications: {
          notificationsLoading: false,
          notificationsData: removeNotificationItem(notificationsRemoveItem, action.payload.itemId),
          smartNotifications: removeNotificationItem(smartNotificationsRemoveItem, action.payload.itemId),
          notificationLocationType: { ...state.notifications.notificationLocationType },
          notificationLocation: Object.assign({}, state.notifications.notificationLocation)
          // notificationLocation: { ...state.notifications.notificationLocation }
        }
      };
    case ActionTypes.REMOVE_ALL_NOTIFICATION_ITEMS:
      const notificationsRemoveAll = state.notifications.notificationsData !== null ? state.notifications.notificationsData! : [];
      const smartNotificationsRemoveAll = state.notifications.smartNotifications !== null ? state.notifications.smartNotifications! : [];
      return {
        ...state,
        notifications: {
          notificationsLoading: false,
          notificationsData: markAllRead(notificationsRemoveAll),
          smartNotifications: markAllRead(smartNotificationsRemoveAll),
          notificationLocationType: { ...state.notifications.notificationLocationType },
          notificationLocation: Object.assign({}, state.notifications.notificationLocation)
          // notificationLocation: { ...state.notifications.notificationLocation }
        }
      };
    case ActionTypes.MARK_NOTIFICATION_ITEM_SELECTED:
      const notificationsSelected = state.notifications.notificationsData !== null ? state.notifications.notificationsData! : [];
      const smartNotificationsSlected = state.notifications.smartNotifications !== null ? state.notifications.smartNotifications! : [];
      return {
        ...state,
        notifications: {
          notificationsLoading: false,
          notificationsData: updateNotificationsData(notificationsSelected, action.payload.itemId),
          smartNotifications: updateNotificationsData(smartNotificationsSlected, action.payload.itemId),
          notificationLocationType: { ...state.notifications.notificationLocationType },
          notificationLocation: Object.assign({}, state.notifications.notificationLocation)
          // notificationLocation: { ...state.notifications.notificationLocation }
        }
      };
    case ActionTypes.UPDATE_NOTIFICATION_DATA:
      const updateNotifications = state.notifications.notificationsData !== null ? state.notifications.notificationsData! : [];
      const smartNotificationsUpdate = state.notifications.smartNotifications !== null ? state.notifications.smartNotifications! : [];
      return {
        ...state,
        notifications: {
          notificationsLoading: false,
          notificationsData: updateNotifications,
          smartNotifications: smartNotificationsUpdate,
          notificationLocationType: { ...state.notifications.notificationLocationType },
          notificationLocation: Object.assign({}, state.notifications.notificationLocation)
          // notificationLocation: { ...state.notifications.notificationLocation }
        }
      };
    case ActionTypes.SHOW_EXPORT_LOADER:
      return {
        ...state,
        showExportLoader: true
      };
    case ActionTypes.HIDE_EXPORT_LOADER:
      return {
        ...state,
        showExportLoader: false
      };
    case ActionTypes.SORT_ASSOCIATE_LEADERBOARD_DATA:
      return {
        ...state,
        associateLeaderboard: {
          ...state.associateLeaderboard,
          ...action.payload,
          rowsData: createAssociateTableData(state.associateLeaderboard.associateData, state.associateLeaderboard.aggregatedData, state.filters, action.payload.sortColumn, action.payload.sortDirection)
        }
      };
    case ActionTypes.SORT_STORE_LEADERBOARD_DATA:
      return {
        ...state,
        storeLeaderboard: {
          ...state.storeLeaderboard,
          ...action.payload,
          rowsData: createStoreLeaderboardTableData(state.storeLeaderboard.storeData, state.storeLeaderboard.aggregatedData, state.filters, action.payload.sortColumn, action.payload.sortDirection)
        }
      };
    default:
      return state;
  }
}