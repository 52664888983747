import * as React from 'react';
import { Navbar } from 'react-bootstrap';
import { OmniNav } from '../../dashboards/OmniDashboard/OmniNav';
import { getFormattedTimeStamp } from '../../../utility/Common';
import { IDashboardFooterProps } from '../../types/shared/DashboardItems/DashboardFooter.types';

const DashboardFooter = ({ lastUpdatedOn, name, modulesNavigation }: IDashboardFooterProps) => {
  const labelText = "KOHL'S";
  return (
    <div className="custom-dashboard-container__footer bg-white">
      <Navbar>
        <Navbar.Brand className="font-bold">{labelText}</Navbar.Brand>
        {name && <span className="font-bold">{name}</span>}
        {lastUpdatedOn && <span className="separator">|</span>}
        {lastUpdatedOn && <span className="last-updated"> Time Updated: {getFormattedTimeStamp(lastUpdatedOn)}</span>}
        <OmniNav className="ml-auto" navigation={modulesNavigation} />
      </Navbar>
    </div>
  );
};
export default DashboardFooter;