export const MODULE_TYPE = {
  LEADERBOARD: 'leaderboard',
  STATUS: 'status'
};

export const PDF_DEFAULT = {
  LEFT_MARGIN: 15,
  TOP_MARGIN: 5
};

export const VIEW_BY_OMNI_CONSTANTS = {
  territories: "TERRITORY",
  volumes: "VOLUME",
  regions: "REGION",
  districts: "DISTRICT",
  stores: "STORE"
};

export const VIEW_BY_SMART_CONSTANTS = {
  ...VIEW_BY_OMNI_CONSTANTS,
  volumes: "VOLUME"
};