import "@babel/polyfill";
import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import * as localStore from "./utility/LocalStorage";
import "bootstrap/dist/css/bootstrap.min.css";
// import "react-dates/initialize";

// declare global{
//     interface Window {
//         clearLocalStore : any;
//     }
// }

store.subscribe(() => {
  localStore.saveState({
    userInfo: store.getState().commonReducer.userInfoData
  });
});

// window.clearLocalStore = function clearLocalStorage(){
//     localStorage.clear()
// };


ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
