import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as localStore from "../../../utility/LocalStorage";
import Filters from './Filters';
import { Loader } from 'cx-ppd-client-components';
import { Accordion } from 'cx-ppd-client-components';

import { IAppState } from '../../../types';
import { omnitureTracking, pageName } from "../../../utility/OmnitureTracking";

import Replenishment from './Replenishment';
import DailySummary from './DailySummary';
import { exportStatusToPdf } from '../../../utility/smartUtils';
import { DateSelectionTypes, DateSelection } from '../../../constants/DateRangePickerConstant';
import { cs } from '../../../utility/Common';
import * as HealthStatusActionCreators from '../../../store/actions/healthStatusSmart';
/**
 * Imported after all imports to allow any css override specific to smart status
 */
import "../../../assets/styles/smart-health-status.scss";
import DashboardFooter from '../../../components/shared/DashboardItems/DashboardFooter';
import { HealthStatusSections, AuditsViewDetailsLabel } from '../../../constants/smartConstants';
import { NoData } from '../../OmniDashboard/HealthStatus/NoData';
import * as moment from 'moment';
import { ISmartStatusStateToProps, ISmartStatusDispatchToProps } from '../../types/SmartDashboard/HealthStatus/SmartStatus.types';
import { IFiltersConfig } from '../../types/Common.types';
import { isCompany } from '../../../store/actions/healthStatus';
import { IPropsByExportManager, ExportFormatType, ExportProduct, ExportDashbordType } from '../../../components/types/shared/Common/ExportManager.types';

interface ISmartStatusSubProps extends IPropsByExportManager {
  modulesNavigation: object[];
  config: IFiltersConfig;
  isFiscalDateFilter?: boolean;
}

export type ISmartStatusProps = ISmartStatusStateToProps & ISmartStatusDispatchToProps & ISmartStatusSubProps;

class SmartStatus extends React.Component<ISmartStatusProps, any> {

  state = localStore.loadState() !== undefined ? localStore.loadState() : '';

  componentDidMount() {
    const { userData: { storeId } } = this.props;
    this.setDefaultLocation(storeId);
  }

  componentDidUpdate(prevProps: ISmartStatusProps) {
    const { userData: { storeId }, geos } = this.props;
    if (storeId !== prevProps.userData.storeId || geos !== prevProps.geos) {
      this.setDefaultLocation(storeId);
    }
  }

  setDefaultLocation = (storeId: any) => {
    if (typeof storeId === "string") {
      storeId = Number.parseInt(storeId, 10);
    }
    if (!storeId) {
      storeId = -1;
    }
    const { geos: { stores }, updateLocation } = this.props;
    if (!stores || stores.length === 0) {
      return;
    }
    const userStore = stores.find((s: any) => s.storeNumber === storeId);
    if (!userStore) {
      return updateLocation(stores[0]);
    }
    updateLocation(userStore);
  }

  reportTitle = () => `SmartStatus_${moment().format()}`;

  exportSmartStatusData = () => {
    const { smartHealthStatus, selectedDateType, startDate, endDate, triggerExport } = this.props;
    // const dataArray = [healthData, { dailySummaryData }];
    // const mergedData = dataArray.reduce((result: any, item: any) => {
    //   const key = Object.keys(item)[0];
    //   result[key] = item[key];
    //   return result;
    // }, {});
    // exportStatusToCsv(mergedData, this.reportTitle());
    let bodyParams: any = HealthStatusActionCreators.getHealthStatusRequestParams(smartHealthStatus);
    if (isCompany(smartHealthStatus.locationType)) {
      bodyParams = {
        isCompany: true,
        startDate,
        endDate,
        dateType: selectedDateType
      };
    }
    triggerExport({
      queryParams: {
        type: ExportFormatType.Csv,
        product: ExportProduct.Smart,
        dashboard: ExportDashbordType.Status
      },
      bodyParams
    });
  }

  exportSmartStatusDataPdf = (): void | null => {
    const { healthData, selectedDateType, startDate, endDate } = this.props;
    if (!healthData) {
      return null;
    }
    let range = null;

    if (selectedDateType !== DateSelectionTypes.CUSTOM_DATE_RANGE) {
      DateSelection.forEach((date: any) => {
        if (date.value === selectedDateType) {
          range = date.label;
        }
      });
    } else {
      range = `${startDate} - ${endDate}`;
    }

    exportStatusToPdf({
      title: this.reportTitle(),
      range,
      refresh: healthData!.lastRefreshedTimeStamp
    });
  }

  render() {
    const {
      isLoading,
      healthData,
      viewLastYear,
      modulesNavigation,
      dailySummaryData,
      isFiscalDateFilter,
      selectedDateType,
      smartHealthStatus: { locationType },
      config,
      isExporting
    } = this.props;

    if (healthData && healthData.healthKPI) {
      const { auditsCreatedActual, replenAuditCompletedPercentageActual, otherAuditCompletedPercentageActual } = healthData.healthKPI;
      if (auditsCreatedActual) {
        const details: any = [];
        if (Object.keys(replenAuditCompletedPercentageActual).length > 0) {
          details.push({ label: AuditsViewDetailsLabel.replenishment, value: replenAuditCompletedPercentageActual.currentValue });
        }
        if (Object.keys(otherAuditCompletedPercentageActual).length > 0) {
          details.push({ label: AuditsViewDetailsLabel.others, value: otherAuditCompletedPercentageActual.currentValue });
        }
        auditsCreatedActual.details = details;
      }
    }

    omnitureTracking.trackPage(pageName.replenishmentStatus);

    return (
      <div className="health-status">
        {
          isLoading && (
            <Loader />
          )
        }
        <Filters
          isExporting={isExporting}
          onExportToCsvClicked={this.exportSmartStatusData}
          onExportToPdfClicked={this.exportSmartStatusDataPdf}
          config={config}
          isFiscalDateFilter={isFiscalDateFilter} />
        {
          healthData ? (
            <div id="health-status-content" className="container-fluid">
              <div className="health-status__wrapper">
                <div className="wrapper-header">{HealthStatusSections.replenishment}</div>
                <div className="wrapper-body">
                  <Replenishment
                    data={healthData.healthKPI}
                    viewLastYear={viewLastYear}
                    locationType={locationType}
                    selectedDateType={selectedDateType}
                  />
                </div>
              </div>
              <div className={cs('daily-summary-accordion', { 'last-year-data': viewLastYear })}>
                <Accordion
                  title={HealthStatusSections.dailySummary}
                >
                  <DailySummary data={dailySummaryData} />
                </Accordion>
              </div>
            </div>
          ) : (
              <NoData />
            )
        }
        <DashboardFooter lastUpdatedOn={healthData ? healthData.lastRefreshedTimeStamp : ""} name={"Replenishment"} modulesNavigation={modulesNavigation} />
      </div>
    );
  }
}

const mapStateToProps = (state: IAppState): ISmartStatusStateToProps => ({
  userData: state.commonReducer.userInfoData,
  userAuthorized: state.commonReducer.userAuthorized,
  geos: state.omni.geos,
  isLoading: state.smartHealthStatus.isLoading,
  healthData: state.smartHealthStatus.healthData,
  selectedDateType: state.smartHealthStatus.selectedDateType,
  startDate: state.smartHealthStatus.startDate,
  endDate: state.smartHealthStatus.endDate,
  viewLastYear: state.smartHealthStatus.viewLastYear,
  dailySummaryData: state.smartHealthStatus.dailySummaryData,
  smartHealthStatus: state.smartHealthStatus
});

const mapDispatchToProps = (dispatch: any): ISmartStatusDispatchToProps => {
  return bindActionCreators({
    updateLocation: HealthStatusActionCreators.updateLocation
  }, dispatch);
};

export default connect<any, any, any>(mapStateToProps, mapDispatchToProps)(SmartStatus);
