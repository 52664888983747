import * as React from 'react';
import { Badge, Dropdown, Image } from "react-bootstrap";
import DropdownMenu from 'react-bootstrap/DropdownMenu';
import DropdownToggle from 'react-bootstrap/DropdownToggle';
import NotificationItem from './NotificationItem';
import { removeNotificationItem, removeAllNotificationItem, markItemSelected, updateNotificationData } from '../../../store/actions/omniAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NotificationStatus, LOCATION_LABEL, GEOKEYS } from '../../../constants/omniConstants';
import { IOmniNotificationStateToProps, IOmniNotificationDispatchToProps } from '../../types/dashboards/OmniDashboard/OmniNotification.types';

interface INotifications {
  notifications: any;
  isSmartDasboard?: boolean;
}

type IOmniNotificationProps = INotifications & IOmniNotificationStateToProps & IOmniNotificationDispatchToProps;

class OmniNotification extends React.Component<IOmniNotificationProps, any> {
  constructor(props: any) {
    super(props);
    this.removeItem = this.removeItem.bind(this);
    this.state = {
      store: null
    };
  }

  removeItem = (notificationId: string) => {
    this.props.removeNotificationItem(notificationId);
  }

  removeAllItems = (value: any) => {
    this.props.removeAllNotificationItem();
  }

  markItemSelected = (notificationId: any, shipmentType?: any) => {
    this.props.markItemSelected(notificationId);

    const shipmentID = shipmentType ? document.getElementById(shipmentType) : null;
    if (shipmentID) {
      shipmentID.scrollIntoView({ behavior: "smooth" });
    }
  }

  componentWillUnmount() {
    this.props.updateNotificationData();
  }

  getStoreId() {
    const { userData } = this.props;
    if (userData && userData.storeId) {
      return userData.storeId;
    }
    return "";
  }

  getNotificationsParams = (storeId: any) => {
    if (typeof storeId === "string") {
      storeId = Number.parseInt(storeId, 10);
    }
    const { stores } = this.props;
    if (!stores || stores.length === 0) {
      return;
    }
    const storeInfo = stores.find((s: any) => s.storeNumber === storeId);
    if (!storeInfo) {
      return;
    }
    return storeInfo;
  }

  provideSubtitleValue = (store: any, locationKey: string) => {
    const labelKey = GEOKEYS[locationKey].ValueKey;
    return store[labelKey];
  }

  getSubtitle = (notifications: any) => {
    const { notificationLocation, notificationLocationType } = notifications;
    if (notificationLocation && notificationLocationType) {
      const locationKey = notificationLocationType.value;
      const name = LOCATION_LABEL[locationKey];
      if (name) {
        return {
          substringName: name,
          substringValue: this.provideSubtitleValue(notificationLocation, locationKey)
        };
      }
    }
    return null;
  }

  render() {
    const { notifications, isSmartDasboard } = this.props;
    const data = notifications &&
      (isSmartDasboard && notifications.smartNotifications ?
        notifications.smartNotifications :
        notifications.notificationsData ? notifications.notificationsData : false);
    let listItems: any = [];
    let notificationCounter = [];
    if (data) {
      listItems = data.map((item: any, index: number) => <NotificationItem key={index} onRemove={this.removeItem} onMarkItemSelected={this.markItemSelected} notificationItem={item} />);
      notificationCounter = data.filter((item: any) => item.status === NotificationStatus.NONE);
    }
    const subtitle: any = this.getSubtitle(notifications);
    return (
      <Dropdown className="notifications-wrapper">
        <DropdownToggle id="dropdown-basic" className="notifications-toggle">
          <Image src={require('../../../assets/images/bell.png')} />
          {notificationCounter.length > 0 && (
            <Badge className="notifications-counter">{notificationCounter.length}</Badge>)
          }
        </DropdownToggle>
        <DropdownMenu className="notifications-menu">
          {<div>
            <h6 className="notifications-header" onClick={this.removeAllItems}>Mark All as Read</h6>
            <a className="notifications-title">
              <b>Notifications:</b>
            </a>
            <br />
            {subtitle && <a className="notifications-subTitle">{subtitle.substringName}: {subtitle.substringValue}</a>}
          </div>}
          <div className="notifications-body">
            {listItems}
          </div>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

const mapStateToProps = (state: any): IOmniNotificationStateToProps => {
  return {
    userData: state.commonReducer.userInfoData,
    userAuthorized: state.commonReducer.userAuthorized,
    stores: state.omni.geos.stores
  };
};

const mapDispatchToProps = (dispatch: any): any => {
  return bindActionCreators({
    removeNotificationItem,
    removeAllNotificationItem,
    markItemSelected,
    updateNotificationData
  }, dispatch);
};

export default connect<IOmniNotificationStateToProps, IOmniNotificationDispatchToProps, any>(
  mapStateToProps,
  mapDispatchToProps
)(OmniNotification);