import * as actionTypes from '../actionTypes';
import { nextTrailerDetails } from '../../utility/Common';

const initalState = {
  trips: [],
  tripsLoading: false,
  fiscalDates: [],
  fiscalDatesLoading: false,
  nextTrailerDetails: {}
};

export default function inboundFreight(state = initalState, action: any) {
  switch (action.type) {
    case actionTypes.GET_INBOUND_TRIPS:
      return {
        ...state,
        tripsLoading: true,
        trips: [],
        nextTrailerDetails: {}
      };
    case actionTypes.GET_INBOUND_TRIPS_SUCCESS:
      const nextTrailer = nextTrailerDetails(action.payload);
      return {
        ...state,
        tripsLoading: false,
        trips: action.payload,
        nextTrailerDetails: nextTrailer
      };
    case actionTypes.GET_INBOUND_TRIPS_FAILED:
      return {
        ...state,
        tripsLoading: false,
        trips: [],
        nextTrailerDetails: {}
      };
    case actionTypes.CLEAR_INBOUND_TRIPS:
      return { ...state, trips: [] };

    case actionTypes.GET_FISCAL_WEEKS:
      return { ...state, fiscalDatesLoading: true, fiscalDates: [] };
    case actionTypes.GET_FISCAL_WEEKS_SUCCESS:
      return { ...state, fiscalDatesLoading: false, fiscalDates: action.payload };
    case actionTypes.GET_FISCAL_WEEKS_FAILED:
      return { ...state, fiscalDatesLoading: false, fiscalDates: [] };

    default:
      return state;
  }
}