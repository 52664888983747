import * as React from 'react';
import AdvancedSelect from '../../../../components/dashboards/ManifestOverview/AdvancedSelect';

export interface IDropdowOption {
  label: string;
  rate: number;
  value: number;
}

interface IDetailsDropdown {
  value: number | any;
  setValue: any;
  hasDropdown?: boolean;
  details?: IDropdowOption[];
}

const isOptionSelected = (option: any, selectValue: any) => selectValue.some((i: any) => i === option);

const detailsDropdownLabelFormatter = (o: any, { context }: any) => context === "value" ? o.label : (<div className="f-row jc-space-between"><div>{o.label}</div><div>{o.rate}%</div></div>);

const detailsDropdownStyles = {
  control: (defaultStyle: any) => ({
    ...defaultStyle,
    border: "none",
    boxShadow: "none",
    backgroundColor: "#f5f5f5",
    height: "56px"
  }),
  menu: (defaultStyle: any) => ({ ...defaultStyle, zIndex: 11 }),
  indicatorSeparator: () => ({ display: 'none' }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'rgba(0, 0, 0, 0.08);' : '#ffffff',
    color: '#222222',
    fontSize: "13px",
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08);'
    }
  })
};

const DetailsDropdown = ({ value, setValue, hasDropdown, details }: IDetailsDropdown) => {
  const onChange = ({ value: selectedOption }: any) => setValue(selectedOption);
  return (
    hasDropdown && details && details.length > 0 ?
      <AdvancedSelect
        name=""
        isClearable={false}
        isSearchable={false}
        options={details.filter((o: any) => o.label !== null && o.rate !== null).sort((a: any, b: any) => a.rate <= b.rate ? 1 : -1)}
        labelKey="label"
        valueKey="rate"
        isObjectValue={true}
        value={value}
        isOptionSelected={isOptionSelected}
        onChange={onChange}
        styles={detailsDropdownStyles}
        formatOptionLabel={detailsDropdownLabelFormatter}
      /> : null
  );
};

export default DetailsDropdown;