import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { IHealthKPI, IMerticValue } from '../../../store/reducers/types/smartHealthStatus.types';
import { ValueFormat } from '../../../constants/omniConstants';
import { PPDTooltip } from 'cx-ppd-client-components';
import { Value, TipRule, ViewDetailsLink, LastYearValue } from '../../OmniDashboard/HealthStatus/Common';
import { parseGoal } from '../../../utility/smartUtils';
import DetailsDropdown, { IDropdowOption } from '../../OmniDashboard/HealthStatus/Common/DetailsDropdown';
import { truncateDecimalPlaces } from '../../../utility/Common';
import { IMetricValueAdvanceProps, IShowLastYearValue } from '../../types/OmniDashboard/HealthStatus/Common/ICommonIndex.types';

export const Variation = ({ variation }: { variation: number }) => (
  <div className="variation">{variation}% to LY</div>
);

interface IMetricValueAdvancePropsSmart extends Omit<IMetricValueAdvanceProps, 'data' | 'fieldsConfig'> {
  data: IHealthKPI;
  fieldsConfig: any;
  goalKey?: string;
}

export const MetricValueAdvance = (props: IMetricValueAdvancePropsSmart) => {
  const { dataKey, data, viewLastYear, fieldsConfig, horizontal, goalKey } = props;
  const fieldConfig = fieldsConfig[dataKey] || {};
  const goalFieldConfig = goalKey ? fieldsConfig[goalKey] || {} : null;

  if (!fieldConfig) {
    return null;
  }

  const { showLastYear } = fieldConfig;

  const field = data && data[dataKey] ? data[dataKey] : null;
  const goal = data && goalKey && data[goalKey] ? data[goalKey] : null;

  if (typeof field === "undefined") {
    return null;
  }

  const {
    currentValue,
    lyValue,
    difference,
    proTip,
    hoverTip,
    details
  } = field;

  const MetricValueRenderer = horizontal ? MetricValueHorizontal : MetricValue;

  return (
    <MetricValueRenderer
      {...fieldConfig}
      {...(typeof currentValue === "object" && currentValue !== null ? currentValue : { value: currentValue })}
      goalFieldConfig={goalFieldConfig}
      showLastYear={showLastYear && viewLastYear}
      lastYearValue={lyValue}
      variation={difference}
      proTip={proTip}
      hoverTip={hoverTip}
      details={details}
      dataKey={dataKey}
      goal={goal}
    />
  );
};

interface IMetricValueProps {
  label: string;
  value: number | null;
  valueFormat?: ValueFormat;
  lastYearValue?: number;
  showLastYear?: boolean;
  proTip?: string;
  hoverTip?: string;
  hasDropdown?: boolean;
  hasViewDetails?: boolean;
  details?: IDropdowOption[];
  variation: number;
  dataKey: string;
  goal?: IMerticValue;
  goalFieldConfig?: {
    label: string;
  };
}

export const ShowLastYearValue = ({ showLastYear, lastYearValue, valueFormat, isHorizontal }: IShowLastYearValue) => {
  let result;
  if (isHorizontal && lastYearValue) {
    result = <Col md={6}><div className="label">LY</div><div className="last-year-value"><LastYearValue value={lastYearValue} format={valueFormat} /></div></Col>;
  } else if (lastYearValue) {
    result = <div className="last-year-value"><LastYearValue value={lastYearValue} format={valueFormat} /> LY</div>;
  }
  return showLastYear && result ? result : null;
};

const smartValueFormatter = (value: number | null, valueFormat?: ValueFormat) => {
  if (value === null) {
    return value;
  }
  switch (valueFormat) {
    case ValueFormat.PERCENT:
      return Math.round(value);
    case ValueFormat.HOURS:
      return truncateDecimalPlaces(value, 1);
    default:
      return value;
  }
};

export const MetricValue = (props: IMetricValueProps) => {
  const {
    label,
    variation,
    lastYearValue,
    showLastYear,
    proTip,
    hoverTip,
    valueFormat,
    hasDropdown,
    hasViewDetails,
    details,
    dataKey,
    goal,
    goalFieldConfig
  } = props;

  const goalResult = parseGoal(goalFieldConfig, goal);

  const [value, setValue] = React.useState(props.value);

  if (typeof value === "undefined") {
    return null;
  }

  const leadZero = value !== null && value < 1 && value > 0;

  return (
    <PPDTooltip tooltip={hoverTip ? hoverTip : null} key={dataKey}>
      <div className="metric-value">
        <Value
          value={smartValueFormatter(value, valueFormat)}
          valueFormat={valueFormat}
          tip={proTip}
          hideLeadZero={leadZero}
        />
        <div className="label">{label}</div>
        <TipRule tipRule={goalResult} brackets={true} />
        <ShowLastYearValue
          showLastYear={showLastYear}
          lastYearValue={lastYearValue}
          valueFormat={valueFormat}
        />
        {
          showLastYear && !!lastYearValue && (
            <>
              <Variation variation={variation} />
            </>
          )
        }
        <DetailsDropdown
          value={value}
          setValue={setValue}
          hasDropdown={hasDropdown}
          details={details}
        />
        <ViewDetailsLink
          hasViewDetails={hasViewDetails}
          details={details}
          title="Audits Created"
          valueFormat={ValueFormat.PERCENT}
        />
      </div>
    </PPDTooltip>
  );
};

export const MetricValueHorizontal = ({ label, value, valueFormat, lastYearValue, variation, showLastYear, proTip }: IMetricValueProps) => {
  return (
    <div className="metric-value vertical">
      <Row>
        <Col sm="6">
          <div className="label">{label}</div>
          <Value
            value={value}
            valueFormat={valueFormat}
            tip={proTip}
          />
          {
            showLastYear && lastYearValue && (
              <Variation variation={variation} />
            )
          }
        </Col>
        <ShowLastYearValue
          showLastYear={showLastYear}
          lastYearValue={lastYearValue}
          valueFormat={valueFormat}
          isHorizontal={true}
        />
      </Row>
    </div>
  );
};