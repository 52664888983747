import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

import { CustomSelect } from 'cx-ppd-client-components';
import { AGGREGATION_OPTIONS, AGGREGATION_OPTIONS_BY_COMPANY, GEOKEYS } from '../../../constants/omniConstants';
import { isCompany } from '../../../store/actions/healthStatus';
import { ILocationFilterProps, ILocationFilterState } from '../../types/shared/Common/LocationFilter.types';

class LocationFilter extends React.PureComponent<ILocationFilterProps, ILocationFilterState>{
  getLocationParams = (geos: any, locationType: any) => {
    let options = [];
    let labelKey = '';
    let valueKey = '';
    let labelFormatter;
    if (geos && locationType) {
      const locationKey = locationType.value;
      options = geos[locationKey] || [];
      labelKey = GEOKEYS[locationKey].NameKey;
      valueKey = GEOKEYS[locationKey].ValueKey;
      labelFormatter = (option: any) => `${option[valueKey]} - ${option[labelKey]}`;
    }
    return {
      options,
      labelKey,
      valueKey,
      labelFormatter
    };
  }

  updateFilterVisibility = (locationType: any) => {
    if (isCompany(locationType)) {
      return "hidden";
    }
    return "visible";
  }

  render() {
    const { geos, locationType, location, updateLocationType, updateLocation, viewByOptionsDefault, isMobile } = this.props;
    const filterVisibility = this.updateFilterVisibility(locationType);
    return (
      <Row className="dropdowns">
        <Col sm={true} xs="12">
          <CustomSelect
            name="locationType"
            placeholder="View By"
            options={viewByOptionsDefault ? AGGREGATION_OPTIONS : AGGREGATION_OPTIONS_BY_COMPANY}
            value={locationType}
            labelKey="label"
            valueKey="value"
            onChange={({ value }: any) => updateLocationType(value)}
            isSingleSelect={true}
            isMobile={isMobile}
          />
        </Col>
        <Col sm={true} xs="12" style={{ visibility: filterVisibility }}>
          <CustomSelect
            name="location"
            placeholder="Location"
            value={location}
            isSingleSelect={true}
            onChange={({ value }: any) => updateLocation(value)}
            {...this.getLocationParams(geos, locationType)}
            isMobile={isMobile}
          />
        </Col>
      </Row>
    );
  }
}

export default LocationFilter;