import { formatDate } from "../Date";
import { sortByDate, groupBy } from "../Common";
import { TripStatusConsts } from "../../constants";

export const getFiscalRange = (days: number = 20) => {
  const sd = new Date();
  sd.setDate(sd.getDate() - sd.getDay());
  const ed = new Date(sd);
  ed.setDate(sd.getDate() + days);

  return {
    startDate: formatDate(sd),
    endDate: formatDate(ed)
  };
};

export const calculateManifestPercent = (forecasted: any, manifested: any) => {
  if (!forecasted || !manifested) {
    return 0;
  }
  return Math.round(((manifested.units - forecasted.units) / forecasted.units) * 100);
};

const getTripByStatus = (tripsByStatus: any[]) => {
  let trips = [];
  if (tripsByStatus[TripStatusConsts.SU]) {
    trips = tripsByStatus[TripStatusConsts.SU];
  } else if (tripsByStatus[TripStatusConsts.DELIVERED]) {
    trips = tripsByStatus[TripStatusConsts.DELIVERED];
  } else if (tripsByStatus[TripStatusConsts.DS]) {
    trips = tripsByStatus[TripStatusConsts.DS];
  } else if (tripsByStatus[TripStatusConsts.DC]) {
    trips = tripsByStatus[TripStatusConsts.DC];
  } else if (tripsByStatus[TripStatusConsts.FORECASTED]) {
    trips = tripsByStatus[TripStatusConsts.FORECASTED];
  }
  return trips[0];
};

const getProjectionInfo = (trip: any) => {
  const { storeNumber, scheduledDate, tripStatus, tripId, trailer, ...manifestInfo } = trip;
  return manifestInfo;
};

export const getGroupedTrips = (trips: any[] = []) => {
  const sortedTrips = sortByDate(trips, 'scheduledDate');
  const groupedByDate = groupBy(sortedTrips, 'scheduledDate');

  for (const date in groupedByDate) {
    if (!groupedByDate.hasOwnProperty(date)) {
      continue;
    }
    const tripsOnDate = groupedByDate[date];
    const tripsByStatus = groupBy(tripsOnDate, 'tripStatus');
    const tripToDisplay = getTripByStatus(tripsByStatus);

    if (!tripToDisplay) {
      continue;
    }
    if (tripToDisplay.tripStatus === TripStatusConsts.FORECASTED) {
      const { storeNumber, scheduledDate, tripStatus, tripId, trailer, ...forecasted } = tripToDisplay;
      groupedByDate[date] = { storeNumber, scheduledDate, tripStatus, tripId, trailer, projections: { forecasted } };
    } else if (tripToDisplay.tripStatus === TripStatusConsts.DC || tripToDisplay.tripStatus === TripStatusConsts.DS) {
      const { storeNumber, scheduledDate, tripStatus, tripId, trailer } = tripToDisplay;

      const manifested = getProjectionInfo(tripToDisplay);

      const forecastedTrip = tripsByStatus[TripStatusConsts.FORECASTED];
      let forecasted;
      if (forecastedTrip) {
        forecasted = getProjectionInfo(forecastedTrip[0]);
      }

      const manifestedPercent = calculateManifestPercent(forecasted, manifested);

      groupedByDate[date] = { storeNumber, scheduledDate, tripStatus, tripId, trailer, projections: { forecasted, manifested, manifestedPercent } };
    } else if (tripToDisplay.tripStatus === TripStatusConsts.DELIVERED || tripToDisplay.tripStatus === TripStatusConsts.SU) {
      const { storeNumber, scheduledDate, tripStatus, tripId, trailer, ...delivered } = tripToDisplay;
      groupedByDate[date] = { storeNumber, scheduledDate, tripStatus, tripId, trailer, projections: { delivered } };
    }
  }
  return groupedByDate;
};

export const getFreightHomeInBoundRange = () => {
  const sd = new Date();
  sd.setDate(sd.getDate());
  const ed = new Date(sd);
  ed.setDate(sd.getDate() + 14);

  return {
    startDate: formatDate(sd),
    endDate: formatDate(ed)
  };
};