import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { AGGREGATION_OPTIONS } from '../../../constants/omniConstants';
import { GeoFilter } from 'cx-ppd-client-components';
import { CustomSelect as OmniSelect } from 'cx-ppd-client-components';
import { IFiltersProps, IFiltersState } from '../../types/shared/Common/Filters.types';

class Filters extends React.PureComponent<IFiltersProps, IFiltersState>{
  render() {
    const { geoData, geoValue, defaultStoreId, onGeoChange, viewByValue, onViewByChange, isMobile, viewByOptions } = this.props;
    return (
      <Row className="dropdowns">
        <GeoFilter
          name="geo"
          geoData={geoData}
          value={geoValue}
          defaultStoreId={defaultStoreId}
          onChange={onGeoChange}
          isMobile={isMobile}
        />
        <Col lg={true} xs="12">
          <OmniSelect
            name="viewBy"
            placeholder="View By"
            options={viewByOptions || AGGREGATION_OPTIONS}
            value={viewByValue}
            labelKey="label"
            valueKey="value"
            onChange={onViewByChange}
            isMobile={isMobile}
          />
        </Col>
      </Row>
    );
  }
}

export default Filters;