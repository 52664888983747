import * as React from 'react';
import { Navbar, NavItem } from 'react-bootstrap';
import { OmniNav } from '../../dashboards/OmniDashboard/OmniNav';
import OmniNotification from '../../../components/dashboards/OmniDashboard/OmniNotification';
import { cs } from '../../../utility/Common';
import { IDashboardHeaderProps } from '../../types/shared/DashboardItems/DashboardHeader.types';

const DashboardHeader = ({ config, modulesNavigation, notifications }: IDashboardHeaderProps) => {
  const { name, showNotifications, showLogo, showNav = true, isSmartDasboard } = config;
  return (
    <div className="dashboard-header bg-white">
      <Navbar>
        {showLogo && <img style={{ width: '92px' }} src={require("../../../assets/images/kohlslogo_black.svg")} />}
        {name && <Navbar.Brand className={cs("font-bold", { "stick-left": showLogo })}>{name}</Navbar.Brand>}
        {showNotifications && (
          <span className="ml-auto">
            <NavItem>
              <OmniNotification notifications={notifications} isSmartDasboard={isSmartDasboard} />
            </NavItem>
          </span>
        )}
        {showNav &&
          (showNotifications ?
            <OmniNav navigation={modulesNavigation} />
            : (
              <span className="ml-auto">
                <OmniNav navigation={modulesNavigation} />
              </span>
            )
          )
        }
      </Navbar>
    </div >
  );
};

export default DashboardHeader;