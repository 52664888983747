import { unionArrayByKey } from "./storeLeaderboard";
import { sortRows } from "cx-ppd-client-components";

export enum RecordType {
  COMPANY = 'COMPANY',
  VOLUME = 'VOLUME',
  TERRITORY = 'TERRITORY',
  REGION = 'REGION',
  DISTRICT = 'DISTRICT',
  STORE = 'STORE',
  NONE = ''
}

export const createAssociateTableData = (associatesData: any[] | null, aggregatedData: any, filters: any, sortColumn?: string, sortDirection?: string, isMobileSmartAssociate?: any) => {
  if (!associatesData) {
    associatesData = [];
  }
  const rows: any[] = [{ ...aggregatedData.company, type: RecordType.COMPANY, associate: "Kohl's", associateName: "Average" }];

  return rows.concat(getByTerritories(associatesData, aggregatedData, filters, sortColumn, sortDirection, isMobileSmartAssociate));
};

const getByTerritories = (associatesData: any[], aggregatedData: any, filters: any, sortColumn?: string, sortDirection?: string, isMobileSmartAssociate?: any) => {
  const { territories, viewBy } = filters;
  if (territories.length === 0) {
    return [];
  }

  if (!viewBy.find((viewByOption: any) => viewByOption.value === 'territories')) {
    return getByRegions(associatesData, aggregatedData, filters, sortColumn === "associate" ? "regionNumber" : sortColumn, sortDirection, undefined, isMobileSmartAssociate, sortColumn === 'associate' ? true : false);
  }

  let rows: any[] = [];
  const allTerritories = unionArrayByKey(territories, aggregatedData.territories, "territoryNumber");
  const sortedTerritories = sortRows(allTerritories, sortColumn === 'associate' ? 'territoryNumber' : sortColumn, sortDirection);

  sortedTerritories.forEach((territory: any) => {
    const { territoryNumber, territoryName } = territory;
    const territoryData = aggregatedData.territories[territoryNumber];
    if (territoryData && territoryData.rank) {
      rows.push({ ...territoryData, type: RecordType.TERRITORY, rank: `T${territoryNumber}`, associateName: isMobileSmartAssociate ? `T${territoryNumber}` : 'Associate Average', territoryNumber, territoryName });
    } else if (territoryData) {
      rows.push({ ...territoryData, type: RecordType.TERRITORY, associate: `T${territoryNumber}`, associateName: isMobileSmartAssociate ? `T${territoryNumber}` : 'Associate Average', territoryNumber, territoryName });
    }
    rows = rows.concat(getByRegions(associatesData, aggregatedData, filters, sortColumn === "associate" ? "regionNumber" : sortColumn, sortDirection, territoryNumber, isMobileSmartAssociate, sortColumn === 'associate' ? true : false));
  });

  return rows;
};

const getByRegions = (associatesData: any[], aggregatedData: any, filters: any, sortColumn?: string, sortDirection?: string, territoryNumber?: number, isMobileSmartAssociate?: any, sortColumnAssociate?: boolean) => {
  const { regions, viewBy } = filters;
  if (regions.length === 0) {
    return [];
  }

  if (!viewBy.find((viewByOption: any) => viewByOption.value === 'regions')) {
    return getByDistricts(associatesData, aggregatedData, filters, sortColumnAssociate ? "districtNumber" : sortColumn, sortDirection, territoryNumber, undefined, isMobileSmartAssociate, sortColumnAssociate);
  }

  let rows: any[] = [];
  const allRegions = unionArrayByKey(regions, aggregatedData.regions, "regionNumber");
  const filteredRegions = territoryNumber ? allRegions.filter((region: any) => region.territoryNumber === territoryNumber) : allRegions;
  const sortedRegions = sortRows(filteredRegions, sortColumn, sortDirection);

  sortedRegions.forEach((region: any) => {
    const regionNumber = region.regionNumber;
    const regionData = aggregatedData.regions[regionNumber];
    if (regionData && regionData.rank) {
      rows.push({ ...regionData, type: RecordType.REGION, rank: `R${regionNumber}`, associateName: isMobileSmartAssociate ? `R${regionNumber}` : 'Associate Average' });
    } else if (regionData) {
      rows.push({ ...regionData, type: RecordType.REGION, associate: `R${regionNumber}`, associateName: isMobileSmartAssociate ? `R${regionNumber}` : 'Associate Average' });
    }
    rows = rows.concat(getByDistricts(associatesData, aggregatedData, filters, sortColumnAssociate ? "districtNumber" : sortColumn, sortDirection, territoryNumber, regionNumber, isMobileSmartAssociate, sortColumnAssociate));
  });

  return rows;
};

const getByDistricts = (associatesData: any[], aggregatedData: any, filters: any, sortColumn?: string, sortDirection?: string, territoryNumber?: number, regionNumber?: number, isMobileSmartAssociate?: any, sortColumnAssociate?: boolean) => {
  const { districts, viewBy } = filters;
  if (districts.length === 0) {
    return [];
  }

  if (!viewBy.find((viewByOption: any) => viewByOption.value === 'districts')) {
    return getByStores(associatesData, aggregatedData, filters, sortColumnAssociate ? "storeNumber" : sortColumn, sortDirection, territoryNumber, regionNumber, undefined, isMobileSmartAssociate, sortColumnAssociate);
  }

  let rows: any[] = [];
  const allDistricts = unionArrayByKey(districts, aggregatedData.districts, "districtNumber");
  const filteredDistricts = regionNumber ? allDistricts.filter((district: any) => district.regionNumber === regionNumber) : (territoryNumber ? allDistricts.filter((district: any) => district.territoryNumber === territoryNumber) : allDistricts);
  const sortedDistricts = sortRows(filteredDistricts, sortColumn, sortDirection);

  sortedDistricts.forEach((district: any) => {
    const districtNumber = district.districtNumber;
    const districtData = aggregatedData.districts[districtNumber];

    if (districtData && districtData.rank) {
      rows.push({ ...districtData, type: RecordType.DISTRICT, rank: `D${districtNumber}`, associateName: isMobileSmartAssociate ? `D${districtNumber}` : 'Associate Average' });
    } else if (districtData) {
      rows.push({ ...districtData, type: RecordType.DISTRICT, associate: `D${districtNumber}`, associateName: isMobileSmartAssociate ? `D${districtNumber}` : 'Associate Average' });
    }
    rows = rows.concat(getByStores(associatesData, aggregatedData, filters, sortColumnAssociate ? "storeNumber" : sortColumn, sortDirection, territoryNumber, regionNumber, districtNumber, isMobileSmartAssociate, sortColumnAssociate));
  });

  return rows;
};

const getByStores = (associatesData: any[], aggregatedData: any, filters: any, sortColumn?: string, sortDirection?: string, territoryNumber?: number, regionNumber?: number, districtNumber?: number, isMobileSmartAssociate?: any, sortColumnAssociate?: boolean) => {
  const { stores, viewBy } = filters;
  if (stores.length === 0) {
    return [];
  }

  if (!viewBy.find((viewByOption: any) => viewByOption.value === 'stores')) {
    return getAssociates(associatesData, sortColumnAssociate ? "associate" : sortColumn, sortDirection, territoryNumber, regionNumber, districtNumber);
  }

  let rows: any[] = [];
  const allStores = unionArrayByKey(stores, aggregatedData.stores, "storeNumber");
  const filteredStores = districtNumber ? allStores.filter((store: any) => store.districtNumber === districtNumber) : (regionNumber ? allStores.filter((store: any) => store.regionNumber === regionNumber) : (territoryNumber ? allStores.filter((store: any) => store.territoryNumber === territoryNumber) : allStores));
  const sortedStores = sortRows(filteredStores, sortColumn, sortDirection);

  sortedStores.forEach((store: any) => {
    const storeNumber = store.storeNumber;
    const storeData = aggregatedData.stores[storeNumber];

    if (storeData && storeData.rank) {
      rows.push({ ...storeData, type: RecordType.STORE, rank: `${storeNumber}`, associateName: isMobileSmartAssociate ? `${storeNumber}` : 'Associate Average' });
    } else if (storeData) {
      rows.push({ ...storeData, type: RecordType.STORE, associate: `${storeNumber}`, associateName: isMobileSmartAssociate ? `${storeNumber}` : 'Associate Average' });
    }

    rows = rows.concat(getAssociates(associatesData, sortColumnAssociate ? "associate" : sortColumn, sortDirection, territoryNumber, regionNumber, districtNumber, storeNumber));
  });

  return rows;
};

const getAssociates = (associatesData: any[], sortColumn?: string, sortDirection?: string, territoryNumber?: number, regionNumber?: number, districtNumber?: number, storeNumber?: number) => {
  let associates: any[] = [];
  if (storeNumber) {
    associates = associatesData.filter((a: any) => a.storeNumber === storeNumber);
  } else if (districtNumber) {
    associates = associatesData.filter((a: any) => a.districtNumber === districtNumber);
  } else if (regionNumber) {
    associates = associatesData.filter((a: any) => a.regionNumber === regionNumber);
  } else if (territoryNumber) {
    associates = associatesData.filter((a: any) => a.territoryNumber === territoryNumber);
  }
  return sortRows(associates, sortColumn, sortDirection).map((associate: any) => ({ ...associate }));
};
