import * as React from 'react';
import { Card, Image } from "react-bootstrap";
import DropdownItem from 'react-bootstrap/DropdownItem';
import { NotificationStatus } from '../../../constants/omniConstants';
import { INotificationItemProps, INotificationItemState } from '../../types/dashboards/OmniDashboard/NotificationItem.types';

const notificationStyling = {
  NotBeenSeen: {
    background: "#ffe6e3",
    notification_background: "#004444",
    notification_icon_url: "../../../assets/images/notification_item.svg",
    title_color: "#000000",
    content_color: "#000000",
    close_icon: require(`../../../assets/images/close_black.svg`)
  },
  NotBeenSeenHover: {
    background: "#e80000",
    notification_background: "#ff0000",
    notification_icon_url: "../../../assets/images/notification_item.svg",
    title_color: "#ffffff",
    content_color: "#ffffff",
    close_icon: require(`../../../assets/images/close.svg`)
  },
  BeenSeen: {
    background: "#ffffff",
    notification_background: "#004444",
    notification_icon_url: "../../../assets/images/notification_item.svg",
    title_color: "#000000",
    content_color: "#000000",
    close_icon: require(`../../../assets/images/close_black.svg`),
    thumbs_up: require(`../../../assets/images/thumbs_up.svg`)
  },
  IsPositiveDefault: {
    background: "#fffcd9",
    notification_background: "#004444",
    notification_icon_url: "../../../assets/images/notification_item.svg",
    title_color: "#000000",
    content_color: "#000000",
    close_icon: require(`../../../assets/images/close_black.svg`),
    thumbs_up: require(`../../../assets/images/thumbs_up.svg`)
  }
};

class NotificationItem extends React.Component<INotificationItemProps, INotificationItemState> {
  constructor(props: INotificationItemProps) {
    super(props);
    const { notificationItem } = this.props;
    const { status, isPositive } = notificationItem;
    const { VISITED } = NotificationStatus;
    const { BeenSeen, NotBeenSeen, IsPositiveDefault } = notificationStyling;

    const config = (status === VISITED ? BeenSeen : NotBeenSeen ? isPositive ? IsPositiveDefault : NotBeenSeen : NotBeenSeen) || NotBeenSeen;

    this.state = {
      configuration: config,
      status
    };
  }

  componentDidUpdate(prevProps: any) {
    const { notificationItem } = prevProps;
    const { status } = this.state;
    const { VISITED } = NotificationStatus;
    const { BeenSeen, NotBeenSeen } = notificationStyling;

    if (notificationItem && status !== notificationItem.status) {
      this.setState({
        configuration: (notificationItem.status === VISITED ? BeenSeen : NotBeenSeen) || NotBeenSeen,
        status: notificationItem.status
      });
    }
  }

  onItemExitClick = (e: any) => {
    e.stopPropagation();
    const { notificationItem, onRemove } = this.props;
    return onRemove(notificationItem.notificationId);
  }

  onHoverHandler = () => {
    const { notificationItem } = this.props;
    const { status, isPositive } = notificationItem;
    const { VISITED } = NotificationStatus;
    const { BeenSeen, NotBeenSeen, IsPositiveDefault, NotBeenSeenHover } = notificationStyling;

    const config = (status === VISITED ? BeenSeen : NotBeenSeen ? isPositive ? IsPositiveDefault : NotBeenSeenHover : NotBeenSeen) || NotBeenSeen;
    this.setState({
      configuration: config
    });
  }

  offHoverHandler = () => {
    const { notificationItem } = this.props;
    const { status, isPositive } = notificationItem;
    const { VISITED } = NotificationStatus;
    const { BeenSeen, NotBeenSeen, IsPositiveDefault } = notificationStyling;

    const config = (status === VISITED ? BeenSeen : BeenSeen ? isPositive ? IsPositiveDefault : NotBeenSeen : NotBeenSeen) || NotBeenSeen;
    this.setState({
      configuration: config
    });
  }

  onItemClick = (e: any) => {
    const { notificationItem, onMarkItemSelected } = this.props;
    const { notificationId, shipmentType } = notificationItem;
    return onMarkItemSelected(notificationId, shipmentType);
  }

  render() {
    const { onItemClick, onHoverHandler, offHoverHandler, onItemExitClick } = this;
    const { notificationItem } = this.props;
    const { configuration } = this.state;

    return (
      <DropdownItem className="notifications-dropdownItem" onClick={onItemClick} onMouseEnter={onHoverHandler} onMouseLeave={offHoverHandler}>
        <Card className="notification-card">
          <Card.Body className="notification-card__body" style={{ background: `${configuration.background}` }}>
            <Card.Text className="notification-card__body--text">
              <span style={{ color: `${configuration.title_color}` }}>
                <b>{notificationItem.title}</b>
              </span>&nbsp;-&nbsp;
              <span style={{ color: `${configuration.content_color}` }}>{notificationItem.description}</span>
              <br />
              <Image className="notification-card__body--close-ico" src={configuration.close_icon} onClick={onItemExitClick} />
              {notificationItem.isPositive && <Image src={configuration.thumbs_up} />}
            </Card.Text>
          </Card.Body>
        </Card>
      </DropdownItem>
    );
  }

}
export default NotificationItem;