export const DateSelectionTypes = {
  Today: 'TODAY',
  Yesterday: 'YESTERDAY',
  WeekToDate: 'WEEK_TO_DATE',
  MonthToDate: 'MONTH_TO_DATE',
  QuarterToDate: 'QUARTER_TO_DATE',
  YearToDate: 'YEAR_TO_DATE',
  PeakToDate: 'PEAK_TO_DATE',
  CUSTOM_DATE_RANGE: 'CUSTOM_DATE_RANGE',
  TWO_WEEKS_FROM_TODAY: 'TWO_WEEKS_FROM_TODAY',
  CURRENT_MONTH: 'CURRENT_MONTH'
};

export const DateSelection = [
  {
    label: "Today",
    value: DateSelectionTypes.Today
  },
  {
    label: "Yesterday",
    value: DateSelectionTypes.Yesterday
  },
  {
    label: "Week to Date",
    value: DateSelectionTypes.WeekToDate
  },
  {
    label: "Month to Date",
    value: DateSelectionTypes.MonthToDate
  },
  {
    label: "Quarter to Date",
    value: DateSelectionTypes.QuarterToDate
  },
  {
    label: "Year to Date",
    value: DateSelectionTypes.YearToDate
  },
  {
    label: "Peak",
    value: DateSelectionTypes.PeakToDate
  },
  {
    label: "Custom",
    value: DateSelectionTypes.CUSTOM_DATE_RANGE
  }
];

export const FiscalDatesTypes = {
  [DateSelectionTypes.WeekToDate]: 'WTD',
  [DateSelectionTypes.MonthToDate]: 'MTD',
  [DateSelectionTypes.QuarterToDate]: 'QTD',
  [DateSelectionTypes.YearToDate]: 'YTD',
  [DateSelectionTypes.PeakToDate]: 'PTD'
};