import axios, { AxiosRequestConfig, AxiosInstance, AxiosResponse } from 'axios';
import { env } from '../envConfig';
import { ApiHeaders } from '../utility/ApiHeaders';
import { SplunkConsole } from '../utility/SplunkLogging';

const axiosInst: AxiosInstance = axios.create();
axiosInst.interceptors.request.use((config) => {

  Object.assign(config.headers.common, ApiHeaders());
  return config;
});

const logSplunkError = (url: string, error: any) => {
  SplunkConsole.error({ URL: url, error });
};

const envVariableWithCredentials = env.REACT_APP_API_REQUEST_WITH_CREDENTIALS_PARAM ? JSON.parse(env.REACT_APP_API_REQUEST_WITH_CREDENTIALS_PARAM!) : false;

export const http = {
  get(url: string, config?: AxiosRequestConfig, withCredentialsParam?: any): Promise<any> {
    const paramValue = typeof withCredentialsParam !== "undefined" ? withCredentialsParam : envVariableWithCredentials;
    return axiosInst
      .get(url, { ...config, withCredentials: paramValue })
      .then(({ data }: AxiosResponse) => {
        return { data };
      })
      .catch(e => {
        logSplunkError(url, e);
        throw e;
      });
  },
  post(url: string, requestData?: any, config?: AxiosRequestConfig, withCredentialsParam?: any) {
    const paramValue = typeof withCredentialsParam !== "undefined" ? withCredentialsParam : envVariableWithCredentials;
    return axiosInst
      .post(url, requestData, { ...config, withCredentials: paramValue })
      .then(({ data }: AxiosResponse) => {
        return { data };
      })
      .catch(e => {
        logSplunkError(url, e);
        throw e;
      });
  },
  put(url: string, requestData?: any, config?: AxiosRequestConfig, withCredentialsParam?: any) {
    const paramValue = typeof withCredentialsParam !== "undefined" ? withCredentialsParam : envVariableWithCredentials;
    return axiosInst
      .put(url, requestData, { ...config, withCredentials: paramValue })
      .then(({ data }: AxiosResponse) => {
        return { data };
      })
      .catch(e => {
        logSplunkError(url, e);
        throw e;
      });
  }
};