import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isMobile } from 'react-device-detect';
import { omnitureTracking } from "../../../../../utility/OmnitureTracking";

import { DateSelectionTypes, FiscalDatesTypes } from '../../../../../constants/DateRangePickerConstant';
import { updateSelectedDateType, updateGeoFilterData } from '../../../../../store/actions/leaderboardActions';
import { Loader } from 'cx-ppd-client-components';
import { getDefaultLeaderboardStore } from '../AssociateLeaderboard/AssociateLeaderboardWrapper';
import MobileStoreLeaderBoardWrapper from './MobileStoreLeaderBoardWrapper';
import DesktopStoreLeaderBoardWrapper from './DesktopStoreLeaderboardWrapper';
import DashboardFooter from '../../DashboardFooter';
import { detectRole } from '../../../../../utility/smartUtils';
import { sortStoreLeaderboardData } from '../../../../../store/actions/omniAction';
import { IAppState } from '../../../../../types';

export interface IQueryParams {
  startDate: string;
  endDate: string;
  territoryNumber: string;
  fiscalDateFilter?: string;
}

class StoreLeaderBoardWrapper extends React.Component<any, any> {
  componentDidMount() {
    const { selectedDateType, storeData, leaderboardType, dateRangePicker, territories, getNotifications } = this.props;
    this.props.getStoreLeaderboardMetaDataSchema({ type: isMobile ? leaderboardType.mobile : leaderboardType.web });
    if (!storeData) {
      this.updateDateSelectionTypes(selectedDateType ? selectedDateType : DateSelectionTypes.Today);
    }

    this.setDefaultStoreId();
    if (territories && territories.length > 0) {
      if (getNotifications) {
        getNotifications();
      }
      this.getLeaderboardData({
        startDate: dateRangePicker.startDate,
        endDate: dateRangePicker.endDate,
        territoryNumber: territories.map((element: any) => element.territoryNumber).join(",")
      });
    }
  }

  componentDidUpdate(prevProps: any) {
    const { geos } = this.props;

    if (prevProps.geos !== geos) {
      this.setDefaultStoreId();
    }

  }

  componentWillUnmount() {
    const { resetFilters } = this.props;
    if (resetFilters) {
      resetFilters();
    }
  }

  setDefaultStoreId = () => {
    const { dateRangePicker, config, userData, getNotifications } = this.props;
    let isCustomRoles;

    if (config.customRoles) {
      const { role } = userData.userRole;
      isCustomRoles = detectRole(role);
    }

    const updatedValue = getDefaultLeaderboardStore(this.props, isCustomRoles);
    if (!updatedValue) {
      return;
    }
    this.props.updateGeoFilterData(updatedValue);
    if (getNotifications) {
      getNotifications(updatedValue);
    }
    this.props.getStoreLeaderboardData({
      startDate: dateRangePicker.startDate,
      endDate: dateRangePicker.endDate,
      territoryNumber: updatedValue.territories && updatedValue.territories.length > 0 ? updatedValue.territories.map((element: any) => element.territoryNumber).join(",") : null
    });
  }

  getLeaderboardData = (payload: any) => {
    const { isFiscalDateFilter, selectedDateType } = this.props;

    if (isFiscalDateFilter && selectedDateType) {
      payload.fiscalDateFilter = FiscalDatesTypes[selectedDateType];
    }

    this.props.getStoreLeaderboardData(payload);
  }

  updateDateSelectionTypes = (selectedDateType: any, datePayLoad?: any) => {
    const { territories, fiscalCalendar, startEndDate, isFiscalDateFilter, getNotifications } = this.props;
    const queryParams: IQueryParams = {
      territoryNumber: territories && territories.length > 0 ? territories.map((element: any) => element.territoryNumber).join(',') : null,
      startDate: "",
      endDate: ""
    };
    if (datePayLoad && datePayLoad.startDate) {
      queryParams.startDate = datePayLoad.startDate;
      queryParams.endDate = datePayLoad.endDate;
    } else {
      const dateRange = startEndDate(selectedDateType, fiscalCalendar);
      if (dateRange) {
        this.props.updateSelectedDateType(selectedDateType, dateRange);
        queryParams.startDate = dateRange.startDate;
        queryParams.endDate = dateRange.endDate;
      }
    }
    if (isFiscalDateFilter) {
      queryParams.fiscalDateFilter = FiscalDatesTypes[selectedDateType];
    }
    if (getNotifications) {
      getNotifications();
    }
    if (queryParams.startDate && queryParams.endDate) {
      this.props.getStoreLeaderboardData(queryParams);
    }

  }

  render() {
    const {
      storeDataLoading,
      metaData,
      sortColumn,
      sortDirection,
      onGridSort,
      rowsData,
      territories,
      config: {
        theme,
        viewByOptions,
        filtersConfig,
        metricDefinition,
        stickyRows
      },
      modulesNavigation,
      dashboardsNavigation,
      startDate,
      endDate,
      geos,
      selectedDateType,
      calendarLoading,
      storeLastUpdatedOn,
      api,
      showExportLoader,
      showLoaderOnExport,
      hideLoaderFromExport,
      exportLeaderboardDataCsv,
      trackName,
      getNotifications
    } = this.props;

    omnitureTracking.trackPage(trackName);

    return (
      <div className={`leaderboards ${theme}-leaderboards`}>
        {(storeDataLoading || metaData.storeMetaDataLoading || calendarLoading) && <Loader />}
        {isMobile ? (
          <MobileStoreLeaderBoardWrapper
            rowsData={rowsData}
            onGridSort={onGridSort}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            metaData={metaData}
            territories={territories}
            viewByOptions={filtersConfig.viewByOptions}
            getLeaderboardData={this.getLeaderboardData}
            updateDateSelectionTypes={this.updateDateSelectionTypes}
            dashboardsNavigation={dashboardsNavigation}
            getNotifications={getNotifications}
            stickyRowsIdxs={stickyRows && stickyRows.store ? stickyRows.store : null}
          />
        ) : (
            <DesktopStoreLeaderBoardWrapper
              rowsData={rowsData}
              metaData={metaData}
              territories={territories}
              startDate={startDate}
              endDate={endDate}
              geos={geos}
              selectedDateType={selectedDateType}
              getLeaderboardData={this.getLeaderboardData}
              updateDateSelectionTypes={this.updateDateSelectionTypes}
              dashboardsNavigation={dashboardsNavigation}
              api={api}
              viewByOptions={viewByOptions}
              filtersConfig={filtersConfig}
              metricDefinition={metricDefinition}
              showExportLoader={showExportLoader}
              showLoaderOnExport={showLoaderOnExport}
              hideLoaderFromExport={hideLoaderFromExport}
              exportLeaderboardDataCsv={exportLeaderboardDataCsv}
              onGridSort={onGridSort}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              stickyRowsIdxs={stickyRows && stickyRows.store ? stickyRows.store : null}
              getNotifications={getNotifications}
            />
          )
        }
        <DashboardFooter
          lastUpdatedOn={storeLastUpdatedOn}
          name={name}
          modulesNavigation={modulesNavigation}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: IAppState) => {
  const { storeDataLoading, storeData, rowsData, metaData, sortColumn, sortDirection } = state.omni.storeLeaderboard;
  const { geos, filters, dateRangePicker } = state.omni;
  const { startDate, endDate, selectedDateType } = dateRangePicker;
  const { territories } = filters;
  const { calendarLoading, fiscalCalendar } = state.postTruckAnalysis;
  return {
    storeDataLoading,
    storeData,
    sortColumn,
    sortDirection,
    rowsData,
    startDate,
    endDate,
    selectedDateType,
    metaData,
    territories,
    geos,
    filters,
    dateRangePicker,
    calendarLoading,
    fiscalCalendar,
    userData: state.commonReducer.userInfoData
  };
};

const mapDispatchToProps = (dispatch: any): any => {
  return bindActionCreators({
    updateSelectedDateType,
    updateGeoFilterData,
    onGridSort: sortStoreLeaderboardData
  }, dispatch);
};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(StoreLeaderBoardWrapper);