import * as React from 'react';
import { Modal, Col } from 'react-bootstrap';
import { cs } from '../../utility/Common';

class FilterModal extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      showFilterModal: false
    };
  }

  handleShow = () => {
    this.setState({ showFilterModal: true });
  }

  handleClose = () => {
    this.setState({ showFilterModal: false });
  }

  render() {
    const { modalclassName } = this.props;
    return (
      <div className="filter-container">
        <a href="javascript:;" onClick={this.handleShow} className='filter'>Filter</a>
        <Modal className={cs("filter-modal", modalclassName)} centered={true} aria-labelledby="contained-modal-title-vcenter" show={this.state.showFilterModal} onHide={this.handleClose}>
          <Modal.Body>
            <div className="filter-container" onClick={this.handleClose}>
              <span><img src={require('../../assets/images/left-chevron.png')} />Filter</span>
            </div>
            <Col>
              {this.props.children}
            </Col>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default FilterModal;