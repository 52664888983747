export enum VIEW_BY {
  UNITS = 'UNIT',
  AMOUNT = 'AMOUNT'
}

export interface IOption {
  label: string;
  value: string;
  [extraProps: string]: any;
}

export interface IMetricValue {
  value: number | null;
  tip?: string;
  tipRule?: string;
}

// interface IProtip {
//   tip?: string;
// }

export interface ITotalBatchData {
  valueInQueue: number;
  totalDemandActual: number;
  totalDemandPlan: number;
  totalDemandProjected: number;
  totalDemandProjectedVariance: number;
  totalProcessedActual: number;
  totalProcessedPlan: number;
  totalProcessedProjected: number;
  totalProcessedProjectedVariance: number;
}
export interface ISfsAging {
  tip?: string;
  in48Hours: number;
  in24Hours: number;
  in18Hours: number;
  in12Hours: number;
}

export interface ISfsData {
  valueInQueue: number;
  shippedIn1Day: IMetricValue;
  backlog: IMetricValue;
  aging: ISfsAging | null;
  tip?: string;
  actualUph: IMetricValue;
  goalUph: number;
  resourceValue: number;
  resourceRate: IMetricValue;
  resourceRateGoal: number;
  deptResourceRate: number;
  cancelRate: number;
  demandActual: number;
  demandPlan: number;
  demandProjected: number;
  demandProjectedVariance: number;
  processedActual: number;
  processedPlan: number;
  processedProjected: number;
  processedProjectedVariance: number;
}

export interface IBopusAging {
  tip?: string;
  in12Hours: number;
  in4Hours: number;
  in1Hour: number;
}
export interface IBopusData {
  tip?: string;
  valueInQueue: number;
  processedIn1Hour: IMetricValue;
  processedIn1HourGoal: number;
  aging: IBopusAging | null;
  actualUph: IMetricValue;
  goalUph: number;
  cancelValue: number;
  cancelRate: IMetricValue;
  cancelRateGoal: number;
  deptCancelRate: number;
  demandActual: number;
  demandPlan: number;
  demandProjected: number;
  demandProjectedVariance: number;
  processedActual: number;
  processedPlan: number;
  processedProjected: number;
  processedProjectedVariance: number;
}

export interface IBossAging {
  tip?: string;
  in6Hours: number;
}

export interface IBossData {
  tip?: string;
  valueInQueue: number;
  processedIn6Hours: IMetricValue;
  processedIn6HoursGoal: number;
  aging: IBossAging | null;
  actualUph: IMetricValue;

  goalUph: number;
  cancelValue: number;
  cancelRate: number;
  cancelRateGoal: number;
  demandActual: number;
  demandPlan: number;
  demandProjected: number;
  demandProjectedVariance: number;
  processedActual: number;
  processedPlan: number;
  processedProjected: number;
  processedProjectedVariance: number;
}

export interface IOnlinePickupData {
  onlinePickup: number;
  bopusPickup: number;
  bossPickup: number;
  ordersPickedUpActualPerDay: number;
  ordersPickedUpSameDayRate: number;
  ordersPickedUpNextDayRate: number;
  abandonedOrdersAmount: number;
  abandonedOrdersPercent: number;
  attachmentSalesAmount: number;
  attachmentSalesPercent: number;
  attachmentSalesTransactionCount: number;
}
export interface IHealthStatusData {
  total: ITotalBatchData;
  sfs: ISfsData;
  bopus: IBopusData;
  boss: IBossData;
  onlinePickup: IOnlinePickupData;
  lastRefreshedTimestamp: string;
}

export interface IHealthStatusState {
  locationType: IOption | null;
  location: IOption | null;
  viewLastYear: boolean;
  viewBy: VIEW_BY;
  selectedDateType: string;
  startDate: string;
  endDate: string;
  isLoading: boolean;
  healthData: IHealthStatusData | null;
  lastYearData: IHealthStatusData | null;
}