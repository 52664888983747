import { SmartHealthStatusActions } from "../actionTypes";
import { AnyAction } from "redux";
import { smartDashboardApi } from "../../api/smartDashboardApi";
import { ThunkDispatch } from "redux-thunk";
import { FilterTypes } from "../../constants/smartConstants";
import { IAppState } from "../../types";
import { isCompany } from "./healthStatus";
import { IRequestParams, ISelectedDatesSmart } from "./types/healthStatusSmart.types";
import { IHealthStatusState } from "../reducers/types/smartHealthStatus.types";
import { GEOKEYS } from "../../constants/omniConstants";
import { getSmartNotificationsData, resetSmartNotificationsData } from "./smartActions";

const smartFetchNotifications = (location: any, locationType: any, dateType?: string) => {
  if (location && typeof location === 'object' && locationType) {
    if (locationType) {
      const locationKey = locationType.value;
      return getSmartNotificationsData({ [GEOKEYS[locationKey].ValueKey]: location[GEOKEYS[locationKey].ValueKey] }, location, locationType, dateType);
    }
  }
  return getSmartNotificationsData({}, location, locationType, dateType);
};

export const updateLocation = (location: any) => {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch({
      type: SmartHealthStatusActions.SMART_UPDATE_LOCATION,
      payload: location
    });
    dispatch(getHealthStatusData());
    dispatch(getHealthStatusDaySummary());
  };
};

export const updateLocationType = (locationType: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => IAppState) => {
    dispatch({
      type: SmartHealthStatusActions.SMART_UPDATE_LOCATION_TYPE,
      payload: locationType
    });
    if (isCompany(locationType)) {
      dispatch(getHealthStatusData());
      dispatch(getHealthStatusDaySummary());
    }
  };
};

export const updateFiscalDateFilter = (dateType: string) => ({
  type: SmartHealthStatusActions.SMART_UPDATE_FISCAL_DATE_FILTER,
  payload: dateType
});

export const updateSelectedDates = (params: ISelectedDatesSmart) => {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch({
      type: SmartHealthStatusActions.SMART_UPDATE_SELECTED_DATES,
      payload: params
    });
    const { startDate, endDate, dateType } = params;
    if (startDate && endDate) {
      dispatch(getHealthStatusData({ dateType }));
      dispatch(getHealthStatusDaySummary());
    }
  };
};

export const toggleViewLastYear = (value: any[]) => {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch({
      type: SmartHealthStatusActions.SMART_TOGGLE_VIEW_LAST_YEAR
    });
    if (value.length > 0) {
      dispatch(getHealthStatusData());
      dispatch(getHealthStatusDaySummary());
    } else {
      dispatch(clearHealthStatusLastYearData());
    }
  };
};

const getCompanySmartParams = (smartHealthStatus: IHealthStatusState): IRequestParams | null => {

  if (smartHealthStatus && isCompany(smartHealthStatus.locationType)) {
    const {
      startDate,
      endDate,
      locationType,
      selectedDateType
    } = smartHealthStatus;
    let filterType;
    if (locationType) {
      filterType = FilterTypes[locationType.value];
    }
    const result: IRequestParams = {
      startDate,
      endDate,
      filterType
    };

    if (selectedDateType) {
      result.dateType = selectedDateType;
    }

    return result;
  }
  return null;
};

export const getHealthStatusRequestParams = (smartHealthStatus: IHealthStatusState): IRequestParams => {
  const {
    startDate,
    endDate,
    location,
    locationType,
    selectedDateType
  } = smartHealthStatus;

  let filterType;
  let filterValue;

  if (location && locationType) {
    filterType = FilterTypes[locationType.value];
    filterValue = location[FilterTypes[locationType.value]];
  }

  const result: IRequestParams = {
    startDate,
    endDate,
    filterType,
    filterValue
  };

  if (selectedDateType) {
    result.dateType = selectedDateType;
  }

  return result;
};

export const getHealthStatusData = (options?: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => IAppState) => {
    const { smartHealthStatus } = getState();
    const { locationType, selectedDateType } = smartHealthStatus;
    let { location } = smartHealthStatus;

    if (!location) {
      location = {};
    }

    let requestParams = getCompanySmartParams(smartHealthStatus);
    if (!requestParams) {
      requestParams = getHealthStatusRequestParams(smartHealthStatus);
      if (!requestParams) {
        return Promise.resolve();
      }
    }

    if (!requestParams) {
      return Promise.resolve();
    }

    if (options && options.filterType && options.filterValue) {
      requestParams.filterType = options.filterType;
      requestParams.filterValue = options.filterValue;
      location[options.filterType] = options.filterValue || '';
    }

    if (smartHealthStatus.viewLastYear) {
      requestParams.ly = true;
    }

    if (isCompany(locationType)) {
      dispatch(resetSmartNotificationsData());
    } else {
      const dType = !(options && options.homeScreen) ? selectedDateType : undefined;
      dispatch(smartFetchNotifications(location, locationType, dType));
    }

    dispatch({ type: SmartHealthStatusActions.SMART_GET_HEALTH_STATUS_DATA });

    return smartDashboardApi.getHealthStatusData(requestParams).then((healthStatusData: any) => {
      dispatch({ type: SmartHealthStatusActions.SMART_GET_HEALTH_STATUS_DATA_SUCCESS, payload: healthStatusData });
    }).catch(() => {
      dispatch({ type: SmartHealthStatusActions.SMART_GET_HEALTH_STATUS_DATA_FAIL, payload: null });
    });

  };
};

export const getHealthStatusDaySummary = () => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => IAppState) => {
    const { smartHealthStatus } = getState();

    let requestParams = getCompanySmartParams(smartHealthStatus);
    if (!requestParams) {
      requestParams = getHealthStatusRequestParams(smartHealthStatus);
      if (!requestParams) {
        return Promise.resolve();
      }
    }

    if (smartHealthStatus.viewLastYear) {
      requestParams.ly = true;
    }

    const { startDate, ...newParams } = requestParams;
    dispatch({ type: SmartHealthStatusActions.SMART_GET_HEALTH_DAILY_SUMMARY_DATA });

    return smartDashboardApi.getHealthStatusDaySummary(newParams).then((dailySummaryData: any) => {
      dispatch({ type: SmartHealthStatusActions.SMART_GET_HEALTH_DAILY_SUMMARY_DATA_SUCCESS, payload: dailySummaryData });
    }).catch(() => {
      dispatch({ type: SmartHealthStatusActions.SMART_GET_HEALTH_DAILY_SUMMARY_DATA_FAIL, payload: null });
    });

  };
};

const clearHealthStatusLastYearData = () => ({
  type: SmartHealthStatusActions.SMART_CLEAR_HEALTH_STATUS_LAST_YEAR_DATA
});