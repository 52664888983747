import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import MetricDetails from '../../../../components/dashboards/OmniDashboard/MetricDetails';

export const HighlightInstruction = () => {
  return (
    <Row className={`highlight-instruction align-items-center bg-white`}>
      <div className="color-block" />
      <span>Ranked goals</span>
    </Row>
  );
};

interface IMetriDetailsItem {
  title: string;
  content: string;
}

const MetricDetailsContentItem = (item: IMetriDetailsItem, index: number) => {
  return <div key={index}><h5>{item.title}</h5><p>{item.content}</p></div>;
};

export const MetricDetailsContent = (props: any) => {
  return props.metricDetails.map((item: any, index: number) => {
    return MetricDetailsContentItem({ title: item.title, content: item.content }, index);
  });
};

const LeaderBoardTableHeader = ({ metricDetails }: any) => {
  return (
    <div className="align-items-center container-fluid">
      <Row >
        <Col sm="auto">
          <Row className={`highlight-instruction align-items-center bg-white`}>
            <div className="color-block" />
            <span>Highlighted columns indicate ranked goals</span>
          </Row>
        </Col>
        <Col className="text-right">
          <MetricDetails><MetricDetailsContent metricDetails={metricDetails} /></MetricDetails>
        </Col>
      </Row>
    </div>
  );
};

export default LeaderBoardTableHeader;