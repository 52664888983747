import { env } from "../envConfig";

export const EnableOmniDashboard = env.REACT_APP_ENABLE_OMNI_DASHBOARD
  ? JSON.parse(env.REACT_APP_ENABLE_OMNI_DASHBOARD)
  : false;

export const EnableSmartDashboard = env.REACT_APP_ENABLE_SMART_DASHBOARD
  ? JSON.parse(env.REACT_APP_ENABLE_SMART_DASHBOARD)
  : false;

export const EnableSpeedOmniStatusDashboard = true;

export const TripStatusConsts = {
  FORECASTED: "FORECASTED",
  DC: "DC",
  DS: "DS",
  SU: "SU",
  DELIVERED: "DELIVERED"
};

export const TripStatus = {
  FORECASTED: {
    value: "FORECASTED",
    label: "Forecasted",
    icon: require(`../assets/images/truck-icons/truck-forecasted.png`)
  },
  DC: {
    value: "DC",
    label: "Closed at DC",
    icon: require(`../assets/images/truck-icons/truck-dc.png`)
  },
  DS: {
    value: "DS",
    label: "Shipped to Store",
    icon: require(`../assets/images/truck-icons/truck-ds.png`)
  },
  SU: {
    value: "SU",
    label: "Unloaded at Store",
    icon: require(`../assets/images/truck-icons/truck-delivered.png`)
  },
  DELIVERED: {
    value: "DELIVERED",
    label: "DELIVERED",
    icon: require(`../assets/images/truck-icons/truck-delivered.png`)
  }
};

export const filterTypeConfig = {
  receivingZone: {
    label: "Receiving Zone",
    value: "receivingZone"
  },
  floorpad: {
    label: "Floorpad",
    value: "floorpad"
  },
  department: {
    label: "Department",
    value: "department"
  },
  brand: {
    label: "Brand",
    value: "brand"
  },
  sku: {
    label: "Sku",
    value: "sku"
  }
};

export const Days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];

export const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export const fiscalRowValues = {
  unitSFS: "sfsMtd",
  unitBopus: "bopusMtd",
  unitBoss: "bossMtd",
  inTransit: "inTransit",
  omniActualHours: "omniHours",
  addBackHours: "addBackHours"
};

export const fiscalRows = [
  {
    type: "text",
    text: "SFS",
    key: fiscalRowValues.unitSFS
  },
  {
    type: "text",
    text: "BPS",
    key: fiscalRowValues.unitBopus
  },
  {
    type: "text",
    text: "BOS",
    key: fiscalRowValues.unitBoss
  },
  {
    type: "image",
    text: require("../assets/images/truck.svg"),
    key: fiscalRowValues.inTransit
  },
  {
    type: "image",
    text: require("../assets/images/watch.svg"),
    key: fiscalRowValues.omniActualHours
  }
];

export const PostTruck = {
  Keys: {
    geo: {
      NameKey: "territoryName",
      ValueKey: "territoryNumber",
      Value: "id"
    },
    geos: {
      NameKey: "territoryName",
      ValueKey: "territoryNumber",
      Value: "id"
    },
    region: {
      NameKey: "regionName",
      ValueKey: "regionNumber",
      Value: "id"
    },
    district: {
      NameKey: "districtName",
      ValueKey: "districtNumber",
      Value: "id"
    }
  }
};
