import * as React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { Security, SecureRoute, ImplicitCallback } from "@okta/okta-react";
import "./assets/styles/app.scss";
import "./assets/styles/dashboard-common.css";
import { ValidateAuthUser } from './ValidateAuthUser';
import { AppRoutes } from './constants/routes';
import { env } from './envConfig';

// import Home from "./container/Home";
// import FreightHome from "./container/FreightHome";
// import ManageRoles from "./container/ManageRoles";
// import RealTimeExecution from "./container/RealTimeExecution";
// import ManifestOverview from "./container/ManifestOverview";
// import InBoundFreight from "./container/InBoundFreight";
// import PostTruckAnalysis from "./container/PostTruckAnalysis";
// import OmniDashboard from "./container/OmniDashboard";
// import SmartDashboard from "./container/SmartDashboard";

const Suspense = React.Suspense;
const lazy = React.lazy;

const Home = lazy(() => import("./container/Home"));
const FreightHome = lazy(() => import("./container/FreightHome"));
const ManageRoles = lazy(() => import("./container/ManageRoles"));
const RealTimeExecution = lazy(() => import("./container/RealTimeExecution"));
const ManifestOverview = lazy(() => import("./container/ManifestOverview"));
const InBoundFreight = lazy(() => import("./container/InBoundFreight"));
const PostTruckAnalysis = lazy(() => import("./container/PostTruckAnalysis"));
const OmniDashboard = lazy(() => import("./container/OmniDashboard"));
const SmartDashboard = lazy(() => import("./container/SmartDashboard"));

const config = {
  issuer: env.REACT_APP_OKTA_ISSUER,
  redirect_uri: window.location.origin + "/implicit/callback",
  client_id: env.REACT_APP_OKTA_CLIENT_ID
};

// const NoMatch = ({ location }: any) => (
//   <div>
//     <h3>
//       No match for <code>{location.pathname}</code>
//     </h3>
//   </div>
// );

class Navigation extends React.Component<any, any> {
  render() {
    return (
      <Router>
        <Security issuer={config.issuer} scope={["openid", "email", "profile", "groups"]} client_id={config.client_id} redirect_uri={config.redirect_uri}>
          <Suspense fallback={<div>&nbsp;</div>}>
            <Switch>
              <SecureRoute path={AppRoutes.Index} exact={true} component={ValidateAuthUser(Home)} />
              <SecureRoute path={AppRoutes.ClientRoute} exact={true} component={ValidateAuthUser(Home)} />
              <SecureRoute path={AppRoutes.Home} exact={true} component={ValidateAuthUser(Home)} />
              <SecureRoute path={AppRoutes.FreightHome} exact={true} component={ValidateAuthUser(FreightHome)} />
              <SecureRoute path={AppRoutes.ManageRoles} exact={true} component={ValidateAuthUser(ManageRoles)} />
              <SecureRoute path={AppRoutes.RealTimeExecution} exact={true} component={ValidateAuthUser(RealTimeExecution)} />
              <SecureRoute path={AppRoutes.ManfiestOverview} exact={true} component={ValidateAuthUser(ManifestOverview)} />
              <SecureRoute path={AppRoutes.InboundFright} exact={true} component={ValidateAuthUser(InBoundFreight)} />
              <SecureRoute path={AppRoutes.PostTruckAnalysis} exact={true} component={ValidateAuthUser(PostTruckAnalysis)} />
              <SecureRoute path={AppRoutes.OmniDashboard} component={ValidateAuthUser(OmniDashboard)} />
              <SecureRoute path={AppRoutes.SmartDashbaord} component={ValidateAuthUser(SmartDashboard)} />
              <Route path="/implicit/callback" component={ImplicitCallback} />
              <Route render={() => (<Redirect to="/" />)} />
            </Switch>
          </Suspense>
        </Security>
      </Router>
    );
  }
}

export default Navigation;
