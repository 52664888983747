import * as React from "react";
import { Image, Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { IBreadcrumbs, IBreadcrumb } from "../types/layout/Breadcrumbs.types";

export const Breadcrumbs = ({ homeIcon, breadcrumbs }: IBreadcrumbs) => {
  return (
    <Breadcrumb>
      {
        breadcrumbs.map(({ isHome, title, to }: IBreadcrumb, index: number) => {
          const breadcrumbItem = isHome ? (
            <Image src={homeIcon ? homeIcon : require(`../../assets/images/home-icon.svg`)} />
          ) : title;

          return (
            <li key={index} className="">
              {
                to ? (
                  <Link to={to}>
                    {breadcrumbItem}
                  </Link>
                ) : breadcrumbItem
              }
            </li>
          );
        })
      }
    </Breadcrumb>
  );
};