import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isMobile } from 'react-device-detect';
import { omnitureTracking } from "../../../../../utility/OmnitureTracking";

import { DateSelectionTypes, FiscalDatesTypes } from '../../../../../constants/DateRangePickerConstant';
import { updateSelectedDateType, updateGeoFilterData } from '../../../../../store/actions/leaderboardActions';
import { Loader, setGeoFilterValue } from 'cx-ppd-client-components';
import MobileAssociateLeaderboardWrapper from './MobileAssociateLeaderboardWrapper';
import DesktopAssociateLeaderboardWrapper from './DesktopAssociateLeaderboardWrapper';
import DashboardFooter from '../../DashboardFooter';
import { IQueryParams } from '../StoreLeaderboard/StoreLeaderboardWrapper';
import { detectRole } from '../../../../../utility/smartUtils';
import { sortAssociateLeaderboardData } from '../../../../../store/actions/omniAction';
import { IAssociateLeaderBoardWrapperProps, IAssociateLeaderBoardWrapperStateToProps, IAssociateLeaderBoardWrapperDispatchToProps, IAssociateLeaderBoardWrapperOwnProps } from '../../../../types/shared/DashboardItems/LeaderBoards/AssociateLeaderboard/AssociateLeaderBoardWrapper.types';
import { IAppState } from '../../../../../types';

export const getDefaultLeaderboardStore = (props: any, isCustomRoles?: boolean) => {
  const { geos, userData, filters, territories: filterterritories } = props;
  const { stores, territories: geoTerritories } = geos;
  if (!stores || !geoTerritories || (filterterritories && filterterritories.length > 0)) {
    return;
  }
  const defaultStoreId = Number.parseInt(userData.storeId, 10);
  let defaultStore;
  if (defaultStoreId) {
    defaultStore = stores.find((store: any) => store.storeNumber === defaultStoreId);
  }
  if (!defaultStore || isCustomRoles) {
    return setGeoFilterValue(geos, filters, "territories", geoTerritories);
  }
  return setGeoFilterValue(geos, filters, "stores", [defaultStore]);
};

export class AssociateLeaderBoardWrapper extends React.Component<IAssociateLeaderBoardWrapperProps, any> {
  componentDidMount() {
    const { associateData, leaderboardType, dateRangePicker, filters: { territories }, getNotifications } = this.props;
    this.props.getAssociateLeaderboardMetaDataSchema({ type: isMobile ? leaderboardType.mobile : leaderboardType.web });
    if (!associateData) {
      this.updateDateSelectionTypes(dateRangePicker.selectedDateType ? dateRangePicker.selectedDateType : DateSelectionTypes.Today);
    }

    this.setDefaultStoreId();
    if (territories && territories.length > 0) {
      if (getNotifications) {
        getNotifications();
      }
      this.getLeaderboardData({
        startDate: dateRangePicker.startDate,
        endDate: dateRangePicker.endDate,
        territoryNumber: territories.map((element: any) => element.territoryNumber).join(",")
      });
    }
  }

  componentDidUpdate(prevProps: any) {
    const { geos } = this.props;
    if (prevProps.geos !== geos) {
      this.setDefaultStoreId();
    }
  }

  setDefaultStoreId = () => {
    const { userData, dateRangePicker, config, getNotifications } = this.props;
    let isCustomRoles;

    if (config.customRoles) {
      const { role } = userData.userRole;
      isCustomRoles = detectRole(role);
    }

    const updatedValue = getDefaultLeaderboardStore(this.props, isCustomRoles);
    if (!updatedValue) {
      return;
    }
    this.props.updateGeoFilterData(updatedValue);
    if (getNotifications) {
      getNotifications(updatedValue);
    }
    this.props.getAssociateLeaderboardData({
      startDate: dateRangePicker.startDate,
      endDate: dateRangePicker.endDate,
      territoryNumber: updatedValue.territories && updatedValue.territories.length > 0 ? updatedValue.territories.map((element: any) => element.territoryNumber).join(",") : null
    });
  }

  getLeaderboardData = (payload: any) => {
    const { isFiscalDateFilter, dateRangePicker: { selectedDateType } } = this.props;

    if (isFiscalDateFilter && selectedDateType) {
      payload.fiscalDateFilter = FiscalDatesTypes[selectedDateType];
    }

    this.props.getAssociateLeaderboardData(payload);
  }

  updateDateSelectionTypes = (selectedDateType: any, datePayLoad?: any) => {
    const { filters: { territories }, fiscalCalendar, startEndDate, isFiscalDateFilter, getNotifications } = this.props;
    const queryParams: IQueryParams = {
      territoryNumber: territories && territories.length > 0 ? territories.map((element: any) => element.territoryNumber).join(',') : "",
      startDate: "",
      endDate: ""
    };
    if (datePayLoad && datePayLoad.startDate) {
      queryParams.startDate = datePayLoad.startDate;
      queryParams.endDate = datePayLoad.endDate;
    } else {
      const dateRange = startEndDate(selectedDateType, fiscalCalendar);
      this.props.updateSelectedDateType(selectedDateType, dateRange);
      queryParams.startDate = dateRange.startDate;
      queryParams.endDate = dateRange.endDate;
    }
    if (isFiscalDateFilter) {
      queryParams.fiscalDateFilter = FiscalDatesTypes[selectedDateType];
    }
    if (getNotifications) {
      getNotifications();
    }
    this.props.getAssociateLeaderboardData(queryParams);

  }

  render() {
    const {
      associateDataLoading,
      rowsData,
      sortColumn,
      sortDirection,
      onGridSort,
      metaData,
      associateLastUpdatedOn,
      dateRangePicker: { startDate, endDate, selectedDateType },
      filters: { territories },
      config: {
        name,
        theme,
        filtersConfig,
        metricDefinition,
        stickyRows
      },
      modulesNavigation,
      geos,
      dashboardsNavigation,
      calendarLoading,
      api,
      showExportLoader,
      showLoaderOnExport,
      hideLoaderFromExport,
      exportLeaderboardDataCsv,
      trackName,
      getNotifications
    } = this.props;

    omnitureTracking.trackPage(trackName);

    return (
      <>
        <div className={`leaderboards ${theme}-leaderboards`}>
          {(associateDataLoading || metaData.associateMetaDataLoading || calendarLoading) && <Loader />}
          {isMobile ? (
            <MobileAssociateLeaderboardWrapper
              rowsData={rowsData}
              onGridSort={onGridSort}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              metaData={metaData}
              territories={territories}
              getLeaderboardData={this.getLeaderboardData}
              updateDateSelectionTypes={this.updateDateSelectionTypes}
              dashboardsNavigation={dashboardsNavigation}
              getNotifications={getNotifications}
              stickyRowsIdxs={stickyRows && stickyRows.associate ? stickyRows.associate : null}
            />
          ) : (
              <DesktopAssociateLeaderboardWrapper
                rowsData={rowsData}
                metaData={metaData}
                territories={territories}
                startDate={startDate}
                endDate={endDate}
                geos={geos}
                selectedDateType={selectedDateType}
                getLeaderboardData={this.getLeaderboardData}
                updateDateSelectionTypes={this.updateDateSelectionTypes}
                dashboardsNavigation={dashboardsNavigation}
                filtersConfig={filtersConfig}
                metricDefinition={metricDefinition}
                api={api}
                showExportLoader={showExportLoader}
                showLoaderOnExport={showLoaderOnExport}
                hideLoaderFromExport={hideLoaderFromExport}
                exportLeaderboardDataCsv={exportLeaderboardDataCsv}
                onGridSort={onGridSort}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
                stickyRowsIdxs={stickyRows && stickyRows.associate ? stickyRows.associate : null}
                getNotifications={getNotifications}
              />
            )
          }
          <DashboardFooter
            lastUpdatedOn={associateLastUpdatedOn}
            name={name}
            modulesNavigation={modulesNavigation}
          />
        </div>
      </>
    );
  }
}


const mapStateToProps = (state: IAppState): IAssociateLeaderBoardWrapperStateToProps => {
  const { geos, filters, dateRangePicker } = state.omni;
  const { calendarLoading, fiscalCalendar } = state.postTruckAnalysis;
  return {
    ...state.omni.associateLeaderboard,
    geos,
    filters,
    dateRangePicker,
    calendarLoading,
    fiscalCalendar,
    userData: state.commonReducer.userInfoData
  };
};

const mapDispatchToProps = (dispatch: any): IAssociateLeaderBoardWrapperDispatchToProps => {
  return bindActionCreators({
    updateSelectedDateType,
    updateGeoFilterData,
    onGridSort: sortAssociateLeaderboardData
  }, dispatch);
};

export default connect<IAssociateLeaderBoardWrapperStateToProps, IAssociateLeaderBoardWrapperDispatchToProps, IAssociateLeaderBoardWrapperOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(AssociateLeaderBoardWrapper);