import * as actionTypes from '../actionTypes';
import { IManifestState, ITotalOverview, IFilterTotalOverview, ITripManifestCategories, ITripManifest } from './types/manifestOverview.types';

const initalState: IManifestState = {
  hasError: false,
  tripManifestLoading: false,
  storesLoading: false,
  tripsLoading: false,
  stores: [],
  trips: [],
  tripManifest: {
    tripId: null,
    tripStatus: '',
    tripStatusCreation: '',
    totalOverview: {} as ITotalOverview,
    filterTotalOverview: {} as IFilterTotalOverview,
    filterDetails: []
  } as ITripManifest,
  tripManifestCategories: {} as ITripManifestCategories
};

export default function manifestStore(state = initalState, action: any) {
  switch (action.type) {
    case actionTypes.GET_STORES:
      return { ...state, storesLoading: true, stores: [] };
    case actionTypes.GET_STORES_SUCCESS:
      return { ...state, storesLoading: false, stores: action.payload };
    case actionTypes.GET_TRIPS:
      return { ...state, trips: [], tripManifestCategories: {}, tripManifest: initalState.tripManifest, tripsLoading: true };
    case actionTypes.GET_TRIPS_SUCCESS:
      return { ...state, trips: action.payload, tripsLoading: false };
    case actionTypes.GET_TRIPS_FAILED:
      return { ...state, initalState, tripsLoading: false };

    case actionTypes.GET_TRIP_MANIFEST:
      return { ...state, tripManifest: initalState.tripManifest, tripManifestLoading: true };
    case actionTypes.GET_TRIP_MANIFEST_SUCCESS:
      return { ...state, tripManifest: action.payload, tripManifestLoading: false };
    case actionTypes.GET_TRIP_MANIFEST_FAILED:
      return { ...state, tripManifest: initalState.tripManifest, tripManifestLoading: false };
    case actionTypes.CLEAR_TRIPS:
      return { ...state, trips: initalState.trips, tripManifest: initalState.tripManifest, tripManifestCategories: initalState.tripManifestCategories };
    case actionTypes.CLEAR_TRIP_MANIFEST:
      return { ...state, tripManifest: initalState.tripManifest };

    case actionTypes.GET_TRIP_MANIFEST_CATEGORIES:
      return { ...state, tripManifestCategories: {} };
    case actionTypes.GET_TRIP_MANIFEST_CATEGORIES_SUCCESS:
      return { ...state, tripManifestCategories: action.payload };
    case actionTypes.GET_TRIP_MANIFEST_CATEGORIES_FAILED:
      return { ...state, tripManifestCategories: {} };

    case actionTypes.HAS_ERROR:
      return { ...state, hasError: action.payload, storesLoading: false };
    default:
      return state;
  }
}