import { NotificationStatus } from "../../constants/omniConstants";


export const getUpdatedNotificationsData = (notifications: any[]) => {
  const notificationIds = loadVisitedNotificationIds();
  const notificationsListTmp = notifications && notifications.filter((item: any) => {
    const ids = notificationIds.filter((id: any) => item.notificationId === id.notificationId);
    if (ids && ids.length > 0) {
      item.status = ids[0].status;
      return (item.status === NotificationStatus.NONE || item.status === NotificationStatus.VISITED) ? item : false;
    }
    if (!item.status) {
      item.status = NotificationStatus.NONE;
      return item;
    }
    return false;
  });
  return notificationsListTmp;
};

export const removeNotificationItem = (notificationsData: any[], notificationId: any) => {
  const tmp = updateNotificationList(notificationsData, notificationId, NotificationStatus.REMOVED);
  return tmp && tmp.filter((item: any) => item.notificationId !== notificationId);
};

const updateNotificationList = (notificationsData: any[], notificationId: any, status: NotificationStatus) => {
  const items = notificationsData && notificationsData.filter((item: any) => item.notificationId === notificationId);
  if (items && items.length > 0) {
    updateNotificationStatus(items[0], status);
  }
  updateLocalStorage(prepareLocalStorageDate(notificationsData));
  return notificationsData;
};

const updateNotificationStatus = (item: any, status: NotificationStatus) => {
  if (item) {
    item.status = status;
  }
};

const prepareLocalStorageDate = (notificationsData: any) => {
  const tmpItems: any[] = [];
  if (notificationsData) {
    notificationsData.map((item: any) => {
      tmpItems.push({ notificationId: item.notificationId, status: item.status });
    });
  }
  return tmpItems;
};

export const markAllRead = (notificationsData: any[]) => {
  if (!notificationsData) {
    return;
  }
  notificationsData.map((item: any) => {
    updateNotificationStatus(item, NotificationStatus.VISITED);
  });
  updateLocalStorage(prepareLocalStorageDate(notificationsData));
  return notificationsData;
};

export const updateNotificationsData = (notificationsData: any[], notificationId: any) => {
  return updateNotificationList(notificationsData, notificationId, NotificationStatus.VISITED);
};

export const updateLocalStorage = (notificationsData: any[]) => {
  if (!notificationsData) {
    return;
  }
  const localList = loadVisitedNotificationIds();
  notificationsData.map((item: any, index: number) => {
    const foundItem = localList.filter((i: any) => i.notificationId === item.notificationId);
    if (foundItem && foundItem.length === 0) {
      localList.push(item);
      return foundItem;
    }
    foundItem[0].status = item.status;
    return item;
  });
  if (localList && localList.length > 0) {
    saveVisitedNotificationIds(localList);
  }
};

export const loadVisitedNotificationIds = () => {
  try {
    const serializedState = localStorage.getItem('notification_ids');
    if (serializedState === null) {
      return [];
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return [];
  }
};

export const saveVisitedNotificationIds = (ids: any) => {
  try {
    const serializedState = JSON.stringify(ids);
    localStorage.setItem('notification_ids', serializedState);
  } catch (error) {
    console.log(error);
  }
};