export const routeClient = '/client';

export const AppRoutes = {
  Index: '/',
  ClientRoute: routeClient,
  Home: routeClient + '/home',
  ManageRoles: routeClient + '/manage-roles',
  RealTimeExecution: routeClient + '/real-time-execution',
  ManfiestOverview: routeClient + '/manifest-overview',
  InboundFright: routeClient + '/inbound-freight',
  PostTruckAnalysis: routeClient + '/post-truck-analysis',
  FreightHome: routeClient + '/freight-home',
  OmniDashboard: routeClient + '/omni-dashboard',
  SmartDashbaord: routeClient + '/smart-dashboard'
};

export const omniModulesNavigation = [
  {
    label: 'Status',
    url: AppRoutes.OmniDashboard + '/status'
  },
  {
    label: 'Calendar',
    url: AppRoutes.OmniDashboard + '/calendar'
  },
  {
    label: 'Leaderboards',
    url: AppRoutes.OmniDashboard + '/leaderboards'
  }
];

export const smartModulesNavigation = [
  {
    label: 'Status',
    url: AppRoutes.SmartDashbaord + '/status'
  },
  {
    label: 'Leaderboards',
    url: AppRoutes.SmartDashbaord + '/leaderboards'
  }
];

export const omniDashboardsNavigation = [
  {
    label: 'Associate LeaderBoard',
    url: AppRoutes.OmniDashboard + '/leaderboards/associate'
  },
  {
    label: 'Store LeaderBoard',
    url: AppRoutes.OmniDashboard + '/leaderboards/store'
  }
];

export const smartDashboardsNavigation = [
  {
    label: 'Associate LeaderBoard',
    url: AppRoutes.SmartDashbaord + '/leaderboards/associate'
  },
  {
    label: 'Store LeaderBoard',
    url: AppRoutes.SmartDashbaord + '/leaderboards/store'
  }
];

export const omniRoute = {
  default: AppRoutes.OmniDashboard + '',
  leaderboards: AppRoutes.OmniDashboard + '/leaderboards',
  statusDashboard: AppRoutes.OmniDashboard + '/status',
  calendarDashboard: AppRoutes.OmniDashboard + '/calendar',
  associateLeaderboard: AppRoutes.OmniDashboard + '/leaderboards/associate',
  storeLeaderboard: AppRoutes.OmniDashboard + '/leaderboards/store'
};

export const smartRoute = {
  default: AppRoutes.SmartDashbaord + '',
  leaderboards: AppRoutes.SmartDashbaord + '/leaderboards',
  statusDashboard: AppRoutes.SmartDashbaord + '/status',
  associateLeaderboard: AppRoutes.SmartDashbaord + '/leaderboards/associate',
  storeLeaderboard: AppRoutes.SmartDashbaord + '/leaderboards/store'
};


export const AppBreadcrumbs = {
  ManageRoles: [{
    isHome: true,
    to: AppRoutes.Index
  }, {
    title: 'Manage Roles'
  }],
  RealTimeExecution: [{
    isHome: true,
    to: AppRoutes.FreightHome
  }, {
    title: 'Real Time Dashboard'
  }],
  ManfiestOverview: [{
    isHome: true,
    to: AppRoutes.FreightHome
  }, {
    title: 'Manifest Overview & Details'
  }],
  InboundFright: [{
    isHome: true,
    to: AppRoutes.FreightHome
  }, {
    title: 'Inbound Freight Execution'
  }],
  PostTruckAnalysis: [{
    isHome: true,
    to: AppRoutes.FreightHome
  }, {
    title: 'Post Truck Analysis'
  }],
  FreightHome: [{
    isHome: true,
    to: AppRoutes.Index
  }, {
    title: 'Freight'
  }],
  OmniDashboard: [{
    isHome: true,
    to: AppRoutes.Index
  }, {
    title: 'Omni'
  }],
  SmartDashboard: [{
    isHome: true,
    to: AppRoutes.Index
  }, {
    title: 'Replenishment'
  }]
};