import { http } from "./http";
import { ApiHeaders } from "../utility/ApiHeaders";
import { IExportParams } from "../components/types/shared/Common/ExportManager.types";
import { env } from "../envConfig";

const serialize = (obj: any) => {
  const str = [];
  for (const p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(p + "=" + obj[p]);
    }
  }
  return str.join("&");
};

export const omniDashboardApi = {
  getGeoData(requestParams: any) {
    const url = env.REACT_APP_STORE_SERVICE + "/v1/stores/geo/filters";
    return http
      .get(url, {}, false)
      .then(response => {
        return response.data;
      });
  },

  getLeaderboardMetaDataSchema(queryParam: any) {
    const url = env.REACT_APP_OMNI_PICKING_SERVICE + "/v1/schema";
    return http
      .get(url, { params: queryParam })
      .then(response => {
        return response.data;
      });
  },

  getAssociateLeaderboardData(requestParams: any) {
    const url = env.REACT_APP_OMNI_PICKING_SERVICE + "/v1/omni/picking/associate/leaderboard";

    return http
      .get(url, { params: requestParams })
      .then(response => {
        return response.data;
      });
  },

  getStoreLeaderboardData(requestParams: any) {
    const url = env.REACT_APP_OMNI_PICKING_SERVICE + "/v1/omni/picking/store/leaderboard";

    return http
      .get(url, { params: requestParams })
      .then(response => {
        return response.data;
      }).catch(() => {
        return { data: [] };
      });
  },

  getCompanyStoreLeaderboardData(requestParams: any) {
    const url = env.REACT_APP_OMNI_PICKING_SERVICE + "/v1/omni/picking/store/company/leaderboard";

    return http
      .get(url, { params: requestParams })
      .then((response) => {
        return response.data;
      });
  },

  getCompanyAssociateLeaderboardData(requestParams: any) {
    const url = env.REACT_APP_OMNI_PICKING_SERVICE + "/v1/omni/picking/associate/company/leaderboard";

    return http
      .get(url, { params: requestParams })
      .then(response => {
        return response.data;
      });
  },



  getOmniNotifications(queryParam: any) {
    const url = env.REACT_APP_OMNI_HEALTH_SERVICE + "/v1/omni/health/notifications";
    return http
      .get(url, {
        params: queryParam
      })
      .then(response => {
        return response.data;
      });
  },

  getOmniPositiveNotifications(params: any) {
    const url = env.REACT_APP_OMNI_HEALTH_SERVICE + "/v1/omni/notifications";
    return http
      .post(url, params)
      .then(response => {
        return response.data;
      });
  },

  getHealthStatusData(requestParams: any, isCompany?: boolean): Promise<any> {
    const url = env.REACT_APP_OMNI_HEALTH_SERVICE + "/v1/omni/health/summary" + (isCompany ? "/company" : "");
    return http
      .get(url, {
        params: requestParams,
        headers: ApiHeaders()
      })
      .then(response => {
        return response.data;
      });
  },

  executeExportToFile(exportParams: IExportParams): Promise<any> {
    const { url = env.REACT_APP_OMNI_CSV_SERVICE + "/v1/reports", queryParams, bodyParams } = exportParams;

    return http
      .post(url, serialize(bodyParams), {
        params: queryParams,
        headers: { ...ApiHeaders(), "content-type": "text/plain" }
      })
      .then(response => {
        return response.data;
      });
  },

  tryDownloadExportFile(filename: any): Promise<any> {
    const url = env.REACT_APP_OMNI_CSV_SERVICE + "/v1/reports/" + filename;
    return http
      .get(url, {
        headers: ApiHeaders()
      })
      .then(response => {
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const tempLink = document.createElement('a');
        tempLink.href = downloadUrl;
        tempLink.setAttribute('download', filename);
        tempLink.click();
        return response.data;
      });
  }
};
