import * as acctionTypes from './actionTypes';

interface IUserTools {
  id: string;
  name: string;
  icon: string;
  link: string;
  type: string | null;
}
interface IUserRole {
  id: string;
  role: string;
  tools: IUserTools[];
}
export interface IUserInfoData {
  name: string;
  email: string;
  storeId: string;
  userRole: IUserRole;
}
export interface ICommonReducerState {
  userDataLoading: boolean;
  userAuthorized: boolean;
  userInfoData: IUserInfoData;
  tools: any;
  roles: any;
  updatedTools: any;
}

const initialState: ICommonReducerState = {
  userDataLoading: true,
  userAuthorized: false,
  userInfoData: {} as IUserInfoData,
  tools: {},
  roles: {},
  updatedTools: []
};

export default function commonReducer(state: ICommonReducerState = initialState, action: any) {
  switch (action.type) {
    case acctionTypes.GET_MANAGE_ROLES_DATA:
      return { ...state, roles: action.payload };

    case acctionTypes.GET_MANAGE_TOOLS_DATA:
      return { ...state, tools: action.payload };

    case acctionTypes.GET_USER_INFO_DATA:
      return { ...state, userInfoData: {}, userAuthorized: false, userDataLoading: true };

    case acctionTypes.GET_USER_INFO_DATA_SUCCESS:
      return { ...state, userInfoData: action.payload, userAuthorized: true, userDataLoading: false };

    case acctionTypes.GET_USER_INFO_DATA_ERROR:
      return { ...state, userInfoData: {}, userAuthorized: false, userDataLoading: false };

    case acctionTypes.PUT_TOOLS_DATA:
      return { ...state, updatedTools: action.payload, userInfoData: { ...state.userInfoData, userRole: action.payload } };

    default:
      return state;
  }
}