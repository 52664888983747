import * as React from "react";
import { Col } from 'react-bootstrap';
import UserInfo from "./UserInfo";
import { Breadcrumbs } from "./Breadcrumbs";
import { IHeaderProps } from "../types/layout/Header.types";

export default class Header extends React.PureComponent<IHeaderProps, {}> {
  render() {
    const { homeIcon, accountIcon, breadcrumbs, uName, uRole, styleClassName } = this.props;
    return (
      <div className={styleClassName}>
        {
          breadcrumbs && breadcrumbs.length > 0 ? (
            <Col lg={9} md={8} sm={6} xs={7} className="breadcrumb-wrapper">
              <Breadcrumbs homeIcon={homeIcon} breadcrumbs={breadcrumbs} />
            </Col>
          ) : ""
        }
        <Col lg={breadcrumbs ? 3 : 6} md={breadcrumbs ? 4 : 6} sm={6} xs={5} className="userInfo">
          <UserInfo userName={uName} userRole={uRole} accountIcon={accountIcon} />
        </Col>
      </div>
    );
  }
}