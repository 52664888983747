import * as actionTypes from '../actionTypes';

const Init = {
  realTimeDataLoading: false,
  realTimeData: {},
  hasError: null
};

export default function realTimeStore(state = Init, action: any) {
  switch (action.type) {
    case actionTypes.GET_REALTIME_RECEIVING_DATA:
      return { ...state, realTimeDataLoading: false, hasError: null };
    case actionTypes.GET_REALTIME_RECEIVING_NETWORK_ERROR:
      return { ...state, realTimeDataLoading: false, hasError: null };
    case actionTypes.GET_REALTIME_RECEIVING_DATA_SUCCESS:
      return { ...state, realTimeDataLoading: false, realTimeData: action.payload, hasError: null };
    case actionTypes.HAS_ERROR:
      return { ...state, realTimeDataLoading: false, realTimeData: {}, hasError: action.payload };
    case actionTypes.CLEAR_REAL_TIME_STATE:
      return { ...state, realTimeData: {}, hasError: null };
    default:
      return state;
  }
}