import * as jsPDF from 'jspdf';
// import * as moment from 'moment';
import html2canvas from 'html2canvas';
import { getFormattedTimeStamp } from './Common';
import {
  PDF_MAX_ROWS, HYPEN
} from '../constants/smartConstants';
import { CustomRoles } from '../constants/smartConstants';
import LeaderboardTable from '../components/shared/DashboardItems/LeaderBoards/LeaderboardTable';
import { MODULE_TYPE, PDF_DEFAULT } from '../constants/commonConstants';

// export const addDelimiter = (type: string, repeater?: number) => {
//   let result = '';
//   let counter = 0;

//   if (repeater) {
//     while (counter < repeater) {
//       result += type;
//       counter++;
//     }
//   } else {
//     result += type;
//   }
//   return result;
// };

// export const json2csv = (data: any, schema: any) => {
//   let result = '';
//   const keys = Object.keys(data[0]);
//   const namesArray: any[] = [];
//   let counter: number;

//   if (data === null || !data.length) {
//     return null;
//   }

//   schema.forEach((column: any) => {
//     column.children.forEach((child: any) => {
//       const name = child.name.includes('<br/>') ? child.name.replace(/<br\s*\/?>/gi, '') : child.name;
//       namesArray.push(name);
//     });
//   });

//   result += namesArray.join(addDelimiter(COLUMN));
//   result += addDelimiter(LINE);

//   data.forEach((row: any) => {
//     counter = 0;
//     schema.forEach((column: any) => {
//       column.children.forEach((child: any) => {
//         if (keys.includes(child.key)) {
//           result += row[child.key];
//         } else if (child.pattern) {
//           let customPattern = child.pattern;
//           customPattern = customPattern.replace(/\${/g, '${row.');
//           customPattern = customPattern.replace(/}/g, '}');
//           customPattern = customPattern.replace(/\//g, ' | ');

//           const patternResult = new Function('row', 'return `' + customPattern + '`;')(row);
//           result += `"${patternResult.replace(/null/g, '-')}"`;
//         }
//         counter++;
//         if (counter < namesArray.length) {
//           result += addDelimiter(COLUMN);
//         }
//       });
//     });
//     result += addDelimiter(LINE);
//   });
//   return result;
// };

// export const exportLeaderboardToCsv = (dataObj: any, storeMetaSchema: any, reportTitle: string) => {
//   const csv = json2csv(dataObj, storeMetaSchema) || '';
//   const downloadLink = document.createElement("a");
//   const blob = new Blob(["\ufeff", csv]);
//   const url = URL.createObjectURL(blob);
//   downloadLink.href = url;
//   downloadLink.download = reportTitle + ".csv";

//   document.body.appendChild(downloadLink);
//   downloadLink.click();
//   document.body.removeChild(downloadLink);
// };

// export const exportLeaderboardDataCsv = (props: any, leaderboard: string) => {
//   const { metaData, rowsData } = props;

//   if (leaderboard === 'associate') {
//     exportLeaderboardToCsv(rowsData, metaData.associateMetaSchema.columns, `AssociateLeaderboard_${moment().format()}`);
//   } else if (leaderboard === 'store') {
//     exportLeaderboardToCsv(rowsData, metaData.storeMetaSchema.columns, `StoreLeaderboard_${moment().format()}`);
//   }

//   return Promise.resolve();
// };

// export const exportStatusToCsv = (data: any, reportTitle: string) => {
//   let csv = '';

//   Object.keys(data).forEach(item => {
//     csv += data[item] ? exporStatusSectionToCsv(data[item], item) || '' : '';
//   });

//   const downloadLink = document.createElement('a');
//   const blob = new Blob(['\ufeff', csv]);
//   const url = URL.createObjectURL(blob);

//   downloadLink.href = url;
//   downloadLink.download = `${reportTitle}.csv`;

//   document.body.appendChild(downloadLink);
//   downloadLink.click();
//   document.body.removeChild(downloadLink);
// };

// const exporStatusSectionToCsv = (data: any, sectionName: string) => {
//   let result = HealthStatusCsvHeaders[sectionName];
//   let keys = Object.keys(data);

//   if (sectionName === 'dailySummaryData') {
//     result += setSectionColumnLabels(COLUMNS_HEADERS.DAILY_SUMMARY);

//     // Iterate by days
//     keys.forEach(day => {
//       const dayData = data[day];
//       keys = Object.keys(dayData);

//       result += day;
//       result += addDelimiter(LINE);
//       result += addRowsWithData(keys, dayData); // Add KPI row with values
//       result += addDelimiter(LINE, 2);
//     });
//     result += addDelimiter(LINE, 2);
//   } else {
//     result += setSectionColumnLabels(COLUMNS_HEADERS.DEFAULT);
//     result += addRowsWithData(keys, data); // Add KPI row with values
//     result += addDelimiter(LINE, 2);
//   }
//   return result;
// };

// const mapValuesToKeys = (data: any, keys: string[]) => {
//   let result = '';

//   keys.forEach(key => {
//     if (typeof data[key] !== 'string') {
//       result += data[key];
//     }
//     result += addDelimiter(COLUMN);
//   });

//   return result;
// };

// const setSectionColumnLabels = (headers: string[]) => {
//   let result = addDelimiter(COLUMN);
//   result += headers.join(addDelimiter(COLUMN));
//   result += addDelimiter(LINE);

//   return result;
// };

// const addRowsWithData = (keys: string[], data: any) => {
//   let result = '';
//   const labels = HealthStatusDashboardConstants.FieldsConfig.Replenishment;

//   keys.forEach(key => {
//     const singleKpi = data[key];
//     const singleKpiKeys = Object.keys(singleKpi ? singleKpi : []).filter(kpiKey => kpiKey !== 'details');
//     const label = labels[key] ? labels[key].label : null;

//     if (label && !label.includes('${goal}') && label !== 'details') {
//       result += label;
//       result += addDelimiter(COLUMN);
//       result += mapValuesToKeys(singleKpi, singleKpiKeys);
//       result += addDelimiter(LINE);
//     }

//   });

//   return result;
// };

interface IOptions {
  title: string;
  range: string | null;
  refresh: string;
}

export const exportStatusToPdf = (options: IOptions) => {
  const healthWrapper = document.querySelector('.health-status') as HTMLElement;
  const healthContent = document.querySelector('#health-status-content') as HTMLElement;
  const healthCopyWrapper = document.createElement('div');
  const healthCopy = healthContent.cloneNode(true) as HTMLElement;

  healthCopy.style.width = '1400px';
  healthCopyWrapper.appendChild(healthCopy);
  healthWrapper.appendChild(healthCopyWrapper);

  const list = healthCopy.children;
  const topLeftMargin = 15;
  const pdfWidth = (healthCopy as HTMLDivElement)!.offsetWidth + (topLeftMargin * 2);
  const pdfHeight = (pdfWidth * 1.5) + (topLeftMargin * 2);

  let maxHeight = 0;

  for (let i = 0; i < list.length; i++) {
    const element = (list[i] as HTMLElement);
    maxHeight += element.clientHeight;

    if (pdfHeight < maxHeight) {
      const lastSectionHeight = element.clientHeight;
      maxHeight -= lastSectionHeight;
      const bottomPadding = pdfHeight - maxHeight;
      if (i !== list.length - 1) {
        element.style.paddingBottom = `${bottomPadding - (bottomPadding * 0.3)}px`;
      }
      break;
    }
  }

  // Modify copy styles for better quality in PDF files
  const wrapperHeader = healthCopy.querySelector('.wrapper-header') as HTMLElement;
  if (wrapperHeader) {
    wrapperHeader.classList.add('print');
  }
  const accordianTitle = healthCopy.querySelector('.accordion-title') as HTMLElement;
  if (accordianTitle) {
    accordianTitle.classList.add('print');
  }
  if (healthCopy) {
    healthCopy.classList.add('print');
  }

  // Create invisible wrapper for modified copy
  healthCopyWrapper.style.height = '0';
  healthCopyWrapper.style.overflow = 'hidden';

  const config = {
    range: options.range,
    refresh: options.refresh,
    type: MODULE_TYPE.STATUS,
    quality: 0.8,
    contentPosition: {
      x: 5,
      y: 55
    },
    topLeftMargin: 5,
    topMargin: 55
  };

  createPdf(healthCopy as HTMLDivElement, `${options.title}`, config);
};

export const exportLeaderboardToPdf = (metaSchema: any, rowsData: any, filename: string) => {
  const storeLeaderboard = document.querySelector('.leaderboard');
  const tableWrapper = document.createElement('div');
  const tableContainer = document.createElement('table');
  const tableHeader = document.createElement('thead');
  const tableBody = document.createElement('tbody');
  const tableHeaderRow = document.createElement('tr');
  const tableSubHeaderRow = document.createElement('tr');
  const { columns } = metaSchema;
  const schema: object[] = [];

  if (storeLeaderboard && storeLeaderboard.classList.contains("associate-leaderboard")) {
    tableContainer.classList.add('associate-leaderboard');
  }
  if (storeLeaderboard && storeLeaderboard.classList.contains("store-leaderboard")) {
    tableContainer.classList.add('store-leaderboard');
  }
  tableWrapper.classList.add('print-table-wrapper');

  columns.forEach((column: any) => {
    const tableHeaderCell = document.createElement('th');
    if (column.name) {
      tableHeaderCell.classList.add('primary-header-cell');
    }
    tableHeaderCell.textContent = column.name;
    tableHeaderCell.colSpan = column.children.length;
    tableHeaderRow.appendChild(tableHeaderCell);

    column.children.forEach((el: any) => {
      const tableSubHeaderCell = document.createElement('th');
      if (el.highlighted) {
        tableSubHeaderCell.classList.add('highlighted');
      }
      tableSubHeaderCell.textContent = el.name.replace(/\<br\/>/g, '');
      schema.push({ key: el.key, pattern: el.pattern, format: el.format });
      tableSubHeaderRow.appendChild(tableSubHeaderCell);

    });
  });

  tableHeader.appendChild(tableHeaderRow);
  tableHeader.appendChild(tableSubHeaderRow);
  tableContainer.appendChild(tableHeader);

  for (let i = 0; i < PDF_MAX_ROWS; i++) {
    const row: any = rowsData[i];
    const dataRow = document.createElement('tr');

    if (row && row.rowsType) {
      dataRow.classList.add(LeaderboardTable.getBackgroundColour(row.rowsType));
    } else if (row && row.type) {
      dataRow.classList.add(LeaderboardTable.getBackgroundColour(row.type));
    }

    schema.forEach((cellType: any) => {
      const dataCell = document.createElement('td');
      let customPattern;
      const defaultValue = HYPEN;
      // const nullRegex = /^null$/;
      const nullRegex = /null/g;
      const nullWithBracketsRegex = /.*\(null\)/g;
      if (row) {
        const formattedRow = objectMap(row, convertTodecimaltwodigits);
        if (cellType.pattern) {
          customPattern = cellType.pattern;
          customPattern = customPattern.replace(/\${/g, '${formattedRow.');
          customPattern = customPattern.replace(/}/g, '}');
          let value = new Function('formattedRow', 'return `' + customPattern + '`;')(formattedRow);
          value = value.replace(nullRegex, defaultValue);
          value = value.replace(nullWithBracketsRegex, `(${defaultValue})`);
          dataCell.textContent = value;
        } else if (cellType.format === "STAR") {
          dataCell.innerHTML = formattedRow[cellType.key] || formattedRow[cellType.key] === 0 ? generateStarContent(formattedRow[cellType.key]) : HYPEN;
        } else if (cellType.format === "PERCENT") {
          dataCell.innerHTML = formattedRow[cellType.key] || formattedRow[cellType.key] === 0 ? formattedRow[cellType.key] + "%" : HYPEN;
        } else {
          dataCell.textContent = formattedRow[cellType.key] || formattedRow[cellType.key] === 0 ? (formattedRow[cellType.key]) : HYPEN;
        }
      }

      dataRow.appendChild(dataCell);
      dataRow.appendChild(dataCell);
    });

    if (row && Object.keys(row).length > 0) {
      tableBody.appendChild(dataRow);
    }
  }

  tableContainer.appendChild(tableBody);
  tableWrapper.appendChild(tableContainer);
  storeLeaderboard!.appendChild(tableWrapper);

  (tableWrapper as HTMLElement).style.height = '0';
  (tableWrapper as HTMLElement).style.overflow = 'hidden';

  const config = {
    type: MODULE_TYPE.LEADERBOARD,
    topMargin: 5,
    topLeftMargin: 15
  };

  return createPdf(tableContainer, filename, config);
};

const createPdf = (wrapper: HTMLDivElement, fileName: string, config?: any) => {
  const htmlWidth = wrapper!.offsetWidth;
  const htmlHeight = wrapper!.offsetHeight;
  const topLeftMargin = config ? config!.topLeftMargin : PDF_DEFAULT.LEFT_MARGIN;
  const topMargin = config ? config!.topMargin : PDF_DEFAULT.TOP_MARGIN;
  const pdfWidth = wrapper!.offsetWidth + (topLeftMargin * 2);
  const pdfHeight = (pdfWidth * 1.5) + (topLeftMargin * 2);
  const totalPDFPages = Math.ceil(htmlHeight / pdfHeight) - 1;

  return html2canvas(wrapper, { allowTaint: false }).then((canvas: any) => {
    return new Promise((resolve) => {
      canvas.getContext('2d');
      const imgData = canvas.toDataURL('image/jpeg', 0.8);
      const pdf = new jsPDF('p', 'pt', [pdfWidth, pdfHeight]);

      if (config && config.type === MODULE_TYPE.STATUS) {
        const { range, refresh } = config;

        pdf.setFontSize(18);
        pdf.setTextColor(80, 80, 80);
        pdf.text(25, 35, `Date Range: ${range}`);
        pdf.text(1100, 35, `Data as of: ${getFormattedTimeStamp(refresh)}`);
        pdf.addImage(imgData, 'JPG', topLeftMargin, topMargin, htmlWidth, htmlHeight, '', 'FAST');
      } else if (config.type === MODULE_TYPE.LEADERBOARD) {
        pdf.addImage(imgData, 'JPG', topLeftMargin, topMargin, htmlWidth, htmlHeight, '', 'FAST');
      }

      for (let i = 1; i <= totalPDFPages; i++) {
        pdf.addPage([pdfWidth, pdfHeight]);
        pdf.addImage(imgData, 'JPG', topLeftMargin, -(pdfHeight * i), htmlWidth, htmlHeight, '', 'FAST');
      }

      pdf.save(`${fileName}.pdf`);
      resolve(true);
    });
  }).catch(err => { console.log(err); });
};

export const parseGoal = (goalConfig: any, goalValue: any) => {
  const { label } = goalConfig || { label: null };
  const { currentValue } = goalValue || { currentValue: null };

  if (label && goalValue && goalConfig) {
    return goalConfig.label.replace('${goal}', `${currentValue}`);
  } else {
    return goalConfig ? goalConfig.label : null;
  }
};

export const detectRole = (role: string) => {
  const roles = CustomRoles.map((el: string) => el.toLocaleLowerCase());
  return roles.includes(role.toLocaleLowerCase());
};
export const convertTodecimaltwodigits = (tableData: any) => {
  if (typeof tableData === 'number' && tableData % 1 !== 0) {
    tableData = tableData.toFixed(2);
  }
  return tableData;
};
const generateStarContent = (data: any) => {
  const rValue = Math.floor(data);
  const rImage = (rValue === 5) ? 'star5' : 'star-non5';
  return `<span class="rating rating-${rValue}">${data}<span class="star ${rImage}"></span></span>`;
};
const objectMap = (object: any, mapFn: any) => {
  return Object.keys(object).reduce((result, key) => {
    result[key] = mapFn(object[key]);
    return result;
  }, {});
};
