import { Dispatch } from 'react-redux';
import * as ActionTypes from '../actionTypes';
import { omniDashboardApi } from '../../api/omniDashboardApi';
import { flattenGeoData, sortGeoData } from '../../utility/Common';

export const updateGeoFilterData = (filterData: any) => ({
  type: ActionTypes.UPDATE_GEO_FILTER_DATA,
  payload: sortGeoData(filterData)
});

export const updateSelectedDateType = (selectedDayType: any, dateRange: any) => ({
  type: ActionTypes.UPDATE_SELECTED_DATE_TYPE,
  payload: {
    selectedDayType,
    dateRange
  }
});

export const updateCalendarStartEndDate = (selectedDayType: any, startDate: any, endDate: any) => ({
  type: ActionTypes.UPDATE_START_END_DATE,
  payload: {
    selectedDayType,
    startDate,
    endDate
  }
});

export const showLoaderOnExport = () => ({
  type: ActionTypes.SHOW_EXPORT_LOADER
});

export const hideLoaderFromExport = () => ({
  type: ActionTypes.HIDE_EXPORT_LOADER
});

export function getGeoData(requestData: any): (dispatch: Dispatch<any>) => Promise<void> {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: ActionTypes.GET_GEO_DATA });
    return omniDashboardApi.getGeoData(requestData)
      .then((geoData: any) => {
        dispatch({ type: ActionTypes.GET_GEO_DATA_SUCCESS, payload: flattenGeoData(geoData) });
      }).catch(() => {
        dispatch({ type: ActionTypes.GET_GEO_DATA_FAILED, payload: null });
      });
  };
}