import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { ValueFormat, AgingVariant, IAgingConfig } from '../../../../constants/omniConstants';
import { PPDModal, ModalSize, PPDTooltip } from 'cx-ppd-client-components';
import { cs, hasValue, numberFormatter } from '../../../../utility/Common';
import "../../../../assets/styles/omni-status.scss";
import DetailsDropdown from './DetailsDropdown';
import { IVariationProps, IMetricValueAdvanceProps, IViewDetailsLink, IShowLastYearValue, ITripRuleProps, IMetricValueProps, IAgingValue, IAgingProps, IAgingItemProps, IPlanProjectedVarianceProps, IViewDetailsModalProps } from '../../../types/OmniDashboard/HealthStatus/Common/ICommonIndex.types';
import { VIEW_BY, IMetricValue } from '../../../../store/reducers/types/omniHealthStatus.types';

export const formatValue = (value: number | undefined | null, format?: ValueFormat, hideLeadZero?: boolean) => {
  if (typeof value === "undefined" || value === null) {
    return '-';
  }
  const displayValue = hideLeadZero ? numberFormatter(value).toString().replace(/^0+/, '') : numberFormatter(value);

  switch (format) {
    case ValueFormat.DOLLARS:
      return `$${displayValue}`;
    case ValueFormat.PERCENT:
      return `${displayValue}%`;
    case ValueFormat.DAYS:
      return `${displayValue} DAYS`;
    case ValueFormat.HOURS:
      return `${displayValue}hrs`;
    case ValueFormat.UNITS:
      return `${displayValue} Unit${value > 1 ? 's' : ''}`;
    default:
      return displayValue;
  }
};

export const InfoModal = ({ title = "Metric Definition", icon, content }: any) => {
  if (!content || content === "" || content === "null") {
    return null;
  }

  let isHtml = false;
  if (typeof content === "string" && content.includes('\n')) {
    isHtml = true;
    content = content.replace(/\n/gi, "<br/>");
  }

  return (
    <PPDModal
      title={title}
      trigger={(
        <sup className={cs("modal-trigger", icon)} />
      )}
    >
      {isHtml ? <div dangerouslySetInnerHTML={{ __html: content }} /> : content}
    </PPDModal>
  );
};

export interface IValueProps {
  value?: number | null;
  valueFormat?: ValueFormat;
  tip?: string | React.ReactElement;
  metricInfo?: string | React.ReactElement;
  hideLeadZero?: boolean;
}

export const Value = ({ value, valueFormat, tip, metricInfo, hideLeadZero }: IValueProps) => {
  return (
    <div className={cs("value", { "attention": tip ? true : false })}>
      <span>{formatValue(value, valueFormat, hideLeadZero)}</span>
      {
        tip ? (
          <InfoModal
            title="Protip"
            content={tip}
            icon={"pro-tip"}
          />
        ) : null
      }
    </div>
  );
};

interface ILastYearValueProps {
  value?: number | null;
  format?: ValueFormat;
  children?: any;
}

export const LastYearValue = ({ value, format }: ILastYearValueProps) => {
  let result;
  if (!hasValue(value)) {
    return <span>No results found</span>;
  } else {
    result = value;
  }

  if (value && format) {
    result = formatValue(value, format);
  }

  return <span>{result}</span>;
};

export const Variation = ({ value, lastYearValue }: IVariationProps) => {
  if (!hasValue(lastYearValue) || !value) {
    return null;
  }
  const variation = Math.trunc(((value - lastYearValue!) / lastYearValue!) * 100);
  return (
    <div className="variation">{variation >= 0 ? `+${variation}` : `${variation}`}% to LY</div>
  );
};

export const MetricValueAdvance = (props: IMetricValueAdvanceProps) => {
  const { dataKey, data, viewBy, viewLastYear, lastYearData, fieldsConfig, horizontal, children } = props;

  const unitsViewConfig = fieldsConfig.UnitsView[dataKey] || {};
  const amountViewConfig = fieldsConfig.AmountView[dataKey] || {};
  const fieldConfig = viewBy === VIEW_BY.AMOUNT ? amountViewConfig : unitsViewConfig;

  if (!fieldConfig) {
    return null;
  }

  const { showLastYear } = fieldConfig;

  /**
   * Could be an object from api
   */
  const fieldValue: number | IMetricValue | null = dataKey ? data[dataKey] : null;
  if (typeof fieldValue === "undefined") {
    return null;
  }

  if (fieldConfig.hideOnNull && typeof fieldValue === "object" && (fieldValue === null || fieldValue.value === null)) {
    return null;
  }

  const lastYearValue = lastYearData ? lastYearData[dataKey] : null;

  const MetricValueRenderer = horizontal ? MetricValueHorizontal : MetricValue;

  return (
    <MetricValueRenderer
      {...fieldConfig}
      {...(typeof fieldValue === "object" && fieldValue !== null ? fieldValue : { value: fieldValue })}
      showLastYear={showLastYear && viewLastYear}
      lastYearValue={typeof lastYearValue === "object" && lastYearValue !== null ? lastYearValue.value : lastYearValue}
      children={children}
      dataKey={dataKey}
      viewBy={viewBy}
    />
  );
};

export const ViewDetailsLink = ({ hasViewDetails, details, title, valueFormat }: IViewDetailsLink) => {
  return (
    hasViewDetails && details && details.length > 0 ?
      <div className="view-details">
        <PPDModal
          size={ModalSize.lg}
          title={title}
          trigger={<a>View Details</a>}>
          <ViewDetailsModal
            list={details}
            valueFormat={valueFormat}
          />
        </PPDModal>
      </div> : null
  );
};

export const ShowLastYearValue = ({ showLastYear, lastYearValue, valueFormat, isHorizontal }: IShowLastYearValue) => {
  let result;
  if (isHorizontal) {
    result = <Col md={6}><div className="label">LY</div><div className="last-year-value"><LastYearValue value={lastYearValue} format={valueFormat} /></div></Col>;
  } else {
    result = <div className="last-year-value"><LastYearValue value={lastYearValue} format={valueFormat} /> LY</div>;
  }
  return showLastYear && result ? result : null;
};

export const TipRule = ({ tipRule, brackets }: ITripRuleProps) => {
  return tipRule ?
    <div className="goal">{brackets ? `(${tipRule})` : tipRule}</div> : null;
};


export const MetricValue = (props: IMetricValueProps) => {
  const { label, value, tipRule, lastYearValue, showVariation, showLastYear, tip, metricInfo, valueFormat, hasDropdown, hasViewDetails, viewDetailsModalTitle = "", details, dataKey, viewBy } = props;

  if ((!hasDropdown && typeof props.value === "undefined") || (hasDropdown && (!details || details.length === 0))) {
    return null;
  }

  const maxValue = hasDropdown && details ? details.reduce((prev, current) => (prev.rate > current.rate) ? prev : current) : null;
  const [selectedOption, setSelectedOption] = React.useState(maxValue);

  return (
    <PPDTooltip tooltip={metricInfo && typeof metricInfo === 'string' && !tip ? metricInfo : null} key={dataKey}>
      <div className="metric-value">
        <Value
          value={hasDropdown ? selectedOption!.rate : value}
          valueFormat={valueFormat}
          tip={tip}
          metricInfo={metricInfo}
        />
        <div className="label">{label}</div>
        <TipRule tipRule={tipRule} />
        <ShowLastYearValue
          showLastYear={showLastYear}
          lastYearValue={lastYearValue}
          valueFormat={valueFormat}
        />
        {
          showLastYear && showVariation && lastYearValue ? (
            <Variation value={value} lastYearValue={lastYearValue} />
          ) : null
        }
        <DetailsDropdown
          value={selectedOption}
          setValue={setSelectedOption}
          hasDropdown={hasDropdown}
          details={details}
        />
        <ViewDetailsLink
          hasViewDetails={hasViewDetails}
          details={details}
          title={viewDetailsModalTitle}
          valueFormat={viewBy === VIEW_BY.AMOUNT ? ValueFormat.DOLLARS : ValueFormat.UNITS}
        />
      </div>
    </PPDTooltip>
  );
};

export const ViewDetailsModal = ({ list, valueFormat = ValueFormat.UNITS }: IViewDetailsModalProps) => {
  const listItems = Array.isArray(list) ? list.map((item: any) =>
    <div key={item.label}>
      <Row>
        <Col md={8}>
          <div>{item.label}</div>
        </Col>
        {
          typeof item.rate !== "undefined" ? (
            <Col md={2}>
              <span>{formatValue(item.rate)}%</span>
            </Col>
          ) : null
        }
        {
          typeof item.value !== "undefined" ? (
            <Col md={2}>
              <span>{formatValue(item.value, valueFormat)}</span>
            </Col>
          ) : null
        }
      </Row>
      <hr /></div>
  ) : list;
  return (
    <div>
      <div className="view-list-modal-items">{listItems}</div>
    </div>
  );
};

export const MetricValueHorizontal = ({ label, value, valueFormat, lastYearValue, showVariation, showLastYear, tip, metricInfo, children }: IMetricValueProps) => {
  return (
    <div className="metric-value vertical">
      <Row>
        <Col xs="6">
          <div className="label">{label}</div>
          <Value
            value={value}
            valueFormat={valueFormat}
            tip={tip}
            metricInfo={metricInfo}
          />
          {
            showLastYear && showVariation && lastYearValue ? (
              <Variation value={value} lastYearValue={lastYearValue} />
            ) : null
          }
          {
            children
          }
        </Col>
        <ShowLastYearValue
          showLastYear={showLastYear}
          lastYearValue={lastYearValue}
          valueFormat={valueFormat}
          isHorizontal={true}
        />
      </Row>
    </div>
  );
};

const validAgingValue = (fieldConfig: IAgingConfig, value: IAgingValue) => {
  let agingHasValue = false;
  fieldConfig.children.forEach(({ key }) => {
    if (agingHasValue === false) {
      agingHasValue = hasValue(value[key]);
    }
  });

  return agingHasValue;
};

export const Aging = ({ value, viewBy, fieldsConfig }: IAgingProps) => {
  const fieldConfig: IAgingConfig | undefined = viewBy === VIEW_BY.AMOUNT ? fieldsConfig.AmountView.aging : fieldsConfig.UnitsView.aging;
  if (!fieldConfig || !value) {
    return null;
  }

  const { tip } = value;
  if (!validAgingValue(fieldConfig, value)) {
    return null;
  }

  const { children, valueFormat } = fieldConfig;

  return (
    <div className="aging">
      <div className={cs("aging-title", { "attention": tip ? true : false })}>
        <span>{fieldConfig.label}</span>
        <InfoModal title="Protip" content={tip} icon="pro-tip" />
      </div>
      <div className="aging-body">
        {
          children.map(({ key, label, variant }) => (
            <AgingItem
              key={key}
              label={label}
              value={value[key]}
              variant={variant}
              valueFormat={valueFormat}
            />
          ))
        }
      </div>
    </div>
  );
};


const AgingItem = ({ label, value, variant = AgingVariant.None, valueFormat }: IAgingItemProps) => {
  if (!hasValue(value)) {
    return null;
  }
  return (
    <div className={cs('aging-item', variant)}>
      <div className="value">{formatValue(value, valueFormat)}</div>
      <div className="label">{label}</div>
    </div>
  );
};

export const PlanProjectedVariance = ({ value }: IPlanProjectedVarianceProps) => {
  if (!hasValue(value)) {
    return null;
  }

  return (
    <div className="variation">
      {value === 0 ? "No variation from Plan" :
        (value ? Math.abs(value) : value) + '% ' + (value! > 0 ? "Above" : "Below") + ' Plan'}
    </div>
  );
};