import * as React from 'react';
import { Nav, NavItem } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { ILeaderBoardNavProps } from '../types/shared/LeaderBoardNav.types';

export const LeaderBoardNav = ({ navigation }: ILeaderBoardNavProps) => (
  <Nav>
    {
      navigation.map((dashboard, index) => (
        <NavItem key={index}>
          <NavLink className="nav-link" to={dashboard.url}>{dashboard.label}</NavLink>
        </NavItem>
      ))
    }
  </Nav>
);

export default LeaderBoardNav;