import * as React from 'react';
import * as moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DayPickerRangeController } from 'react-dates';
import { DateSelectionTypes } from '../../constants/DateRangePickerConstant';
import { DateFormats } from '../../utility/Date';

class CalendarPicker extends React.PureComponent<any, any>{
  constructor(props: any) {
    super(props);
    let calendarStartDate;
    let calendarEndDate;
    const { startDate, endDate, selectedDateType } = props;
    if (selectedDateType === DateSelectionTypes.CUSTOM_DATE_RANGE) {
      calendarStartDate = startDate;
      calendarEndDate = endDate;
    }
    this.state = {
      isStartDateSelected: false,
      isEndDateSelected: false,
      focusedInput: null,
      calendarStartDate,
      calendarEndDate
    };
  }

  onFocusChange = ({ focusedInput }: any) => {
    this.setState({ focusedInput: focusedInput || 'startDate' });
  }

  onDatesChange = ({ startDate, endDate }: any) => {
    const isStartDateSelected = startDate ? true : false;
    const isEndDateSelected = this.state.isStartDateSelected && endDate ? true : false;
    this.setState({
      calendarStartDate: startDate,
      calendarEndDate: isStartDateSelected ? null : endDate,
      isStartDateSelected,
      isEndDateSelected
    });
    if (isStartDateSelected && isEndDateSelected && startDate && endDate) {
      const updatedStartDate = moment(startDate).format(DateFormats.YYYYMMDD);
      const updatedEndDate = moment(endDate).format(DateFormats.YYYYMMDD);
      this.props.updateCalendarStartEndDate(updatedStartDate, updatedEndDate);
      this.props.onBackButtonClick();
    }
  }

  render() {
    const { calendarStartDate, calendarEndDate, isStartDateSelected } = this.state;
    const { onBackButtonClick, numberOfMonths } = this.props;

    const momentStartDate = calendarStartDate && moment(calendarStartDate);
    const momentEndDate = calendarEndDate && moment(calendarEndDate);

    const startDateString = momentStartDate ? momentStartDate.format('MM-DD-YYYY') : '';
    const endDateString = momentEndDate ? momentEndDate.format('MM-DD-YYYY') : '';

    return (
      <>
        <div className='date-picker-wrapper'>
          <div className='date-picker-header'>
            <span className='float-left back-button' onClick={onBackButtonClick}>
              <img className='arrow' src={require('../../assets/images/left-chevron.png')} />
              <span>Back</span>
            </span>
            <span className='float-right'>
              <input type="text" name="start date" className='input input-image' value={startDateString} readOnly={true} placeholder='Start Date' />
              <input type="text" name="end date" className='input input-image' value={endDateString} readOnly={true} placeholder='End Date' />
            </span>
          </div>

          <DayPickerRangeController
            startDate={momentStartDate}
            endDate={momentEndDate}
            onDatesChange={this.onDatesChange}
            onFocusChange={this.onFocusChange}
            focusedInput={isStartDateSelected ? 'endDate' : 'startDate'}
            noBorder={true}
            keepOpenOnDateSelect={true}
            numberOfMonths={numberOfMonths}
            withPortal={true}
            minimumNights={0}
          />
        </div>
      </>
    );
  }
}

export default CalendarPicker;