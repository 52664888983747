import * as React from "react";
import Navigation from "./Navigation";

export default class App extends React.Component<any, any> {
  render() {
    return (
      <div className="rootContainer">
        <Navigation />
      </div>
    );
  }
}