import * as React from 'react';
import { Image } from 'react-bootstrap';
import { IUserInfoProps } from '../types/layout/UserInfo.types';

export class UserInfo extends React.Component<IUserInfoProps, {}> {
  render() {
    const { accountIcon, userName, userRole } = this.props;
    return (
      <div id="userInfoSection">
        <Image src={accountIcon ? accountIcon : require(`../../assets/images/ic-account-white.svg`)} />
        <div id="userData">
          {userName}
          <br />
          <span>{userRole}</span>
        </div>
      </div>
    );
  }
}

export default UserInfo;