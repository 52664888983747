import * as React from 'react';
import { Row, Col, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import LocationFilter from '../../../components/shared/Common/LocationFilter';
import { isMobile } from 'react-device-detect';
import DateRangePicker from '../../../components/shared/DateRangePicker';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as HealthStatusActionCreators from '../../../store/actions/healthStatusSmart';
import { startEndDate } from '../../../store/actions/smartActions';
import { DateSelectionTypes, FiscalDatesTypes } from '../../../constants/DateRangePickerConstant';
import FilterModal from '../../../components/shared/FilterModal';
import { IFiltersStateToProps, IFiltersDispatchToProps, ISubFiltersProps, IConfig } from '../../types/SmartDashboard/HealthStatus/Filters.types';
import ExportDropdown from '../../../components/shared/DashboardItems/ExportDropdown';

type IFilterProps = IFiltersStateToProps & IFiltersDispatchToProps & ISubFiltersProps;

export class Filters extends React.Component<IFilterProps, any> {

  updateDateSelectionTypes = (selectedDateType: any, datePayLoad?: any) => {
    const { isFiscalDateFilter, updateFiscalDateFilter, updateSelectedDates } = this.props;

    const { startDate, endDate } = (selectedDateType !== DateSelectionTypes.CUSTOM_DATE_RANGE) ? startEndDate(selectedDateType) : datePayLoad;
    const config: IConfig = {
      selectedDateType,
      startDate,
      endDate
    };

    if (isFiscalDateFilter) {
      config.fiscalDateFilter = FiscalDatesTypes[selectedDateType];
      updateFiscalDateFilter(config.fiscalDateFilter);
    }
    updateSelectedDates(config);
  }

  updateCalendarStartEndDate = (startDate: any, endDate: any) => {
    this.updateDateSelectionTypes(DateSelectionTypes.CUSTOM_DATE_RANGE, { startDate, endDate });
  }

  render() {
    const {
      selectedDateType,
      startDate,
      endDate,
      viewLastYear,
      toggleViewLastYear,
      onExportToCsvClicked,
      onExportToPdfClicked,
      config,
      isExporting
    } = this.props;

    return (
      <div className="container-fluid">
        <Row className="filters">
          <Col lg="4" md="4" xs="6">
            {
              isMobile ? (
                <FilterModal>
                  <LocationFilter {...this.props} />
                </FilterModal>
              ) : (
                  <LocationFilter {...this.props} />
                )
            }
          </Col>
          <Col className="ml-auto" lg="auto" md="4" xs="6">
            <Row>
              {
                !isMobile && (
                  <Col sm="auto" xs="12">
                    <ExportDropdown
                      isExporting={isExporting}
                      isDisabled={isExporting}
                      onExportToCsv={onExportToCsvClicked}
                      onExportToPdf={onExportToPdfClicked}
                      {...config.exportConfig.healthStatus}
                    />
                  </Col>
                )
              }
              <Col lg={true} xs="12">
                <DateRangePicker
                  startDate={startDate}
                  endDate={endDate}
                  numberOfMonths={isMobile ? 1 : 2}
                  selectedDateType={selectedDateType}
                  updateCalendarStartEndDate={this.updateCalendarStartEndDate}
                  updateDateSelectionTypes={this.updateDateSelectionTypes}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="filter-switches">
          <Col xs="auto">
            <ToggleButtonGroup
              className="view-last-year"
              type="checkbox"
              value={viewLastYear ? ["1"] : []}
              onChange={toggleViewLastYear}
            >
              <ToggleButton value="1">View Last Year</ToggleButton>
            </ToggleButtonGroup>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state: any): IFiltersStateToProps => ({
  geos: state.omni.geos,
  ...state.smartHealthStatus
});

const mapDispatchToProps = (dispatch: any): IFiltersDispatchToProps => {
  return bindActionCreators({
    updateLocationType: HealthStatusActionCreators.updateLocationType,
    updateLocation: HealthStatusActionCreators.updateLocation,
    updateSelectedDates: HealthStatusActionCreators.updateSelectedDates,
    toggleViewLastYear: HealthStatusActionCreators.toggleViewLastYear,
    updateFiscalDateFilter: HealthStatusActionCreators.updateFiscalDateFilter
  }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(Filters);
