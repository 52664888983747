import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getUserInfoData } from './store/action';
import { Loader } from 'cx-ppd-client-components';
import { getValue } from "./utility/Common";
import { IAppState } from "./types";



export const ValidateAuthUser = (Component: any) => {
  class ValidateAuthUserHOC extends React.PureComponent<any, any> {
    componentDidMount() {
      if (!this.props.userAuthorized) {
        this.props.getUserInfoData();
      }
    }

    render() {
      const { userDataLoading, userAuthorized, userInfoData } = this.props;
      const tools = getValue('userRole.tools', userInfoData);
      return (
        userDataLoading ? <Loader /> :
          userAuthorized && tools && tools.length > 0 ? <Component {...this.props} /> :
            <h5>There are no tools associated with your role. Please contact your Administrator.</h5>
      );
    }

  }
  return connect(mapStateToProps, mapDispatchToProps)(ValidateAuthUserHOC);
};

const mapStateToProps = (state: IAppState) => {
  const { userDataLoading, userAuthorized, userInfoData } = state.commonReducer;
  return {
    userDataLoading,
    userAuthorized,
    userInfoData
  };
};

const mapDispatchToProps = (dispatch: any): any => {
  return bindActionCreators({
    getUserInfoData
  }, dispatch);
};