import * as React from "react";
import './VerticalBar.scss';
import { IVerticalBarProps } from "../../types/shared/VerticalBar/VerticalBar.types";

export const DEFAULT_HEIGHT = {
  TOTAL: 60,
  PICKED: 30
};

const VerticalBar = ({ totalUnits, unitsPicked, weekDay, maxUnits, maxBarHeight = 250, viewLastYear }: IVerticalBarProps) => {

  const totalHeight = () => {
    if (totalUnits && totalUnits.currentValue) {
      return maxBarHeight * totalUnits.currentValue / maxUnits;
    }
    return false;
  };

  const pickedHeight = () => {
    if (unitsPicked && unitsPicked.currentValue) {
      return maxBarHeight * unitsPicked.currentValue / totalUnits.currentValue;
    }
    return false;
  };

  return (
    <div className="vertical-bar-wrapper">
      <div className="vertical-bar" style={{ height: `${totalHeight() ? totalHeight() : DEFAULT_HEIGHT.TOTAL}px` }}>
        {
          totalUnits && totalUnits.currentValue ?
            <span className="bar-label">{totalUnits.currentValue}</span>
            :
            <span className="bar-label">- -</span>
        }
        {
          viewLastYear && totalUnits && totalUnits.lyValue > 0 && (
            <>
              <div className="last-year-value">{totalUnits.lyValue} LY</div>
              <div className="variation">{totalUnits.difference}% to LY</div>
            </>
          )
        }
        <div className="vertical-bar__total">
          {
            totalUnits && unitsPicked.currentValue ?
              <span className="bar-label">{unitsPicked.currentValue}</span>
              :
              <span className="bar-label">- -</span>
          }
          {
            viewLastYear && unitsPicked && unitsPicked.lyValue > 0 && (
              <>
                <div className="last-year-value">{unitsPicked.lyValue} LY</div>
                <div className="variation">{unitsPicked.difference}% to LY</div>
              </>
            )
          }
          <div className="vertical-bar__picked" style={{ height: `${pickedHeight() ? pickedHeight() : DEFAULT_HEIGHT.PICKED}px` }} />
        </div>
      </div>
      <div className="week-day-label">{weekDay}</div>
    </div>
  );
};
export default VerticalBar;

