import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Filters from '../../../../components/shared/Common/Filters';
import { AGGREGATION_OPTIONS } from '../../../../constants/omniConstants';
import { updateGeoFilterData } from '../../../../store/actions/leaderboardActions';
import { IPpdDashboardFilters, IGeos } from '../../../../store/reducers/types/omni.types';
import { IAppState } from '../../../../types';

interface ILeaderboardFiltersProps {
  isMobile?: boolean;
  onFilterChange?: (value: any) => void;
  getLeaderboardData: (requestParams: any) => void;
  viewByOptions?: any;
}
class LeaderboardFilters extends React.Component<IStateToProps & IDispatchToProps & ILeaderboardFiltersProps, any> {
  handleFilterChange = (value: any) => {
    const { onFilterChange } = this.props;
    this.props.updateGeoFilterData(value);
    if (onFilterChange) {
      onFilterChange(value);
    }
  }

  handleGeoFilterChange = ({ value }: any) => {
    const { startDate, endDate } = this.props;
    const territories = value.territories;
    if (territories !== this.props.filters.territories && territories.length > 0) {
      this.props.getLeaderboardData({ startDate, endDate, territoryNumber: territories.map((element: any) => element.territoryNumber).join(',') });
    }
    this.handleFilterChange(value);
  }

  handleViewByChange = ({ name, value, checked, isCheckUncheckAll }: any) => {
    if (value.length > 0) {
      this.handleFilterChange({ [name]: value });
    } else if (isCheckUncheckAll && !checked) {
      this.handleFilterChange({ [name]: [AGGREGATION_OPTIONS[AGGREGATION_OPTIONS.length - 1]] });
    }
  }

  render() {
    const { geosData, filters, isMobile, viewByOptions } = this.props;
    return (
      <Filters
        geoData={geosData}
        geoValue={filters}
        onGeoChange={this.handleGeoFilterChange}
        viewByValue={filters.viewBy || []}
        onViewByChange={this.handleViewByChange}
        isMobile={isMobile}
        viewByOptions={viewByOptions}
      />
    );
  }
}
interface IStateToProps {
  geosData: IGeos;
  startDate: any;
  endDate: any;
  filters: IPpdDashboardFilters;
}
const mapStateToProps = (state: IAppState): IStateToProps => {
  const { startDate, endDate } = state.omni.dateRangePicker;
  return {
    geosData: state.omni.geos,
    filters: state.omni.filters,
    startDate,
    endDate
  };
};
interface IDispatchToProps {
  updateGeoFilterData: (value: any) => void;
}
const mapDispatchToProps = (dispatch: any): IDispatchToProps => {
  return bindActionCreators({
    updateGeoFilterData
  }, dispatch);
};

export default connect<IStateToProps, IDispatchToProps, ILeaderboardFiltersProps>(
  mapStateToProps,
  mapDispatchToProps
)(LeaderboardFilters);
