import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DateRangePicker from '../../DateRangePicker';
import { DateSelectionTypes } from '../../../../constants/DateRangePickerConstant';
import { updateCalendarStartEndDate } from '../../../../store/actions/leaderboardActions';

export class LeaderBoardCalendar extends React.Component<any, any> {
  updateCalendarStartEndDate = (startDate: any, endDate: any) => {
    // const { territories } = this.props;
    this.props.updateCalendarStartEndDate(DateSelectionTypes.CUSTOM_DATE_RANGE, startDate, endDate);
    // this.props.getLeaderboardData({
    //   startDate,
    //   endDate,
    //   territoryNumber: territories ? territories.map((element: any) => element.territoryNumber).join(',') : null
    // });
    this.props.updateDateSelectionTypes("custom", { startDate, endDate });
  }

  render() {
    const { selectedDateType, startDate, endDate, updateDateSelectionTypes, numberOfMonths } = this.props;

    return (
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        numberOfMonths={numberOfMonths}
        selectedDateType={selectedDateType}
        updateCalendarStartEndDate={this.updateCalendarStartEndDate}
        updateDateSelectionTypes={updateDateSelectionTypes}
      />
    );
  }
}

export const mapStateToProps = (state: any): any => {
  const { selectedDateType, startDate, endDate } = state.omni.dateRangePicker;
  return {
    selectedDateType,
    startDate,
    endDate
  };
};

const mapDispatchToProps = (dispatch: any): any => {
  return bindActionCreators({
    updateCalendarStartEndDate
  }, dispatch);
};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(LeaderBoardCalendar);

