import * as React from 'react';
import { Col, DropdownButton, Dropdown } from 'react-bootstrap';
import { Loader } from 'cx-ppd-client-components';
interface IExportDropdownProps {
  isExporting?: boolean;
  isDisabled?: boolean;
  allowCSVExport: boolean;
  allowPDFExport: boolean;
  onExportToCsv: () => void;
  onExportToPdf: () => void;
}
function ExportDropdown(props: IExportDropdownProps) {
  const { isExporting, isDisabled = false, allowCSVExport, allowPDFExport, onExportToCsv, onExportToPdf } = props;
  if (!allowCSVExport && !allowPDFExport) {
    return null;
  }
  return (
    <Col sm="auto" xs="12">
      {isExporting && <div className="loader-class"><Loader /></div>}
      <DropdownButton id="dropdown-basic-button" disabled={isDisabled} className='export-button' title={<span>Export</span>}>
        {allowCSVExport && <Dropdown.Item onClick={onExportToCsv}>Export to CSV</Dropdown.Item>}
        {allowPDFExport && <Dropdown.Item onClick={onExportToPdf}>Export to PDF</Dropdown.Item>}
      </DropdownButton>
    </Col>
  );
}
export default ExportDropdown;
