import * as React from 'react';
import { Nav, NavItem } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { cs } from '../../../utility/Common';
import { IOmniNavProps, IOmniNavState } from '../../types/dashboards/OmniDashboard/OmniNav.types';

export const OmniNav = ({ className, navigation }: IOmniNavProps) => (
  <Nav className={cs("navbar-align navbar-margin", className)}>
    {navigation &&
      navigation.map((element: IOmniNavState, index: number) => (
        <NavItem className="navbar-align" key={index}>
          <NavLink className="nav-link" to={element.url}>{element.label}</NavLink>
        </NavItem>
      ))
    }
  </Nav>
);