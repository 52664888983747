import { env } from "../envConfig";
import { http } from "./http";

export const getmanageRolesData = () => {
  const url = env.REACT_APP_ADMIN_SERVICE + "/roles";
  // change service to roles service
  return http
    .get(url, {}, false)
    .then(response => {
      return response.data;
      // json payload needs to return
    })
    .catch(e => {
      return e;
    });
};

export const getmanageToolsData = () => {
  const url = env.REACT_APP_ADMIN_SERVICE + "/tools";
  // change service to roles service
  return http
    .get(url, {}, false)
    .then(response => {
      return response.data;
      // json payload needs to return
    })
    .catch(e => {
      return e;
    });
};

export const getUserInfo = () => {
  const url = env.REACT_APP_ADMIN_SERVICE + "/auth/user";
  return http
    .get(url, {}, false)
    .then(response => {
      return response.data;
      // json payload needs to return
    })
    .catch(e => {
      return { error: true, e };
    });
};

export const postTools = (roleId: any, requestData: any) => {
  // change service to roles service
  const url = env.REACT_APP_ADMIN_SERVICE + "/roles/" + roleId + "/tools";
  return http.put(url, requestData, {}, false)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      return e;
    });
};
