import * as React from 'react';
import { DashboardDataTable } from 'cx-ppd-client-components';

const getRowType = (row: any) => {
  if (!row) {
    return null;
  }
  if (row.type) {
    return row.type;
  } else if (row.rowsType) {
    return row.rowsType;
  }
  return null;
};
class LeaderboardTable extends React.PureComponent<any, any> {
  static getBackgroundColour(type: string) {
    let className = '';
    switch (type) {
      case "COMPANY":
        className = "kohlsAverage-background-shade";
        break;
      case "VOLUME":
        className = "volumeAverage-background-shade";
        break;
      case "TERRITORY":
        className = "territory-background-shade";
        break;
      case "REGION":
        className = "region-background-shade";
        break;
      case "DISTRICT":
        className = "district-background-shade";
        break;
      case "STORE":
        className = "store-background-shade";
        break;
      default:
        className = "default-background-shade";
    }
    return className;
  }

  defaultRowHeight = 56;

  RowRenderer = ({ renderBaseRow, ...props }: any) => {
    const { row } = props;

    const className = LeaderboardTable.getBackgroundColour(getRowType(row));
    return <div className={className}>{renderBaseRow(props)}</div>;
  }

  getHeaderHeight = () => {
    const { headerHeight, stickyRowsIdxs } = this.props;

    return stickyRowsIdxs ? headerHeight + (stickyRowsIdxs.length * this.defaultRowHeight) : headerHeight;
  }

  getStickyRows = () => {
    const { rowsData, stickyRowsIdxs } = this.props;

    return stickyRowsIdxs ? stickyRowsIdxs.map((i: number) => rowsData[i]) : null;
  }

  render() {
    const { rowsData, territories } = this.props;

    return (
      <DashboardDataTable
        {...this.props}
        rowRenderer={this.RowRenderer}
        rowsCount={rowsData.length}
        rowHeight={this.defaultRowHeight}
        minHeight={rowsData.length > 0 ? 474 : 296}
        noDataText={territories && territories.length === 0 ? "Please select a filter" : "No results found"}
        headerRowHeight={this.getHeaderHeight()}
        stickyRows={this.getStickyRows()}
      />
    );
  }
}

export default LeaderboardTable;
