import * as React from 'react';
import * as moment from "moment";
import { exportLeaderboardToPdf } from '../../../../../utility/smartUtils';
import LeaderBoardsHeader from '../LeaderBoardsHeader';
import LeaderBoardTableHeader from '../LeaderBoardTableHeader';
import LeaderboardTable from '../LeaderboardTable';

const winWidth = window.innerWidth;

const AssociateColumnWidth = {
  blankColumn: winWidth * 0.2,
  speed: winWidth * 0.458,
  accuracy: winWidth * 0.3
};

export class DesktopAssociateLeaderBoardWrapper extends React.PureComponent<any, any> {

  exportLeaderboardDataPdf = () => {
    const { metaData: { associateMetaSchema }, rowsData } = this.props;
    this.props.showLoaderOnExport();
    exportLeaderboardToPdf(associateMetaSchema, rowsData, `AssociateLeaderboard_${moment().format()}`).then(() => {
      this.props.hideLoaderFromExport();
    });
  }

  render() {
    const {
      rowsData,
      metaData,
      territories,
      getLeaderboardData,
      updateDateSelectionTypes,
      dashboardsNavigation,
      filtersConfig,
      metricDefinition,
      showExportLoader,
      onGridSort,
      sortColumn,
      sortDirection,
      stickyRowsIdxs,
      getNotifications,
      exportLeaderboardDataCsv
    } = this.props;

    return (
      <>
        <LeaderBoardsHeader
          showExportLoader={showExportLoader}
          filtersConfig={filtersConfig}
          getLeaderboardData={getLeaderboardData}
          onExportToCsvClicked={exportLeaderboardDataCsv}
          onExportToPdfClicked={this.exportLeaderboardDataPdf}
          updateDateSelectionTypes={updateDateSelectionTypes}
          dashboardsNavigation={dashboardsNavigation}
          getNotifications={getNotifications}
        />

        <div className="leaderboard associate-leaderboard container-fluid">
          <LeaderBoardTableHeader metricDetails={metricDefinition.AssociateLederboard} />
          {metaData && metaData.associateMetaSchema && metaData.associateMetaSchema.columns &&
            <div className="leaderboard-grid associate-grid">
              <LeaderboardTable
                columns={metaData.associateMetaSchema.columns}
                rowsData={rowsData}
                headerHeight={136}
                columnWidths={AssociateColumnWidth}
                territories={territories}
                onGridSort={onGridSort}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
                stickyRowsIdxs={stickyRowsIdxs}
              />
            </div>
          }
        </div>
      </>
    );
  }
}

export default DesktopAssociateLeaderBoardWrapper;