import { Dispatch } from 'react-redux';
import * as moment from 'moment';
import * as ActionTypes from '../actionTypes';
import { DateSelectionTypes } from '../../constants/DateRangePickerConstant';
import { smartDashboardApi } from '../../api/smartDashboardApi';
import { IAppState } from '../../types';
import { AvgRowsTypes } from '../../constants/smartConstants';
import { leaderboardType } from '../../container/SmartDashboard';
import { ISmartAggegatedData } from './types/smartActions.types';
import { IAssociateLeaderboardAggregatedData, IOmniAssociateCompanyData, ICommonAggregatedData, IOmniNotification, IOmniAssociateVolumeData } from '../reducers/types/omni.types';
import { GEOKEYS, AGGREGATION_OPTION_VALUES } from '../../constants/omniConstants';
import { AxiosError } from 'axios';
import { getValue } from '../../utility/Common';
import { VIEW_BY_SMART_CONSTANTS } from '../../constants/commonConstants';
import { getViewBySelectedFilters, getSelectedFilters } from '../../utility/dashboard/filters';

const sortAggregatedData = (aggregatedData: ISmartAggegatedData[], isMobileSmartAssociate?: any): IAssociateLeaderboardAggregatedData => {
  const sortedData = {
    company: {} as IOmniAssociateCompanyData,
    volumes: {} as IOmniAssociateVolumeData,
    territories: {} as ICommonAggregatedData,
    regions: {} as ICommonAggregatedData,
    districts: {} as ICommonAggregatedData,
    stores: {}
  };

  aggregatedData.forEach((el: any) => {
    switch (el.rowsType) {
      case AvgRowsTypes.company:
        sortedData.company = { ...el, type: 'COMPANY', rank: `Kohl's`, associateName: isMobileSmartAssociate ? `Kohl's` : 'Associate Average', locationNumber: '- All', locationName: '', territoryNumber: '- All', territoryName: '', regionNumber: '- All', regionName: '', districtNumber: '- All', districtName: '' };
        break;
      case AvgRowsTypes.volume:
        sortedData.volumes = { ...sortedData.volumes, [el.volumeNumber]: el };
        break;
      case AvgRowsTypes.territory:
        sortedData.territories = { ...sortedData.territories, [el.territoryNumber]: el };
        break;
      case AvgRowsTypes.region:
        sortedData.regions = { ...sortedData.regions, [el.regionNumber]: el };
        break;
      case AvgRowsTypes.district:
        sortedData.districts = { ...sortedData.districts, [el.districtNumber]: el };
        break;
      case AvgRowsTypes.store:
        sortedData.stores = { ...sortedData.stores, [el.storeNumber]: el };
        break;
      default:
        break;
    }
  });

  return sortedData;
};

export const startEndDate = (selectedDayType: any): any => {
  switch (selectedDayType) {
    case DateSelectionTypes.Today:
      return {
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
      };
    case DateSelectionTypes.Yesterday:
      return {
        startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        endDate: moment().subtract(1, 'days').format('YYYY-MM-DD')
      };
    case DateSelectionTypes.WeekToDate:
      return {
        startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
      };
    case DateSelectionTypes.MonthToDate:
      return {
        startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
      };
    case DateSelectionTypes.QuarterToDate:
      return {
        startDate: moment().subtract(90, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
      };
    case DateSelectionTypes.YearToDate:
      return {
        startDate: moment().subtract(365, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
      };
    case DateSelectionTypes.PeakToDate:
      return {
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
      };
    case DateSelectionTypes.TWO_WEEKS_FROM_TODAY:
      return {
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().add(13, 'days').format('YYYY-MM-DD')
      };
    case DateSelectionTypes.CURRENT_MONTH:
      return {
        startDate: moment().format('YYYY-MM-01'),
        endDate: moment().format("YYYY-MM-") + moment().daysInMonth()
      };
  }
};

export function getAssociateLeaderboardMetaDataSchema(queryParam: any): (dispatch: Dispatch<any>) => Promise<void> {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: ActionTypes.GET_ASSOCIATE_LEADERBOARD_METADATA });
    return smartDashboardApi.getLeaderboardMetaDataSchema(queryParam)
      .then((metaData: any) => {
        dispatch({ type: ActionTypes.GET_ASSOCIATE_LEADERBOARD_METADATA_SUCCESS, payload: metaData, isMobileSmartAssociate: queryParam.type === leaderboardType.associate.mobile });
      }).catch(() => {
        dispatch({ type: ActionTypes.GET_ASSOCIATE_LEADERBOARD_METADATA_FAILED, payload: null });
      });
  };
}

export function getAssociateLeaderboardData(requestParams: any): (dispatch: Dispatch<any>, getState: () => IAppState) => Promise<void> {
  return async (dispatch: Dispatch<any>, getState: () => IAppState) => {
    dispatch({ type: ActionTypes.GET_ASSOCIATE_DATA });
    const { omni } = getState();
    smartDashboardApi.getCompanyAssociateLeaderboardData({ ...requestParams, leaderboard: 'associate' })
      .then((aggregatedData) => {
        dispatch({
          type: ActionTypes.GET_ASSOCIATE_AGGREGATE_DATA_SUCCESS,
          payload: sortAggregatedData(aggregatedData, omni.isMobileSmartAssociate)
        });
      });

    return smartDashboardApi.getAssociateLeaderboardData(requestParams)
      .then((associateData: any) => {
        dispatch({ type: ActionTypes.GET_SMART_ASSOCIATE_DATA_SUCCESS, payload: associateData });
      }).catch(() => {
        dispatch({ type: ActionTypes.GET_ASSOCIATE_DATA_FAILED, payload: null });
      });
  };
}

export function getStoreLeaderboardMetaDataSchema(queryParam: any): (dispatch: Dispatch<any>) => Promise<void> {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: ActionTypes.GET_STORE_LEADERBOARD_METADATA });
    return smartDashboardApi.getLeaderboardMetaDataSchema(queryParam)
      .then((metaData: any) => {
        dispatch({ type: ActionTypes.GET_STORE_LEADERBOARD_METADATA_SUCCESS, payload: metaData });
      }).catch(() => {
        dispatch({ type: ActionTypes.GET_STORE_LEADERBOARD_METADATA_FAILED, payload: null });
      });
  };
}

export function getStoreLeaderboardData(requestParams: any): (dispatch: Dispatch<any>, getState: () => IAppState) => Promise<void> {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: ActionTypes.GET_STORE_DATA });
    smartDashboardApi.getCompanyStoreLeaderboardData({ ...requestParams, leaderboard: 'store' })
      .then((aggregatedData) => {
        dispatch({
          type: ActionTypes.GET_STORE_AGGREGATE_DATA_SUCCESS,
          payload: sortAggregatedData(aggregatedData)
        });
      });

    return smartDashboardApi.getStoreLeaderboardData(requestParams)
      .then((storeData: any) => {
        dispatch({ type: ActionTypes.GET_STORE_DATA_SUCCESS, payload: storeData });
      }).catch(() => {
        dispatch({ type: ActionTypes.GET_STORE_DATA_FAILED, payload: null });
      });
  };
}

export function getHomeData(queryString: any): (dispatch: Dispatch<any>) => Promise<void> {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: ActionTypes.GET_SMART_HOME_DATA });
    return smartDashboardApi.getHomeData(queryString)
      .then((homeData: any) => {
        dispatch({ type: ActionTypes.GET_SMART_HOME_DATA_SUCCESS, payload: homeData });
      }).catch(() => {
        dispatch({ type: ActionTypes.GET_SMART_HOME_DATA_FAILED });
      });
  };
}

const getNotificationApiParams = (locationType: any, location: any, dateType: string) => {
  const locationTypeValue = locationType ? locationType.value : AGGREGATION_OPTION_VALUES.Store;
  const locationKey = GEOKEYS[locationTypeValue].ValueKey;
  return {
    dateType,
    [locationKey]: [location[locationKey]],
    viewBy: [locationType ? VIEW_BY_SMART_CONSTANTS[locationTypeValue] : VIEW_BY_SMART_CONSTANTS.stores]
  };
};
export function getSmartNotificationsData(queryString: any, location: any, locationType: any, dateType: string = DateSelectionTypes.MonthToDate): (dispatch: Dispatch<any>) => Promise<void> {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: ActionTypes.GET_NOTIFICATION_DATA });
    return smartDashboardApi.getSmartNotifications(queryString)
      .then((notifications: IOmniNotification[]) => {
        dispatch<any>(fetchSmartNotificationsData(getNotificationApiParams(locationType, location, dateType), location, locationType, notifications));
      }).catch((err: AxiosError) => {
        dispatch<any>(fetchSmartNotificationsData(getNotificationApiParams(locationType, location, dateType), location, locationType, null, err));
      });
  };
}

function fetchSmartNotificationsData(queryString: any, location: any, locationType: any, notificationsData?: IOmniNotification[] | null, err?: any): (dispatch: Dispatch<any>) => Promise<void> {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: ActionTypes.GET_NOTIFICATION_DATA });
    if (queryString && (queryString.dateType === DateSelectionTypes.Yesterday || queryString.dateType === DateSelectionTypes.CUSTOM_DATE_RANGE)) {
      if (notificationsData) {
        return dispatch<any>({ type: ActionTypes.GET_SMART_NOTIFICATION_SUCCESS, payload: { smartNotifications: notificationsData, location, locationType } });
      } else {
        return dispatch<any>({ type: ActionTypes.GET_SMART_NOTIFICATION_FAILED, payload: { notificationsError: err && err.message, location, locationType } });
      }
    } else {
      return smartDashboardApi.getSmartPositiveNotifications(queryString)
        .then((positiveNotifications: IOmniNotification[]) => {
          let notifications = notificationsData;
          if (positiveNotifications && positiveNotifications.length > 0) {
            positiveNotifications = positiveNotifications.map(n => ({ ...n, isPositive: true }));
            notifications = notifications ? notifications.concat(positiveNotifications) : positiveNotifications;
          }
          dispatch({ type: ActionTypes.GET_SMART_NOTIFICATION_SUCCESS, payload: { smartNotifications: notifications, location, locationType } });
        }).catch((error: AxiosError) => {
          if (notificationsData) {
            dispatch({ type: ActionTypes.GET_SMART_NOTIFICATION_SUCCESS, payload: { smartNotifications: notificationsData, location, locationType } });
          } else {
            dispatch({ type: ActionTypes.GET_SMART_NOTIFICATION_FAILED, payload: { notificationsError: error.message, location, locationType } });
          }
        });
    }
  };
}

export const getSmartLeaderboardNotifications = () => (dispatch: Dispatch<any>, getState: () => IAppState) => {
  const { omni, smartHealthStatus } = getState();
  const { filters, dateRangePicker } = omni;
  const { selectedDateType } = smartHealthStatus;
  const { viewBy } = filters;

  if (selectedDateType === DateSelectionTypes.Yesterday &&
    selectedDateType === DateSelectionTypes.CUSTOM_DATE_RANGE) {
    return;
  }

  if (viewBy &&
    viewBy.length === 1 &&
    getValue('value', viewBy.find(v => v.value === AGGREGATION_OPTION_VALUES.Volume)) === AGGREGATION_OPTION_VALUES.Volume
  ) {
    return;
  }

  const selectedFilters = getSelectedFilters(viewBy, filters);
  const apiParams = {
    dateType: dateRangePicker.selectedDateType,
    viewBy: getViewBySelectedFilters(viewBy, VIEW_BY_SMART_CONSTANTS),
    ...selectedFilters
  };
  dispatch<any>(
    fetchSmartNotificationsData(apiParams, null, null)
  );
};

export const resetSmartNotificationsData = () => (dispatch: Dispatch<any>) => {
  dispatch({ type: ActionTypes.RESET_NOTIFICATION_DATA });
};