import { http } from "./http";
import { ApiHeaders } from "../utility/ApiHeaders";
import { env } from "../envConfig";

export const smartDashboardApi = {
  getGeoData() {
    const url = env.REACT_APP_STORE_SERVICE + "/v1/stores/geo/filters";
    return http
      .get(url, {}, false)
      .then(response => {
        return response.data;
      });
  },

  getLeaderboardMetaDataSchema(queryParam: any) {
    const url = env.REACT_APP_OMNI_PICKING_SERVICE + "/v1/schema";
    return http
      .get(url, { params: queryParam })
      .then(response => {
        return response.data;
      });
  },

  getAssociateLeaderboardData(requestParams: any) {
    const url = env.REACT_APP_SMART_PICKING_SERVICE + "/v1/smart/picking/associate/leaderboard";

    return http
      .get(url, { params: requestParams })
      .then(response => {
        return response.data;
      });
  },

  getStoreLeaderboardData(requestParams: any) {
    const url = env.REACT_APP_SMART_PICKING_SERVICE + "/v1/smart/picking/store/leaderboard";

    return http
      .get(url, { params: requestParams })
      .then(response => {
        return response.data;
      });
  },

  getCompanyStoreLeaderboardData(requestParams: any) {
    const url = env.REACT_APP_SMART_PICKING_SERVICE + "/v1/smart/picking/avg/leaderboard";

    return http
      .get(url, { params: requestParams })
      .then((response) => {
        return response.data;
      });
  },

  getCompanyAssociateLeaderboardData(requestParams: any) {
    const url = env.REACT_APP_SMART_PICKING_SERVICE + "/v1/smart/picking/avg/leaderboard";

    return http
      .get(url, { params: requestParams })
      .then((response) => {
        return response.data;
      });
  },

  getSmartNotifications(queryParam: any) {
    const url = env.REACT_APP_OMNI_HEALTH_SERVICE + "/v1/smart/notifications/health";
    return http
      .get(url, {
        params: queryParam
      })
      .then(response => {
        return response.data;
      });
  },

  getSmartPositiveNotifications(params: any) {
    const url = env.REACT_APP_OMNI_HEALTH_SERVICE + "/v1/smart/notifications";
    return http
      .post(url, params)
      .then(response => {
        return response.data;
      });
  },

  getHealthStatusData(requestParams: any): Promise<any> {
    const url = env.REACT_APP_SMART_HEALTH_SERVICE + "/v1/smart/health/summary";
    return http
      .get(url, {
        params: requestParams,
        headers: ApiHeaders()
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return error;
      });
  },

  getHealthStatusDaySummary(requestParams: any): Promise<any> {
    const url = env.REACT_APP_SMART_HEALTH_SERVICE + "/v1/smart/health/daily-summary";
    return http
      .get(url, {
        params: requestParams,
        headers: ApiHeaders()
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return error;
      });
  },

  getHomeData(requestParams: any) {
    const url = env.REACT_APP_SMART_PICKING_SERVICE + "/v1/smart/picking/store/home/leaderboard";

    return http
      .get(url, { params: requestParams })
      .then(response => {
        return response.data;
      });
  }

};
