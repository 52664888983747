import * as React from 'react';
import MobileDataTable, { IMobileDataTableProps } from '../MobileDataTable';
import LeaderboardTable from './LeaderboardTable';

interface ILeaderboardMobileTableProps extends IMobileDataTableProps {
  stickyRowsIdxs?: number[];
}
interface ILeaderboardMobileTableState {
  rows: any[];
  stickyRows: any[];
}
class LeaderboardMobileTable extends React.PureComponent<ILeaderboardMobileTableProps, ILeaderboardMobileTableState> {
  static getDerivedStateFromProps(props: ILeaderboardMobileTableProps) {
    const { stickyRowsIdxs, rowsData } = props;
    return {
      stickyRows: stickyRowsIdxs && rowsData && rowsData.length > 0 ? stickyRowsIdxs.map((idx: number) => rowsData[idx]) : [],
      rows: stickyRowsIdxs ? rowsData.filter((row: any, idx: number) => !stickyRowsIdxs.includes(idx)) : rowsData
    };
  }

  RowRenderer = (props: any) => {
    const { row, renderBaseRow, style, className } = props;
    const bgClassName = LeaderboardTable.getBackgroundColour(row.type);
    return (
      <div className={`${className} ${bgClassName}`} style={style}>
        {renderBaseRow(props)}
      </div>
    );
  }

  render() {
    const { rows, stickyRows } = this.state;
    return (
      <>
        <MobileDataTable
          {...this.props}
          rowsData={rows}
          stickyRows={stickyRows}
          rowRenderer={this.RowRenderer}
        />
      </>
    );
  }
}

export default LeaderboardMobileTable;