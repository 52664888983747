import * as localStore from "./LocalStorage";
import { SplunkConsole } from './SplunkLogging';

export const pageName = {
  freightHome: 'ppd:Freight Home',
  omniStatus: 'ppd:Omni Status',
  omniCalendar: 'ppd:Omni Calendar',
  omniAssociateLeaderboard: 'ppd:Omni Associate Leaderboard',
  omniStoreLeaderboard: 'ppd:Omni Store Leaderboard',
  replenishmentStatus: 'ppd:Replenishment Status',
  replenishmentAssociateLeaderboard: 'ppd:Replenishment Associate Leaderboard',
  replenishmentStoreLeaderboardPage: 'ppd:Replenishment Store Leaderboard'
};

function dataObject() {
  const userInfo = localStore.loadState() !== undefined ? localStore.loadState().userInfo : '';
  return {
    storeNumber: userInfo.storeId ? userInfo.storeId : '',
    roleType: userInfo.userRole && userInfo.userRole.role ? userInfo.userRole.role : '',
    site: 'planning and productivity dashboard',
    platform: 'web'
  };
}

function triggerAnalytics(data: any) {
  try {
    window._satellite.track(data);
  } catch (e) {
    SplunkConsole.error({ error: e, URL: location.href });
  }
}

export function homePage(trackingData: any) {
  const dataObj = dataObject();
  const trackingObj = {
    pageName: 'ppd:homepage',
    error: '',
    ppdPage: 'ppd:homepage'
  };
  window.dataLayer = { ...trackingObj, ...dataObj };
  triggerAnalytics("ppd_pageView");
}

export function manageRolesPage(trackingData: any) {
  const dataObj = dataObject();
  const trackingObj = {
    pageName: 'ppd:admin:manage roles',
    error: '',
    ppdPage: 'ppd:admin'
  };
  window.dataLayer = { ...trackingObj, ...dataObj };
  triggerAnalytics("ppd_pageView");
}

export function manifestOverviewPage(trackingData: any) {
  const dataObj = dataObject();
  const trackingObj = {
    pageName: 'ppd:manifest and details dashboard',
    error: '',
    ppdPage: 'ppd:manifest and details dashboard'
  };
  window.dataLayer = { ...trackingObj, ...dataObj };
  triggerAnalytics("ppd_pageView");
}

export function realTimeExecutionPage(trackingData: any) {
  const dataObj = dataObject();
  const trackingObj = {
    pageName: 'ppd:real time execution',
    error: '',
    ppdPage: 'ppd:real time execution'
  };
  window.dataLayer = { ...trackingObj, ...dataObj };
  triggerAnalytics("ppd_pageView");
}

export function inBoundFreightPage(trackingData: any) {
  const dataObj = dataObject();
  const trackingObj = {
    pageName: 'ppd:inbound freight visibility',
    error: '',
    ppdPage: 'ppd:inbound freight visibility'
  };
  window.dataLayer = { ...trackingObj, ...dataObj };
  triggerAnalytics("ppd_pageView");
}

export function postTruckAnalysisPage(trackingData: any) {
  const dataObj = dataObject();
  const trackingObj = {
    pageName: 'ppd:post truck analysis',
    error: '',
    previousPageName: '/home',
    ppdPage: 'ppd:post truck analysis'
  };
  window.dataLayer = { ...trackingObj, ...dataObj };
  triggerAnalytics("ppd_pageView");
}

export const trackPage = (page: string) => {
  const dataObj = dataObject();
  const trackingObj = {
    pageName: page,
    error: '',
    ppdPage: page
  };
  window.dataLayer = { ...trackingObj, ...dataObj };
  triggerAnalytics("ppd_pageView");
};

export const omnitureTracking = {
  homePage,
  manageRolesPage,
  manifestOverviewPage,
  realTimeExecutionPage,
  inBoundFreightPage,
  postTruckAnalysisPage,
  trackPage
};