import * as React from 'react';

import FilterModal from '../../../FilterModal';
import LeaderBoardCalendar from '../LeaderBoardCalendar';
import LeaderBoardNav from '../../../LeaderBoardNav';
import LeaderboardMobileTable from '../LeaderboardMobileTable';
import LeaderBoardFilters from '../LeaderBoardFilters';
import { HighlightInstruction } from '../LeaderBoardTableHeader';

class MobileStoreLeaderBoardWrapper extends React.PureComponent<any, any> {
  render() {
    const { metaData, rowsData, sortColumn, sortDirection, onGridSort, getLeaderboardData, updateDateSelectionTypes, dashboardsNavigation, getNotifications, stickyRowsIdxs, viewByOptions } = this.props;
    return (
      <>
        <FilterModal modalclassName="leaderboard-filters-modal">
          <LeaderBoardFilters
            getLeaderboardData={getLeaderboardData}
            isMobile={true}
            onFilterChange={getNotifications}
            viewByOptions={viewByOptions}
          />
        </FilterModal>
        <LeaderBoardCalendar
          numberOfMonths={1}
          getLeaderboardData={getLeaderboardData}
          updateDateSelectionTypes={updateDateSelectionTypes}
        />
        <div className='leaderBoardNav-mobile'>
          <LeaderBoardNav navigation={dashboardsNavigation} />
        </div>

        <div className="store-leaderboard container-fluid">
          <HighlightInstruction />
          {metaData && metaData.storeMetaSchema && metaData.storeMetaSchema.columns &&
            <LeaderboardMobileTable
              columns={metaData.storeMetaSchema.columns}
              rowsData={rowsData}
              onGridSort={onGridSort}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              stickyRowsIdxs={stickyRowsIdxs}
            />}
        </div>
      </>
    );
  }
}

export default MobileStoreLeaderBoardWrapper;