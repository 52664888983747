import * as React from 'react';
import { PPDModal } from 'cx-ppd-client-components';


const MetricDetails = ({ children }: any) => (
  <div className="metric-details-container">
    <PPDModal
      className="metric-details"
      title=""
      trigger={(
        <a href="javascript:;">METRIC DETAILS</a>
      )}
    >
      {children}
    </PPDModal>
  </div>
);

export default MetricDetails;
