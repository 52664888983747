import * as ActionTypes from '../actionTypes';
import { IHomePageState } from './types/homePageReducer.types';

const initialState: IHomePageState = {
  selectedStore: null,
  smartHomeData: null
};

export default function homePageReducer(state: IHomePageState = initialState, action: any) {
  switch (action.type) {
    case ActionTypes.UPDATE_STORE_DETAILS:
      return {
        ...state,
        selectedStore: action.payload
      };
    case ActionTypes.GET_SMART_HOME_DATA_SUCCESS:
      return {
        ...state,
        smartHomeData: action.payload
      };
    case ActionTypes.GET_SMART_HOME_DATA_FAILED:
      return {
        ...state,
        smartHomeData: null
      };
    default:
      return state;
  }
}