import * as React from 'react';

import FilterModal from '../../../FilterModal';
import LeaderBoardCalendar from '../LeaderBoardCalendar';
import LeaderBoardNav from '../../../LeaderBoardNav';
import LeaderboardMobileTable from '../LeaderboardMobileTable';
import LeaderBoardFilters from '../LeaderBoardFilters';
import { HighlightInstruction } from '../LeaderBoardTableHeader';
import { IMobileAssociateLeaderboardWrapper } from '../../../../types/shared/DashboardItems/LeaderBoards/AssociateLeaderboard/MobileAssociateLeaderboardWrapper.types';


export class MobileAssociateLeaderboardWrapper extends React.PureComponent<IMobileAssociateLeaderboardWrapper, any> {
  render() {
    const { rowsData, sortColumn, sortDirection, onGridSort, metaData, getLeaderboardData, updateDateSelectionTypes, dashboardsNavigation, getNotifications, stickyRowsIdxs } = this.props;
    return (
      <>
        <FilterModal modalclassName="leaderboard-filters-modal">
          <LeaderBoardFilters
            getLeaderboardData={getLeaderboardData}
            isMobile={true}
            onFilterChange={getNotifications}
          />
        </FilterModal>
        <LeaderBoardCalendar
          numberOfMonths={1}
          getLeaderboardData={getLeaderboardData}
          updateDateSelectionTypes={updateDateSelectionTypes}
        />
        <div className='leaderBoardNav-mobile'>
          <LeaderBoardNav navigation={dashboardsNavigation} />
        </div>

        <div className="associate-leaderboard container-fluid">
          <HighlightInstruction />
          {metaData && metaData.associateMetaSchema && metaData.associateMetaSchema.columns &&
            <LeaderboardMobileTable
              columns={metaData.associateMetaSchema.columns}
              rowsData={rowsData}
              onGridSort={onGridSort}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              stickyRowsIdxs={stickyRowsIdxs}
            />}
        </div>
      </>
    );
  }
}

export default MobileAssociateLeaderboardWrapper;