import { AnyAction } from "redux";
import { SmartHealthStatusActions } from "../actionTypes";
import { AGGREGATION_OPTIONS, AGGREGATION_OPTION_VALUES } from "../../constants/omniConstants";
import { startEndDate } from "../actions/smartActions";
import { DateSelectionTypes } from "../../constants/DateRangePickerConstant";
import { IHealthStatusState } from "./types/smartHealthStatus.types";

const { startDate, endDate } = startEndDate(DateSelectionTypes.Today);

const initalState: IHealthStatusState = {
  locationType: AGGREGATION_OPTIONS.find((o: any) => o.value === AGGREGATION_OPTION_VALUES.Store) || null,
  location: null,
  viewLastYear: false,
  selectedDateType: DateSelectionTypes.Today,
  startDate,
  endDate,
  isLoading: false,
  healthData: null,
  dailySummaryData: null,
  dateType: null
};

export const smartHealthStatusReducer = (state: IHealthStatusState = initalState, action: AnyAction) => {
  switch (action.type) {
    case SmartHealthStatusActions.SMART_UPDATE_LOCATION_TYPE:
      return {
        ...state,
        locationType: action.payload,
        location: null,
        healthData: null
      };
    case SmartHealthStatusActions.SMART_UPDATE_LOCATION:
      return {
        ...state,
        location: action.payload
      };
    case SmartHealthStatusActions.SMART_TOGGLE_VIEW_LAST_YEAR:
      return {
        ...state,
        viewLastYear: !state.viewLastYear
      };
    case SmartHealthStatusActions.SMART_UPDATE_SELECTED_DATES:
      return {
        ...state,
        ...action.payload
      };
    case SmartHealthStatusActions.SMART_GET_HEALTH_STATUS_DATA:
      return {
        ...state,
        isLoading: true,
        healthData: null
      };
    case SmartHealthStatusActions.SMART_GET_HEALTH_STATUS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        healthData: action.payload
      };
    case SmartHealthStatusActions.SMART_GET_HEALTH_STATUS_DATA_FAIL:
      return {
        ...state,
        isLoading: false
      };
    case SmartHealthStatusActions.SMART_GET_HEALTH_DAILY_SUMMARY_DATA:
      return {
        ...state,
        isLoading: true,
        dailySummaryData: null
      };
    case SmartHealthStatusActions.SMART_GET_HEALTH_DAILY_SUMMARY_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dailySummaryData: action.payload.hasOwnProperty('dailySummaryKPI') ? action.payload.dailySummaryKPI : null
      };
    case SmartHealthStatusActions.SMART_GET_HEALTH_DAILY_SUMMARY_DATA_FAIL:
      return {
        ...state,
        isLoading: false
      };
    case SmartHealthStatusActions.SMART_UPDATE_FISCAL_DATE_FILTER:
      return {
        ...state,
        dateType: action.payload
      };
    default:
      return { ...state };
  }
};