// Types
export const GET_USER_INFO_DATA = "GET_USER_INFO_DATA";
export const GET_USER_INFO_DATA_SUCCESS = "GET_USER_INFO_DATA_SUCCESS";
export const GET_USER_INFO_DATA_ERROR = "GET_USER_INFO_DATA_ERROR";

export const GET_MANAGE_TOOLS_DATA = "GET_MANAGE_TOOLS_DATA";
export const GET_MANAGE_ROLES_DATA = "GET_MANAGE_ROLES_DATA";
export const PUT_TOOLS_DATA = "PUT_TOOLS_DATA";

export const HAS_ERROR = "HAS_ERROR";

// Realtime Execution Dashboard types
export const GET_REALTIME_RECEIVING_DATA = "GET_REALTIME_RECEIVING_DATA";
export const GET_REALTIME_RECEIVING_DATA_SUCCESS = "GET_REALTIME_RECEIVING_DATA_SUCCESS";
export const GET_REALTIME_RECEIVING_NETWORK_ERROR = "GET_REALTIME_RECEIVING_NETWORK_ERROR";
export const CLEAR_REAL_TIME_STATE = "CLEAR_REAL_TIME_STATE";

// Manifest Dashboard Types
export const GET_STORES = 'GET_STORES';
export const GET_STORES_SUCCESS = 'GET_STORES_SUCCESS';

export const GET_TRIPS = 'GET_TRIPS';
export const GET_TRIPS_SUCCESS = 'GET_TRIPS_SUCCESS';
export const GET_TRIPS_FAILED = 'GET_TRIPS_FAILED';
export const CLEAR_TRIPS = 'CLEAR_TRIPS';

export const GET_TRIP_MANIFEST = 'GET_TRIP_MANIFEST';
export const GET_TRIP_MANIFEST_SUCCESS = 'GET_TRIP_MANIFEST_SUCCESS';
export const GET_TRIP_MANIFEST_FAILED = 'GET_TRIP_MANIFEST_FAILED';
export const CLEAR_TRIP_MANIFEST = 'CLEAR_TRIP_MANIFEST';

export const GET_TRIP_MANIFEST_CATEGORIES = 'GET_TRIP_MANIFEST_CATEGORIES';
export const GET_TRIP_MANIFEST_CATEGORIES_SUCCESS = 'GET_TRIP_MANIFEST_CATEGORIES_SUCCESS';
export const GET_TRIP_MANIFEST_CATEGORIES_FAILED = 'GET_TRIP_MANIFEST_CATEGORIES_FAILED';


export const GET_INBOUND_TRIPS = 'GET_INBOUND_TRIPS';
export const GET_INBOUND_TRIPS_SUCCESS = 'GET_INBOUND_TRIPS_SUCCESS';
export const GET_INBOUND_TRIPS_FAILED = 'GET_INBOUND_TRIPS_FAILED';
export const CLEAR_INBOUND_TRIPS = 'CLEAR_INBOUND_TRIPS';

export const GET_FISCAL_WEEKS = 'GET_FISCAL_WEEKS';
export const GET_FISCAL_WEEKS_SUCCESS = 'GET_FISCAL_WEEKS_SUCCESS';
export const GET_FISCAL_WEEKS_FAILED = 'GET_FISCAL_WEEKS_FAILED';

export const GET_FISCAL_CALENDAR = 'GET_FISCAL_CALENDAR';
export const GET_FISCAL_CALENDAR_SUCCESS = 'GET_FISCAL_CALENDAR_SUCCESS';
export const GET_FISCAL_CALENDAR_FAILED = 'GET_FISCAL_CALENDAR_FAILED';

export const GET_KPIS = 'GET_KPIS';
export const GET_KPIS_SUCCESS = 'GET_KPIS_SUCCESS';
export const GET_KPIS_FAILED = 'GET_KPIS_FAILED';

export const GET_POST_TRUCK_TRAILERS = 'GET_POST_TRUCK_TRAILERS';
export const GET_POST_TRUCK_TRAILERS_SUCCESS = 'GET_POST_TRUCK_TRAILERS_SUCCESS';
export const GET_POST_TRUCK_TRAILERS_FAILED = 'GET_POST_TRUCK_TRAILERS_FAILED';
export const CLEAR_POST_TRUCK_TRAILERS = 'CLEAR_POST_TRUCK_TRAILERS';
export const CLEAR_POST_TRUCK_STATE = 'CLEAR_POST_TRUCK_STATE';

// Omni
export const SHOW_EXPORT_LOADER = 'SHOW_EXPORT_LOADER';
export const HIDE_EXPORT_LOADER = 'HIDE_EXPORT_LOADER';

export const GET_GEO_DATA = 'GET_GEO_DATA';
export const GET_GEO_DATA_SUCCESS = 'GET_GEO_DATA_SUCCESS';
export const GET_GEO_DATA_FAILED = 'GET_GEO_DATA_FAILED';

export const GET_ASSOCIATE_DATA = 'GET_ASSOCIATE_DATA';
export const GET_ASSOCIATE_DATA_SUCCESS = 'GET_ASSOCIATE_DATA_SUCCESS';
export const GET_ASSOCIATE_DATA_FAILED = 'GET_ASSOCIATE_DATA_FAILED';
export const SORT_ASSOCIATE_LEADERBOARD_DATA = 'SORT_ASSOCIATE_LEADERBOARD_DATA';

export const GET_STORE_DATA = 'GET_STORE_DATA';
export const GET_STORE_DATA_SUCCESS = 'GET_STORE_DATA_SUCCESS';
export const GET_STORE_DATA_FAILED = 'GET_STORE_DATA_FAILED';
export const SORT_STORE_LEADERBOARD_DATA = 'SORT_STORE_LEADERBOARD_DATA';

export const GET_STORE_AGGREGATE_DATA_SUCCESS = 'GET_STORE_AGGREGATE_DATA_SUCCESS';
export const GET_ASSOCIATE_AGGREGATE_DATA_SUCCESS = 'GET_ASSOCIATE_AGGREGATE_DATA_SUCCESS';
export const GET_SMART_ASSOCIATE_DATA_SUCCESS = 'GET_SMART_ASSOCIATE_DATA_SUCCESS';

export const GET_ASSOCIATE_LEADERBOARD_METADATA = 'GET_ASSOCIATE_LEADERBOARD_METADATA';
export const GET_ASSOCIATE_LEADERBOARD_METADATA_SUCCESS = 'GET_ASSOCIATE_LEADERBOARD_METADATA_SUCCESS';
export const GET_ASSOCIATE_LEADERBOARD_METADATA_FAILED = 'GET_ASSOCIATE_LEADERBOARD_METADATA_FAILED';

export const GET_STORE_LEADERBOARD_METADATA = 'GET_STORE_LEADERBOARD_METADATA';
export const GET_STORE_LEADERBOARD_METADATA_SUCCESS = 'GET_STORE_LEADERBOARD_METADATA_SUCCESS';
export const GET_STORE_LEADERBOARD_METADATA_FAILED = 'GET_STORE_LEADERBOARD_METADATA_FAILED';

export const UPDATE_SELECTED_DATE_TYPE = 'UPDATE_SELECTED_DATE_TYPE';
export const UPDATE_START_END_DATE = 'UPDATE_START_END_DATE';
export const UPDATE_GEO_FILTER_DATA = 'UPDATE_GEO_FILTER_DATA';

export const GET_NOTIFICATION_DATA = 'GET_NOTIFICATION_DATA';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_FAILED = 'GET_NOTIFICATION_FAILED';
export const REMOVE_NOTIFICATION_ITEM = 'REMOVE_NOTIFICATION_ITEM';
export const REMOVE_ALL_NOTIFICATION_ITEMS = 'REMOVE_ALL_NOTIFICATION_ITEMS';
export const MARK_NOTIFICATION_ITEM_SELECTED = 'MARK_NOTIFICATION_ITEM_SELECTED';
export const UPDATE_NOTIFICATION_DATA = 'UPDATE_NOTIFICATION_DATA';
export const RESET_NOTIFICATION_DATA = 'RESET_NOTIFICATION_DATA';

// Smart Replenishment
export const GET_SMART_HOME_DATA = 'GET_SMART_HOME_DATA';
export const GET_SMART_HOME_DATA_SUCCESS = 'GET_SMART_HOME_DATA_SUCCESS';
export const GET_SMART_HOME_DATA_FAILED = 'GET_SMART_HOME_DATA_FAILED';

// Smart Notification
export const GET_SMART_NOTIFICATION_SUCCESS = 'GET_SMART_NOTIFICATION_SUCCESS';
export const GET_SMART_NOTIFICATION_FAILED = 'GET_SMART_NOTIFICATION_FAILED';

/** Calendar Dashboard */
export const GET_OMNI_CALENDAR_DATA = 'GET_OMNI_CALENDAR_DATA';
export const GET_OMNI_CALENDAR_DATA_SUCCESS = 'GET_OMNI_CALENDAR_DATA_SUCCESS';
export const GET_OMNI_CALENDAR_DATA_FAILED = 'GET_OMNI_CALENDAR_DATA_FAILED';

export const GET_CALENDAR_TRENDS_DATA = 'GET_CALENDAR_TRENDS_DATA';
export const GET_CALENDAR_TRENDS_DATA_SUCCESS = 'GET_CALENDAR_TRENDS_DATA_SUCCESS';
export const GET_CALENDAR_TRENDS_DATA_FAILED = 'GET_CALENDAR_TRENDS_DATA_FAILED';

export const GET_CALENDAR_EVENTS_DATA = 'GET_CALENDAR_EVENTS_DATA';
export const GET_CALENDAR_EVENTS_DATA_SUCCESS = 'GET_CALENDAR_EVENTS_DATA_SUCCESS';
export const GET_CALENDAR_EVENTS_DATA_FAILED = 'GET_CALENDAR_EVENTS_DATA_FAILED';

export const UPDATE_CALENDAR_DATE = 'UPDATE_CALENDAR_DATE';
export const UPDATE_CALENDAR_LOCATION = 'UPDATE_CALENDAR_LOCATION';
export const UPDATE_CALENDAR_LOCATION_TYPE = 'UPDATE_CALENDAR_LOCATION_TYPE';
export const UPDATE_MODIFIED_TRENDS = 'UPDATE_MODIFIED_TRENDS';
export const MODIFIED_TRENDS_APPLIED = 'MODIFIED_TRENDS_APPLIED';
export const RESET_MODIFIED_TRENDS = 'RESET_MODIFIED_TRENDS';
export const REMOVE_INDIVIDUAL_TRENDS = 'REMOVE_INDIVIDUAL_TRENDS';

/** HealthStaus Actions */
export const HealthStatusActions = {
  UPDATE_LOCATION_TYPE: 'UPDATE_LOCATION_TYPE',
  UPDATE_LOCATION: 'UPDATE_LOCATION',
  UPDATE_SELECTED_DATES: 'HS_UPDATE_SELECTED_DATES',
  TOGGLE_VIEW_LAST_YEAR: 'TOGGLE_VIEW_LAST_YEAR',
  CLEAR_HEALTH_STATUS_LAST_YEAR_DATA: 'CLEAR_HEALTH_STATUS_LAST_YEAR_DATA',
  TOGGLE_VIEW_BY: 'TOGGLE_VIEW_BY',
  GET_HEALTH_STATUS_DATA: 'GET_HEALTH_STATUS_DATA',
  GET_HEALTH_STATUS_DATA_SUCCESS: 'GET_HEALTH_STATUS_DATA_SUCCESS',
  GET_HEALTH_STATUS_DATA_FAIL: 'GET_HEALTH_STATUS_DATA_FAIL',
  GET_HEALTH_STATUS_LAST_YEAR_DATA: 'GET_HEALTH_STATUS_LAST_YEAR_DATA',
  GET_HEALTH_STATUS_LAST_YEAR_DATA_SUCCESS: 'GET_HEALTH_STATUS_LAST_YEAR_DATA_SUCCESS',
  GET_HEALTH_STATUS_LAST_YEAR_DATA_FAIL: 'GET_HEALTH_STATUS_LAST_YEAR_DATA_FAIL',
  RESET_NOTIFICATION_DATA: 'RESET_NOTIFICATION_DATA'
};

/** HomePage Actions */
export const UPDATE_STORE_DETAILS = 'UPDATE_STORE_DETAILS';

/** Smart Replenishment HealthStaus Actions */
export const SmartHealthStatusActions = {
  SMART_UPDATE_LOCATION_TYPE: 'SMART_UPDATE_LOCATION_TYPE',
  SMART_UPDATE_LOCATION: 'SMART_UPDATE_LOCATION',
  SMART_UPDATE_SELECTED_DATES: 'SMART_UPDATE_SELECTED_DATES',
  SMART_TOGGLE_VIEW_LAST_YEAR: 'SMART_TOGGLE_VIEW_LAST_YEAR',
  SMART_TOGGLE_VIEW_BY: 'SMART_TOGGLE_VIEW_BY',
  SMART_GET_HEALTH_STATUS_DATA: 'SMART_GET_HEALTH_STATUS_DATA',
  SMART_GET_HEALTH_STATUS_DATA_SUCCESS: 'SMART_GET_HEALTH_STATUS_DATA_SUCCESS',
  SMART_GET_HEALTH_STATUS_DATA_FAIL: 'SMART_GET_HEALTH_STATUS_DATA_FAIL',
  SMART_GET_HEALTH_DAILY_SUMMARY_DATA: 'SMART_GET_HEALTH_DAILY_SUMMARY_DATA',
  SMART_GET_HEALTH_DAILY_SUMMARY_DATA_SUCCESS: 'SMART_GET_HEALTH_DAILY_SUMMARY_DATA_SUCCESS',
  SMART_GET_HEALTH_DAILY_SUMMARY_DATA_FAIL: 'SMART_GET_HEALTH_DAILY_SUMMARY_DATA_FAIL',
  SMART_RESET_FILTERS: 'SMART_RESET_FILTERS',
  SMART_UPDATE_FISCAL_DATE_FILTER: 'SMART_UPDATE_FISCAL_DATE_FILTER',
  SMART_CLEAR_HEALTH_STATUS_LAST_YEAR_DATA: 'SMART_CLEAR_HEALTH_STATUS_LAST_YEAR_DATA',
  RESET_NOTIFICATION_DATA: 'RESET_NOTIFICATION_DATA'

};
/** FreightHomePage Actions */
export const UPDATE_FREIGHT_HOME_STORE_DETAILS = 'UPDATE_FREIGHT_HOME_STORE_DETAILS';

export const INIT_ACTION = "@@INIT";
