import { HealthStatusActions } from "../actionTypes";
import { AnyAction } from "redux";
import { omniDashboardApi } from "../../api/omniDashboardApi";
import { ThunkDispatch } from "redux-thunk";
import { GEOKEYS, AGGREGATION_OPTION_VALUES } from "../../constants/omniConstants";
import { IHealthStatusState } from "../reducers/types/omniHealthStatus.types";
import { IAppState } from "../../types";
import { getNotificationsData, resetOmniNotificationData } from "./omniAction";

export const updateLocationType = (locationType: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => IAppState) => {
    dispatch({
      type: HealthStatusActions.UPDATE_LOCATION_TYPE,
      payload: locationType
    });
    if (isCompany(locationType)) {
      dispatch(getHealthStatusDashboardData());
    }
  };
};

export const fetchNotifications = (location: any, locationType: any, dateType?: string) => {
  if (location && typeof location === 'object' && locationType) {
    if (locationType) {
      const locationKey = locationType.value;
      return getNotificationsData({ [GEOKEYS[locationKey].ValueKey]: location[GEOKEYS[locationKey].ValueKey] }, location, locationType, dateType);
    }
  }
  return getNotificationsData({}, location, locationType, dateType);
};

export const updateLocation = (location: any) => {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch({
      type: HealthStatusActions.UPDATE_LOCATION,
      payload: location
    });
    dispatch(getHealthStatusDashboardData());
  };
};

export interface ISelectedDates {
  selectedDateType: any;
  startDate: any;
  endDate: any;
}
export const updateSelectedDates = (params: ISelectedDates) => {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch({
      type: HealthStatusActions.UPDATE_SELECTED_DATES,
      payload: params
    });
    const { startDate, endDate } = params;
    if (startDate && endDate) {
      dispatch(getHealthStatusDashboardData());
    }
  };
};

const clearHealthStatusLastYearData = () => {
  return {
    type: HealthStatusActions.CLEAR_HEALTH_STATUS_LAST_YEAR_DATA
  };
};

export const toggleViewLastYear = (value: any[]) => {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch({
      type: HealthStatusActions.TOGGLE_VIEW_LAST_YEAR
    });
    if (value.length > 0) {
      dispatch(getHealthStatusLastYearData());
    } else {
      dispatch(clearHealthStatusLastYearData());
    }
  };
};

export const toggleViewBy = () => {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch({
      type: HealthStatusActions.TOGGLE_VIEW_BY
    });

    dispatch(getHealthStatusDashboardData());
  };
};

const getCompanyParams = (omniHealthStatus: IHealthStatusState) => {
  if (omniHealthStatus && isCompany(omniHealthStatus.locationType)) {
    const { startDate, endDate, viewBy, selectedDateType } = omniHealthStatus;
    return {
      type: viewBy,
      startDate,
      endDate,
      dateType: selectedDateType
    };
  }
  return null;
};

export const isCompany = (locationType: any) => {
  if (locationType && locationType.value === AGGREGATION_OPTION_VALUES.Company) {
    return true;
  }
  return false;
};

export const getHealthStatusRequestParams = (omniHealthStatus: IHealthStatusState) => {
  if (!omniHealthStatus.location) {
    return;
  }
  const { locationType, viewBy, startDate, endDate, selectedDateType } = omniHealthStatus;
  if (!locationType) {
    return null;
  }
  const locationValue = locationType.value;
  const locationTypeKey = GEOKEYS[locationValue].ValueKey;
  return {
    [locationTypeKey]: omniHealthStatus.location[locationTypeKey],
    type: viewBy,
    startDate,
    endDate,
    dateType: selectedDateType
  };
};


export const getHealthStatusDashboardData = () => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => IAppState) => {
    const { omniHealthStatus } = getState();
    const { viewLastYear, location, locationType, selectedDateType } = omniHealthStatus;
    let requestParams: any = getCompanyParams(omniHealthStatus);
    let company = false;
    if (!requestParams) {
      requestParams = getHealthStatusRequestParams(omniHealthStatus);
      if (!requestParams) {
        return Promise.resolve();
      }
    }
    else {
      company = true;
    }

    if (viewLastYear) {
      dispatch(getHealthStatusLastYearData(requestParams, company));
    }
    if (company) {
      dispatch(resetOmniNotificationData());
    } else {
      dispatch(fetchNotifications(location, locationType, selectedDateType));
    }
    return dispatch(getHealthStatusData(requestParams, company));
  };
};

export const getHealthStatusData = (requestParams: any, company?: boolean) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch({ type: HealthStatusActions.GET_HEALTH_STATUS_DATA });
    omniDashboardApi.getHealthStatusData(requestParams, company).then((healthStatusData: any) => {
      dispatch({ type: HealthStatusActions.GET_HEALTH_STATUS_DATA_SUCCESS, payload: healthStatusData });
    }).catch(() => {
      dispatch({ type: HealthStatusActions.GET_HEALTH_STATUS_DATA_FAIL, payload: null });
    });
  };
};

export const getHealthStatusLastYearData = (requestParams?: any, company?: boolean) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => any) => {

    const { omniHealthStatus } = getState();
    requestParams = getHealthStatusRequestParams(omniHealthStatus);
    if (omniHealthStatus && isCompany(omniHealthStatus.locationType)) {
      requestParams = getCompanyParams(omniHealthStatus);
      company = true;
    }
    if (!requestParams) {
      return Promise.resolve();
    }
    dispatch({ type: HealthStatusActions.GET_HEALTH_STATUS_LAST_YEAR_DATA });
    return omniDashboardApi.getHealthStatusData({ ...requestParams, ly: true }, company).then((healthStatusData: any) => {
      dispatch({ type: HealthStatusActions.GET_HEALTH_STATUS_LAST_YEAR_DATA_SUCCESS, payload: healthStatusData });
    }).catch(() => {
      dispatch({ type: HealthStatusActions.GET_HEALTH_STATUS_LAST_YEAR_DATA_FAIL, payload: null });
    });
  };
};