import * as React from "react";
import { PPDModal } from "cx-ppd-client-components";
import { omniDashboardApi } from "../../../api/omniDashboardApi";
import { IPropsByExportManager, IExportParams } from "../../types/shared/Common/ExportManager.types";
import { env } from "../../../envConfig";

export function exportManager<T extends IPropsByExportManager>(Component: React.ComponentType<T>) {
  class ExportManager extends React.Component<Exclude<T, IPropsByExportManager>> {
    state = {
      downloadTriedCount: 1,
      intervalID: 0,
      downloadAttemptsTotal: env.REACT_APP_DOWNLOAD_EXPORT_FILE_TRIES !== undefined ? env.REACT_APP_DOWNLOAD_EXPORT_FILE_TRIES : 2,
      timeout: env.REACT_APP_DOWNLOAD_EXPORT_FILE_TIMEOUT !== undefined ? env.REACT_APP_DOWNLOAD_EXPORT_FILE_TIMEOUT : "10000",
      isExporting: false
    };

    closeModal = () => {
      clearTimeout(this.state.intervalID);
      this.setState({ downloadTriedCount: 0, isExporting: false });
    }

    exportToFile = (exportParams: IExportParams) => {
      this.setState({ downloadTriedCount: 0, isExporting: true });
      omniDashboardApi.executeExportToFile(exportParams)
        .then((response: any) => {
          this.retryDownloadExportFile(response);
        })
        .catch(() => {
          this.closeModal();
        });
    }

    componentWillUnmount() {
      // Clear timeout
      clearTimeout(this.state.intervalID);
    }

    exportTimer = (item: any) => {
      this.setState({ intervalId: setTimeout(() => { this.tick(item); }, parseInt(this.state.timeout, 10)) });
    }

    retryDownloadExportFile = (item: any) => {
      this.exportTimer(item);
    }

    tick = (item: any) => {
      const { intervalID, isExporting, downloadTriedCount, downloadAttemptsTotal } = this.state;
      if (isExporting && downloadTriedCount < downloadAttemptsTotal) {
        this.setState({ downloadTriedCount: ++this.state.downloadTriedCount });
        this.executeDownload(item);
      }
      else {
        clearTimeout(intervalID);
      }
    }

    executeDownload(item: any) {
      omniDashboardApi.tryDownloadExportFile(item)
        .then(() => {
          this.closeModal();
        })
        .catch(() => {
          this.retryDownloadExportFile(item);
        });
    }

    render() {
      const { downloadTriedCount, downloadAttemptsTotal, isExporting } = this.state;
      return (
        <>
          <Component
            {...this.props}
            isExporting={isExporting}
            triggerExport={this.exportToFile}
            cancelExport={this.closeModal}
          />
          {
            downloadTriedCount >= downloadAttemptsTotal ? (
              <PPDModal className="no-export-data" defaultOpen={true} onHide={this.closeModal}>The export has failed. Please try again</PPDModal>
            ) : null}
        </>
      );
    }
  }
  return ExportManager;
}