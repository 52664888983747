import * as actionTypes from '../actionTypes';

const initalState = {
  fiscalCalendar: {},
  calendarLoading: false,
  kpis: {},
  kpiLoading: false,
  trailers: [],
  trailersLoading: false
};

export default function postTruckAnalysis(state = initalState, action: any) {
  switch (action.type) {
    case actionTypes.GET_FISCAL_CALENDAR:
      return { ...state, fiscalCalendar: initalState.fiscalCalendar, calendarLoading: true };
    case actionTypes.GET_FISCAL_CALENDAR_SUCCESS:
      return { ...state, fiscalCalendar: action.payload, calendarLoading: false };
    case actionTypes.GET_FISCAL_CALENDAR_FAILED:
      return { ...state, initalState, calendarLoading: false };

    case actionTypes.GET_KPIS:
      return { ...state, kpis: initalState.kpis, kpiLoading: true };
    case actionTypes.GET_KPIS_SUCCESS:
      return { ...state, kpis: action.payload, kpiLoading: false };
    case actionTypes.GET_KPIS_FAILED:
      return { ...state, initalState, kpiLoading: false };


    case actionTypes.GET_POST_TRUCK_TRAILERS:
      return { ...state, trailers: initalState.trailers, trailersLoading: true };
    case actionTypes.GET_POST_TRUCK_TRAILERS_SUCCESS:
      return { ...state, trailers: action.payload, trailersLoading: false };
    case actionTypes.GET_POST_TRUCK_TRAILERS_FAILED:
      return { ...state, trailers: initalState.trailers, trailersLoading: false };
    case actionTypes.CLEAR_POST_TRUCK_TRAILERS:
      return { ...state, trailers: initalState.trailers };
    case actionTypes.CLEAR_POST_TRUCK_STATE:
      return { ...state, fiscalCalendar: initalState.fiscalCalendar, kpis: initalState.kpis, trailers: initalState.trailers };
    default:
      return state;
  }
}