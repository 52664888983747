import * as ActionTypes from '../actionTypes';
import { IFreightHomePageState } from './types/freightHomePageReducer.types';

const initialState: IFreightHomePageState = {
  selectedStore: null
};

export default function freightHomePageReducer(state: IFreightHomePageState = initialState, action: any) {
  switch (action.type) {
    case ActionTypes.UPDATE_FREIGHT_HOME_STORE_DETAILS:
      return {
        ...state,
        selectedStore: action.payload
      };
    default:
      return state;
  }
}