import * as ActionTypes from '../actionTypes';
import * as moment from 'moment';
import { AnyAction } from 'redux';
import { AGGREGATION_OPTIONS, AGGREGATION_OPTION_VALUES } from '../../constants/omniConstants';
import { getValue } from '../../utility/Common';
import { IOmniCalendarState, IMonthTrends, IUpdatedTrends } from './types/omniCalendarReducer.types';

const initialState: IOmniCalendarState = {
  locationType: AGGREGATION_OPTIONS.find((o: any) => o.value === AGGREGATION_OPTION_VALUES.Store) || null,
  location: null,
  calendarAPILoading: false,
  calendarData: null,
  monthTrendDataLoading: false,
  monthTrends: {} as IMonthTrends,
  calendarEventsLoading: false,
  calendarEventsData: null,
  selectedDate: moment().startOf('week'),
  updatedTrends: {} as IUpdatedTrends,
  modifiedTrendsFlag: {
    sfsMtd: false,
    bopusMtd: false,
    bossMtd: false
  }
};

const checkWhichTrendModified = (stateMonthTrends: any, stateUpdatedTrends: any) => {
  return {
    sfsMtd: getValue('sfsMtd', stateUpdatedTrends) !== 0,
    bopusMtd: getValue('bopusMtd', stateUpdatedTrends) !== 0,
    bossMtd: getValue('bossMtd', stateUpdatedTrends) !== 0
  };
};

export default function omniCalendarReducer(state: IOmniCalendarState = initialState, action: AnyAction) {
  switch (action.type) {
    case ActionTypes.GET_OMNI_CALENDAR_DATA:
      return {
        ...state,
        calendarAPILoading: true,
        calendarData: null
      };
    case ActionTypes.GET_OMNI_CALENDAR_DATA_SUCCESS:
      return {
        ...state,
        calendarAPILoading: false,
        calendarData: action.payload
      };
    case ActionTypes.GET_OMNI_CALENDAR_DATA_FAILED:
      return {
        ...state,
        calendarAPILoading: false
      };
    case ActionTypes.GET_CALENDAR_TRENDS_DATA:
      return {
        ...state,
        monthTrendDataLoading: true,
        monthTrends: {},
        updatedTrends: {},
        modifiedTrendsFlag: {
          sfsMtd: false,
          bopusMtd: false,
          bossMtd: false
        }
      };
    case ActionTypes.GET_CALENDAR_TRENDS_DATA_SUCCESS:
      const trends = getValue('stores.0', action.payload, {});
      return {
        ...state,
        monthTrendDataLoading: false,
        monthTrends: trends,
        updatedTrends: trends
      };
    case ActionTypes.GET_CALENDAR_TRENDS_DATA_FAILED:
      return {
        ...state,
        monthTrendDataLoading: false,
        monthTrends: {},
        updatedTrends: {}
      };
    case ActionTypes.GET_CALENDAR_EVENTS_DATA:
      return {
        ...state,
        calendarEventsLoading: true,
        calendarEventsData: action.payload
      };
    case ActionTypes.GET_CALENDAR_EVENTS_DATA_SUCCESS:
      return {
        ...state,
        calendarEventsLoading: false,
        calendarEventsData: action.payload
      };
    case ActionTypes.GET_CALENDAR_EVENTS_DATA_FAILED:
      return {
        ...state,
        calendarEventsLoading: false,
        calendarEventsData: action.payload
      };
    case ActionTypes.UPDATE_CALENDAR_LOCATION_TYPE:
      return {
        ...state,
        locationType: action.payload,
        location: null
      };
    case ActionTypes.UPDATE_CALENDAR_LOCATION:
      return {
        ...state,
        location: action.payload
      };
    case ActionTypes.UPDATE_CALENDAR_DATE:
      return {
        ...state,
        selectedDate: action.payload
      };
    case ActionTypes.UPDATE_MODIFIED_TRENDS:
      return {
        ...state,
        updatedTrends: action.payload
      };
    case ActionTypes.RESET_MODIFIED_TRENDS:
      return {
        ...state,
        updatedTrends: { ...state.monthTrends },
        modifiedTrendsFlag: {
          sfsMtd: false,
          bopusMtd: false,
          bossMtd: false
        }
      };
    case ActionTypes.MODIFIED_TRENDS_APPLIED:
      return {
        ...state,
        modifiedTrendsFlag: checkWhichTrendModified(state.monthTrends, state.updatedTrends)
      };
    case ActionTypes.REMOVE_INDIVIDUAL_TRENDS:
      return {
        ...state,
        updatedTrends: action.payload,
        modifiedTrendsFlag: action.updatedModifiedTrendsFlag
      };
    default:
      return state;
  }
}