import { AGGREGATION_OPTION_VALUES, GEOKEYS } from "../../constants/omniConstants";

export const getViewBySelectedFilters = (viewByObj: any, constants: any) => viewByObj.filter((v: any) => v.value !== AGGREGATION_OPTION_VALUES.Volume).map((v: any) => constants[v.value]);

export const getSelectedFilters = (viewBy: any, filters: any) => {
  const selectedFilters = {};

  viewBy.forEach(({ value }: any) => {
    const key = GEOKEYS[value].ValueKey;
    const filterValue = filters[value];
    if (filterValue) {
      selectedFilters[key] = filterValue.map((f: any) => f[key]);
    }
  });

  return selectedFilters;
};