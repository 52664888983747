import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Switch, Route, Redirect } from 'react-router';
import { isMobile } from 'react-device-detect';
import { PPDModal } from 'cx-ppd-client-components';

import '../../assets/styles/smart-dashboard.scss';
import {
  getAssociateLeaderboardData,
  getAssociateLeaderboardMetaDataSchema,
  getStoreLeaderboardMetaDataSchema,
  getStoreLeaderboardData,
  startEndDate,
  getSmartLeaderboardNotifications
} from '../../store/actions/smartActions';
import { pageName } from "../../utility/OmnitureTracking";
import { getGeoData, showLoaderOnExport, hideLoaderFromExport, updateGeoFilterData } from '../../store/actions/leaderboardActions';
import { cs, validateRoute } from '../../utility/Common';
import DashboardHeader from '../../components/shared/DashboardItems/DashboardHeader';
import Header from '../../components/layout/Header';
import StoreLeaderBoardWrapper from '../../components/shared/DashboardItems/LeaderBoards/StoreLeaderboard/StoreLeaderboardWrapper';
import AssociateLeaderboardWrapper from '../../components/shared/DashboardItems/LeaderBoards/AssociateLeaderboard/AssociateLeaderboardWrapper';
import SmartStatus from './HealthStatus/SmartStatus';
import { smartDashboardApi } from '../../api/smartDashboardApi';
import { AppBreadcrumbs, smartModulesNavigation, smartDashboardsNavigation, smartRoute } from '../../constants/routes';
import { SmartMetricDefinition } from '../../constants/smartConstants';
import { IAppState } from '../../types';
import { ISmartDashboardProps, ISmartDashboardState } from '../types/SmartDashboard/SmartDashboardIndex.types';
import { AGGREGATION_OPTIONS_WITH_VOLUME, AGGREGATION_OPTION_VALUES, AGGREGATION_OPTIONS } from '../../constants/omniConstants';
import { IDashboardConfig } from '../types/Common.types';
import { exportManager } from '../../components/shared/Common/ExportManager';
import { ExportFormatType, ExportProduct, ExportDashbordType } from '../../components/types/shared/Common/ExportManager.types';
import { env } from '../../envConfig';

export const dashboardConfig: IDashboardConfig = {
  theme: 'smart',
  name: 'Replenishment',
  isSmartDasboard: true,
  showCalendar: false,
  showNotifications: true,
  metricDefinition: SmartMetricDefinition,
  customRoles: true,
  stickyRows: {
    store: [0],
    associate: [0]
  },
  filtersConfig: {
    exportConfig: {
      leaderboards: {
        allowCSVExport: env.REACT_APP_ENABLE_SMART_LEADERBOARD_CSV_EXPORT ? JSON.parse(env.REACT_APP_ENABLE_SMART_LEADERBOARD_CSV_EXPORT) : true,
        allowPDFExport: env.REACT_APP_ENABLE_SMART_LEADERBOARD_PDF_EXPORT ? JSON.parse(env.REACT_APP_ENABLE_SMART_LEADERBOARD_PDF_EXPORT) : true
      },
      healthStatus: {
        allowCSVExport: env.REACT_APP_ENABLE_SMART_STATUS_CSV_EXPORT ? JSON.parse(env.REACT_APP_ENABLE_SMART_STATUS_CSV_EXPORT) : true,
        allowPDFExport: env.REACT_APP_ENABLE_SMART_STATUS_PDF_EXPORT ? JSON.parse(env.REACT_APP_ENABLE_SMART_STATUS_PDF_EXPORT) : true
      }
    },
    viewByOptions: AGGREGATION_OPTIONS_WITH_VOLUME
  }
};

export const leaderboardType = {
  associate: {
    web: 'smart_associate_web',
    mobile: 'smart_associate_mobile'
  },
  store: {
    web: 'smart_store_web',
    mobile: 'smart_store_mobile'
  }
};

const healthStatusConfig = {
  isFiscalDateFilter: true
};

const NoAccess = () => {
  return (
    <div className="no-access-container">
      <p>No Access</p>
    </div>
  );
};

class SmartDashboard extends React.Component<ISmartDashboardProps, ISmartDashboardState> {
  state = {
    showExportNoDataModal: false
  };

  componentDidMount() {
    document.body.classList.add('bg-gray');
    this.props.getGeoData();
  }

  componentWillUnmount() {
    document.body.classList.remove('bg-gray');
  }

  getNotifications = () => {
    this.props.getSmartLeaderboardNotifications();
  }

  exportLeaderboardCsv = (queryParams: any) => {
    const { dateRangePicker: { startDate, endDate, selectedDateType }, geos, leaderboardFilters } = this.props;

    if (!geos || !geos.territories) {
      return;
    }
    const territoriesData = leaderboardFilters && leaderboardFilters.territories ? leaderboardFilters.territories : geos.territories;
    const territoryNumber = territoriesData.map((element: any) => element.territoryNumber).join(',');
    const bodyParams = { startDate, endDate, territoryNumber, dateType: selectedDateType };
    this.props.triggerExport({
      queryParams,
      bodyParams
    });
  }
  // onCsvExport = (props: any, leaderboard: string) => {
  //   this.props.showLoaderOnExport();
  //   exportLeaderboardDataCsv(props, leaderboard).then(() => {
  //     this.props.hideLoaderFromExport();
  //   }).catch(() => {
  //     this.setState({ showExportNoDataModal: true });
  //     this.props.hideLoaderFromExport();
  //   });
  // }

  onStoreLeaderboardCsvExport = () => {
    // const { rowsData, metaData } = this.props.storeLeaderboard;
    // this.onCsvExport({
    //   rowsData,
    //   metaData
    // }, "store");
    this.exportLeaderboardCsv({
      type: ExportFormatType.Csv,
      product: ExportProduct.Smart,
      dashboard: ExportDashbordType.StoreLeaderboard
    });
  }

  onAssociateLeaderboardCsvExport = () => {
    // const { rowsData, metaData } = this.props.associateLeaderboard;
    // this.onCsvExport({
    //   rowsData,
    //   metaData
    // }, "associate");
    this.exportLeaderboardCsv({
      type: ExportFormatType.Csv,
      product: ExportProduct.Smart,
      dashboard: ExportDashbordType.AssociateLeaderboard
    });
  }

  closeModal = () => {
    this.setState({ showExportNoDataModal: false });
  }

  resetFilters = () => {
    const { leaderboardFilters: { viewBy } } = this.props;
    const volumeIndex = viewBy.findIndex((v: any) => v.value === AGGREGATION_OPTION_VALUES.Volume);
    if (volumeIndex > -1) {
      viewBy.splice(volumeIndex, 1);
      const viewByData = viewBy.length > 0 ? viewBy : [AGGREGATION_OPTIONS[AGGREGATION_OPTIONS.length - 1]];
      this.props.updateGeoFilterData({
        viewBy: viewByData
      });
    }
  }
  
  render() {
    const {
      userInfoData,
      // associateMetaSchema,
      associateLastUpdatedOn,
      storeMetaSchema,
      storeLastUpdatedOn,
      // showExportLoader,
      notifications,
      isExporting,
      triggerExport
    } = this.props;
    const { name, userRole } = userInfoData;
    const leaderboardObj = userRole && userRole.tools.filter((toolRoute: any) => toolRoute.link === smartRoute.associateLeaderboard || toolRoute.link === smartRoute.storeLeaderboard);
    const leaderboardRoutes = leaderboardObj.map((route: any) => route.link);
    const dashboardRoute = leaderboardRoutes.indexOf(smartRoute.associateLeaderboard) > -1 ? smartRoute.associateLeaderboard : smartRoute.storeLeaderboard;

    return (
      <div className={cs("custom-dashboard-container smart-dashboard-container", { 'mobile': isMobile })}>
        {this.state.showExportNoDataModal && <PPDModal className="no-export-data" defaultOpen={true} onHide={this.closeModal}>No data available to download</PPDModal>}
        <Header
          styleClassName="header no-background"
          breadcrumbs={AppBreadcrumbs.SmartDashboard}
          uName={name}
          uRole={userRole ? userRole.role : ""}
          homeIcon={require("../../assets/images/home-black.png")}
          accountIcon={require("../../assets/images/account-icon-black.png")}
        />

        <DashboardHeader
          config={dashboardConfig}
          modulesNavigation={smartModulesNavigation}
          notifications={notifications}
        />
        <Switch>
          <Redirect from={smartRoute.default} exact={true} to={smartRoute.statusDashboard} />
          <Redirect from={smartRoute.leaderboards} exact={true} to={dashboardRoute} />

          <Route
            path={smartRoute.associateLeaderboard}
            render={props =>
              validateRoute(userInfoData, props.location.pathname) ? (
                <AssociateLeaderboardWrapper
                  // {...props}
                  config={dashboardConfig}
                  // associateMetaSchema={associateMetaSchema}
                  associateLastUpdatedOn={associateLastUpdatedOn}
                  modulesNavigation={smartModulesNavigation}
                  dashboardsNavigation={smartDashboardsNavigation}
                  leaderboardType={leaderboardType.associate}
                  isFiscalDateFilter={healthStatusConfig.isFiscalDateFilter}
                  api={smartDashboardApi}
                  getAssociateLeaderboardData={this.props.getAssociateLeaderboardData}
                  getAssociateLeaderboardMetaDataSchema={this.props.getAssociateLeaderboardMetaDataSchema}
                  startEndDate={startEndDate}
                  showExportLoader={isExporting}
                  showLoaderOnExport={this.props.showLoaderOnExport}
                  hideLoaderFromExport={this.props.hideLoaderFromExport}
                  exportLeaderboardDataCsv={this.onAssociateLeaderboardCsvExport}
                  trackName={pageName.replenishmentAssociateLeaderboard}
                  getNotifications={this.getNotifications}
                />
              ) : <NoAccess />
            }
          />
          <Route
            path={smartRoute.storeLeaderboard}
            render={props =>
              validateRoute(userInfoData, props.location.pathname) ? (
                <StoreLeaderBoardWrapper
                  {...props}
                  config={dashboardConfig}
                  storeMetaSchema={storeMetaSchema}
                  storeLastUpdatedOn={storeLastUpdatedOn}
                  modulesNavigation={smartModulesNavigation}
                  dashboardsNavigation={smartDashboardsNavigation}
                  leaderboardType={leaderboardType.store}
                  isFiscalDateFilter={healthStatusConfig.isFiscalDateFilter}
                  api={smartDashboardApi}
                  getStoreLeaderboardMetaDataSchema={this.props.getStoreLeaderboardMetaDataSchema}
                  getStoreLeaderboardData={this.props.getStoreLeaderboardData}
                  startEndDate={startEndDate}
                  showExportLoader={isExporting}
                  showLoaderOnExport={this.props.showLoaderOnExport}
                  hideLoaderFromExport={this.props.hideLoaderFromExport}
                  exportLeaderboardDataCsv={this.onStoreLeaderboardCsvExport}
                  trackName={pageName.replenishmentStoreLeaderboardPage}
                  getNotifications={this.getNotifications}
                  resetFilters={this.resetFilters}
                />
              ) : <NoAccess />
            }
          />
          <Route
            path={smartRoute.statusDashboard}
            render={props =>
              validateRoute(userInfoData, props.location.pathname) ? (
                <SmartStatus
                  {...props}
                  config={dashboardConfig.filtersConfig}
                  modulesNavigation={smartModulesNavigation}
                  isFiscalDateFilter={healthStatusConfig.isFiscalDateFilter}
                  isExporting={isExporting}
                  triggerExport={triggerExport}
                />
              ) : <NoAccess />
            }
          />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state: IAppState) => {
  const { userInfoData, userAuthorized } = state.commonReducer;
  const { storeLeaderboard, associateLeaderboard, notifications, dateRangePicker, geos, filters: leaderboardFilters } = state.omni;
  const { lastUpdatedOn: storeLastUpdatedOn, metaData: storeSchema } = storeLeaderboard;
  const { lastUpdatedOn: associateLastUpdatedOn } = associateLeaderboard;
  const { storeMetaSchema } = storeSchema;
  // const { associateMetaSchema } = associateSchema;
  return {
    geos,
    dateRangePicker,
    userInfoData,
    notifications,
    userAuthorized,
    associateLastUpdatedOn,
    storeLastUpdatedOn,
    // associateMetaSchema,
    leaderboardFilters,
    storeMetaSchema,
    // showExportLoader,
    storeLeaderboard,
    associateLeaderboard
  };
};

const mapDispatchToProps = (dispatch: any): any => {
  return bindActionCreators({
    getGeoData,
    getAssociateLeaderboardData,
    getAssociateLeaderboardMetaDataSchema,
    getStoreLeaderboardMetaDataSchema,
    getStoreLeaderboardData,
    showLoaderOnExport,
    hideLoaderFromExport,
    getSmartLeaderboardNotifications,
    updateGeoFilterData
  }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(exportManager(SmartDashboard));