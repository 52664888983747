
import { ValueFormat, IFieldConfig } from './omniConstants';

interface IFieldConfigSmart extends IFieldConfig {
  details?: string;
  tipRule?: string;
}

interface IReplenishmentFields {
  [anyProp: string]: IFieldConfigSmart;
}

interface IAllFieldsConfig {
  Replenishment: IReplenishmentFields;
}

interface IHealthStatusConstants {
  FieldsConfig: IAllFieldsConfig;
}

export const DailySummaryRowsTitles = {
  queueWorkedPercentageActual: 'Queue Worked',
  unitsNotReplenished: 'Units Not Picked'
};

export const AuditsViewDetailsLabel = {
  replenishment: 'REPLENISHMENT',
  others: 'OTHERS'
};

export const HealthStatusDashboardConstants: IHealthStatusConstants = {
  FieldsConfig: {
    Replenishment: {
      uph: {
        label: "Units per hour",
        showLastYear: true
      },
      goalUph: {
        label: "Goal ${goal} UPH"
      },
      replenAuditCompletedPercentageActual: {
        label: "Replenishment Audits Complete",
        showLastYear: true,
        valueFormat: ValueFormat.PERCENT
      },
      replenAuditCompletedPercentageGoal: {
        label: "Goal ${goal}%"
      },
      replenAuditsCreatedActual: {
        label: "Replenishment Audits Created",
        showLastYear: true,
        hasViewDetails: true,
        details: "Replenishment Audits Created"
      },
      replenAuditsCompletedActual: {
        label: "Replenishment Audits Complete",
        showLastYear: true
      },
      auditCompletedPercentageActual: {
        label: "All Audits Complete",
        showLastYear: true,
        valueFormat: ValueFormat.PERCENT
      },
      auditCompletedPercentageGoal: {
        label: "Goal ${goal}%"
      },
      auditsCreatedActual: {
        label: "All Audits Created",
        showLastYear: true,
        hasViewDetails: true,
        details: "Audits Created"
      },
      auditsCompletedActual: {
        label: "All Audits Completed",
        showLastYear: true
      },
      otherAuditCompletedPercentageActual: {
        label: "Other Audits Complete",
        showLastYear: true,
        valueFormat: ValueFormat.PERCENT
      },
      otherAuditCompletedPercentageGoal: {
        label: "Goal ${goal}%"
      },
      otherAuditsCreatedActual: {
        label: "Other Audits Created",
        showLastYear: true,
        hasViewDetails: true
      },
      otherAuditsCompletedActual: {
        label: "Other Audits Completed",
        showLastYear: true
      },
      unitsReplenished: {
        label: "Units Replenished",
        showLastYear: true
      },
      unitsInQueue: {
        label: "Units in Queue",
        showLastYear: true
      },
      estimatedWorkloadGoal: {
        label: "Estimated Workload",
        showLastYear: true,
        valueFormat: ValueFormat.HOURS
      },
      estimatedWorkloadActual: {
        label: "Estimated Workload",
        showLastYear: true,
        valueFormat: ValueFormat.HOURS
      },
      estimatedWorkloadActualGoalSingle: {
        label: "your store's 30-day UPH"
      },
      estimatedWorkloadActualGoalMulti: {
        label: "your stores' 30-day UPH"
      },
      queueWorkedPercentageActual: {
        label: "of Queue Worked",
        showLastYear: true,
        valueFormat: ValueFormat.PERCENT
      },
      queueWorkedPercentageGoal: {
        label: "Goal ${goal}%",
        showLastYear: true
      },
      skuNsfCount: {
        label: "SKUs not on sales floor (NSF)",
        showLastYear: true
      },
      skuNsfPercentageActual: {
        label: "SKUs NSF",
        showLastYear: true,
        valueFormat: ValueFormat.PERCENT
      },
      skuNsfPercentageGoal: {
        label: "Goal <= ${goal}%",
        showLastYear: true
      },
      valueAtRisk: {
        label: "Value in Sales Risk",
        showLastYear: true,
        valueFormat: ValueFormat.DOLLARS
      },
      avgDailyQueueSize: {
        label: "Avg. Daily Queue Size",
        showLastYear: true
      },
      avgWeeklyPercentageQueueActual: {
        label: "Avg. Weekly % of Queue Worked",
        showLastYear: true,
        valueFormat: ValueFormat.PERCENT
      },
      avgWeeklyPercentageQueueGoal: {
        label: "Goal ${goal}%",
        showLastYear: true
      },
      priorityBatchesWorkedPercentageActual: {
        label: "Batches Worked from Priority",
        showLastYear: true,
        valueFormat: ValueFormat.PERCENT
      }
    }
  }
};

export const FilterTypes = {
  ['company']: 'company',
  ['stores']: 'storeNumber',
  ['districts']: 'districtNumber',
  ['regions']: 'regionNumber',
  ['territories']: 'territoryNumber'
};

export const HealthStatusSections = {
  replenishment: 'Replenishment',
  dailySummary: 'Daily Summary for current week'
};

export const HealthStatusCsvHeaders = {
  healthKPI: HealthStatusSections.replenishment,
  dailySummaryData: HealthStatusSections.dailySummary
};

export const NOT_FOUND = 'No results found';

export const LINE = '\n';
export const COLUMN = ',';
export const HYPEN = '-';

export const COLUMNS_HEADERS = {
  DEFAULT: ['Current Value', 'Last Year Value', 'Difference'],
  DAILY_SUMMARY: ['Units Not Picked', 'Units Picked', 'Total Units']
};

export const DailySummaryConstants = {
  NO_DATA: '- -',
  LAST_YEAR_INFO: 'Last year data according to fiscal week'
};

export const AvgRowsTypes = {
  district: 'DISTRICT',
  region: 'REGION',
  territory: 'TERRITORY',
  company: 'COMPANY',
  volume: 'VOLUME',
  store: 'STORE'
};

export const PDF_MAX_ROWS = 500;


export const SmartMetricDefinition = {
  AssociateLederboard: [
    {
      title: 'Overall Score',
      content: 'Rank is based on a combination of queue worked, UPH, and audtis completed'
    },
    {
      title: 'Pick UPH Goal',
      content: 'Goal UPH based on batches picked'
    },
    {
      title: '% Audits Complete (Goal 100%)',
      content: 'Percent of audits you have completed'
    }
  ],
  StoreLeaderboard: [
    {
      title: 'Goals Achieved',
      content: 'The amount of goals achieved out of: UPH, Batches Complete, and % Audits Complete'
    },
    {
      title: 'Speed Rank',
      content: 'Speed rank is based on picking UPH'
    },
    {
      title: 'Accuracy Rank',
      content: 'Accuracy Rank is based on audits completed'
    },
    {
      title: 'Queue Worked Rank',
      content: 'Your Queue Worked rank is based on units picked'
    },
    {
      title: '% Batches Worked from Top Priority',
      content: 'Priority batches are the top three batches in your Replenishment queue (P1-P3)'
    }
  ]
};

export const CustomRoles = ['PPD-Admin', 'PPD-SOE', 'PPD-KT'];