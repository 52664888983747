import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import LeaderBoardFilters from '../LeaderBoards/LeaderBoardFilters';
import LeaderBoardCalendar from './LeaderBoardCalendar';
import LeaderBoardNav from '../../LeaderBoardNav';
import ExportDropdown from '../ExportDropdown';
import { ILeaderBoardsHeaderProps } from '../../../types/shared/DashboardItems/LeaderBoards/LeaderBoardsHeader.types';

const LeaderBoardsHeader = ({ getLeaderboardData, onExportToCsvClicked, onExportToPdfClicked, updateDateSelectionTypes, dashboardsNavigation, filtersConfig, showExportLoader, getNotifications, viewByOptions }: ILeaderBoardsHeaderProps) => {
  return (
    <div className="leaderboard-header container-fluid">
      <Row>
        <Col lg={true} xs="12">
          <LeaderBoardFilters
            getLeaderboardData={getLeaderboardData}
            onFilterChange={getNotifications}
            viewByOptions={viewByOptions}
          />
        </Col>
        <Col lg="auto" xs="12" style={{ minWidth: '330px' }}>
          <Row>
            <Col sm="auto" xs="12">
              <ExportDropdown
                isExporting={showExportLoader}
                onExportToCsv={onExportToCsvClicked}
                onExportToPdf={onExportToPdfClicked}
                {...filtersConfig.exportConfig.leaderboards}
              />
            </Col>
            <Col lg={true} xs="12">
              <LeaderBoardCalendar
                updateDateSelectionTypes={updateDateSelectionTypes}
                getLeaderboardData={getLeaderboardData}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <LeaderBoardNav navigation={dashboardsNavigation} />
    </div>
  );
};

export default LeaderBoardsHeader;
