export enum ExportFormatType {
  Csv = "CSV",
  Pdf = "PDF"
}
export enum ExportProduct {
  Omni = "OMNI",
  Smart = "SMART"
}
export enum ExportDashbordType {
  Status = "STATUS",
  StoreLeaderboard = "STORES_LEADERBOARD",
  AssociateLeaderboard = "ASSOCIATE_LEADERBOARD"
}
interface IExportQueryParams {
  type: ExportFormatType;
  product: ExportProduct;
  dashboard: ExportDashbordType;
}
interface IExportBodyParams {
  [anyKey: string]: any;
}
export interface IExportParams {
  url?: string;
  queryParams: IExportQueryParams;
  bodyParams?: IExportBodyParams | null;
}

export interface IPropsByExportManager {
  isExporting: boolean;
  triggerExport: (exportParams: IExportParams) => void;
  cancelExport: () => void;
}