import * as React from 'react';
import * as moment from "moment";
import { exportLeaderboardToPdf } from '../../../../../utility/smartUtils';
import LeaderBoardsHeader from '../LeaderBoardsHeader';
import LeaderBoardTableHeader from '../LeaderBoardTableHeader';
import LeaderboardTable from '../LeaderboardTable';

const winWidth = window.innerWidth;

const StoreColumnWidth = {
  total: Math.floor(winWidth * 0.49),
  speed: winWidth * 0.48,
  accuracy: winWidth * 0.48,
  execution: winWidth
};

class DesktopStoreLeaderBoardWrapper extends React.PureComponent<any, any> {

  /**
   * Export method is removed from coverage report
   * because it will be totally removed after switch
   * to service side export will be finished
   */
  /* istanbul ignore next */
  exportLeaderboardDataPdf = () => {
    const { metaData: { storeMetaSchema }, rowsData } = this.props;
    this.props.showLoaderOnExport();
    exportLeaderboardToPdf(storeMetaSchema, rowsData, `StoreLeaderboard_${moment().format()}`).then(() => {
      this.props.hideLoaderFromExport();
    });
  }

  render() {
    const {
      rowsData,
      metaData,
      territories,
      getLeaderboardData,
      updateDateSelectionTypes,
      dashboardsNavigation,
      filtersConfig,
      metricDefinition,
      showExportLoader,
      onGridSort,
      sortColumn,
      sortDirection,
      stickyRowsIdxs,
      getNotifications,
      exportLeaderboardDataCsv
    } = this.props;

    return (
      <>
        <div>
          <LeaderBoardsHeader
            showExportLoader={showExportLoader}
            getLeaderboardData={getLeaderboardData}
            onExportToCsvClicked={exportLeaderboardDataCsv}
            onExportToPdfClicked={this.exportLeaderboardDataPdf}
            updateDateSelectionTypes={updateDateSelectionTypes}
            dashboardsNavigation={dashboardsNavigation}
            filtersConfig={filtersConfig}
            viewByOptions={filtersConfig.viewByOptions}
            getNotifications={getNotifications}
          />

          <div className="leaderboard store-leaderboard container-fluid">
            <LeaderBoardTableHeader metricDetails={metricDefinition.StoreLeaderboard} />
            {metaData && metaData.storeMetaSchema && metaData.storeMetaSchema.columns && (
              <div className="leaderboard-grid">
                <LeaderboardTable
                  columns={metaData.storeMetaSchema.columns}
                  rowsData={rowsData}
                  headerHeight={136}
                  columnWidths={StoreColumnWidth}
                  territories={territories}
                  onGridSort={onGridSort}
                  sortColumn={sortColumn}
                  sortDirection={sortDirection}
                  stickyRowsIdxs={stickyRowsIdxs}
                />
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default DesktopStoreLeaderBoardWrapper;