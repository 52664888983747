import { combineReducers } from "redux";
import commonReducer from "./reducer";
import realTimeStore from "./reducers/realTimeDashboardReducer";
import manifestReducer from './reducers/manifestOverview';
import inboundFreight from "./reducers/inbountFrieght";
import postTruckAnalysis from './reducers/postTruckAnalysis';
import omniReducer from "./reducers/omni";
import { omniHealthStatusReducer } from "./reducers/omniHealthStatus";
import homePageReducer from "../store/reducers/homePageReducer";
import { smartHealthStatusReducer } from "./reducers/smartHealthStatus";
import freightHomePageReducer from "./reducers/freightHomePageReducer";
import omniCalendarReducer from "./reducers/omniCalendarReducer";

const combinedReducer = combineReducers({
  commonReducer,
  realTimeStore,
  manifest: manifestReducer,
  inboundFreight,
  postTruckAnalysis,
  homePage: homePageReducer,
  freightHome: freightHomePageReducer,
  omni: omniReducer,
  omniHealthStatus: omniHealthStatusReducer,
  smartHealthStatus: smartHealthStatusReducer,
  omniCalendar: omniCalendarReducer
});

export default combinedReducer;
