import { env } from "../envConfig";

const config = {
  url: env.REACT_APP_ADMIN_SERVICE
};

export function log(args: any) {
  return send(args, "info");
}

export function error(args: any) {
  if (args.error && args.error.config && args.error.config.headers) {
    delete args.error.config.headers;
  }
  if (args.error && args.error.response && args.error.response.config && args.error.response.config.headers) {
    delete args.error.response.config.headers;
  }
  return send(args, "error");
}

export function warn(args: any) {
  return send(args, "warning");
}

export const info = log;

export function send(incomingPayload: any, severity: any) {
  const payload = {
    hdr: {
      appName: "PPD-Client",
      level: severity,
      browser: window.navigator.appVersion,
      timestamp: new Date(),
      UTCtimestamp: new Date().toUTCString()
    },
    bdy: {
      msg: incomingPayload
    }
  };

  return fetch(config.url + '/logging', {
    body: JSON.stringify(payload),
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    }
  }).catch(e => {
    console.log("Splunk Request Failed");
    console.log(e);
  });
}

export const SplunkConsole = {
  log,
  info,
  error,
  warn
};
