import * as React from "react";
import ReactSelect from "react-select";

class AdvancedSelect extends React.PureComponent<any, any> {
  static defaultProps = {
    isClearable: true
  };

  constructor(props: any) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  getOptionByValue = (value: string = "") => {
    const { options, valueKey } = this.props;
    const option = options.find((o: any) => {
      const optionValue = o[valueKey];
      return optionValue ? optionValue.toString() === value.toString() : false;
    });
    return option || {};
  }

  handleChange = (value: any) => {
    const { name, onChange } = this.props;
    return onChange({ name, value: value || {} });
  }

  labelFormatter = (option: any) => {
    if (!option || Object.keys(option).length === 0) {
      return "";
    }
    const { labelKey, labelFormatter } = this.props;
    return labelFormatter ? labelFormatter(option) : option[labelKey];
  }

  render() {
    const {
      name,
      value,
      valueKey,
      labelKey,
      labelFormatter,
      isObjectValue,
      ...rest
    } = this.props;
    const selectedOption = isObjectValue ? value : this.getOptionByValue(value);

    return (
      <ReactSelect
        styles={{
          menu: (defaultStyle: any) => ({ ...defaultStyle, zIndex: 11 })
        }}
        {...rest}
        value={selectedOption}
        onChange={this.handleChange}
        getOptionLabel={this.labelFormatter}
        getOptionValue={(option: any) => option[valueKey]}
      />
    );
  }
}

export default AdvancedSelect;
