import { AnyAction } from "redux";
import { HealthStatusActions } from "../actionTypes";
import { AGGREGATION_OPTIONS, AGGREGATION_OPTION_VALUES } from "../../constants/omniConstants";
import { startEndDate } from "../actions/omniAction";
import { DateSelectionTypes } from "../../constants/DateRangePickerConstant";
import { IHealthStatusState, VIEW_BY } from "./types/omniHealthStatus.types";

const { startDate, endDate } = startEndDate(DateSelectionTypes.Today);

const initalState: IHealthStatusState = {
  locationType: AGGREGATION_OPTIONS.find((o: any) => o.value === AGGREGATION_OPTION_VALUES.Store) || null,
  location: null,
  viewLastYear: false,
  viewBy: VIEW_BY.UNITS,
  selectedDateType: DateSelectionTypes.Today,
  startDate,
  endDate,
  isLoading: false,
  healthData: null,
  lastYearData: null
};

export const omniHealthStatusReducer = (state: IHealthStatusState = initalState, action: AnyAction) => {
  switch (action.type) {
    case HealthStatusActions.UPDATE_LOCATION_TYPE:
      return {
        ...state,
        locationType: action.payload,
        location: null,
        healthData: null
      };
    case HealthStatusActions.UPDATE_LOCATION:
      return {
        ...state,
        location: action.payload
      };
    case HealthStatusActions.TOGGLE_VIEW_LAST_YEAR:
      return {
        ...state,
        viewLastYear: !state.viewLastYear
      };
    case HealthStatusActions.TOGGLE_VIEW_BY:
      return {
        ...state,
        viewBy: state.viewBy === VIEW_BY.UNITS ? VIEW_BY.AMOUNT : VIEW_BY.UNITS,
        healthData: null,
        lastYearData: null
      };
    case HealthStatusActions.UPDATE_SELECTED_DATES:
      return {
        ...state,
        ...action.payload
      };
    case HealthStatusActions.GET_HEALTH_STATUS_DATA:
      return {
        ...state,
        isLoading: true,
        healthData: null
      };
    case HealthStatusActions.GET_HEALTH_STATUS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        healthData: action.payload
      };
    case HealthStatusActions.GET_HEALTH_STATUS_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        healthData: null
      };
    case HealthStatusActions.GET_HEALTH_STATUS_LAST_YEAR_DATA:
      return {
        ...state,
        isLoading: true,
        lastYearData: null
      };
    case HealthStatusActions.GET_HEALTH_STATUS_LAST_YEAR_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lastYearData: action.payload
      };
    case HealthStatusActions.GET_HEALTH_STATUS_LAST_YEAR_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        lastYearData: null
      };
    case HealthStatusActions.CLEAR_HEALTH_STATUS_LAST_YEAR_DATA:
      return {
        ...state,
        lastYearData: null
      };
    default:
      return { ...state };
  }
};