import * as React from "react";
import { connect } from 'react-redux';
import VerticalBar from "../../../components/shared/VerticalBar/VerticalBar";
import { DailySummaryRowsTitles, DailySummaryConstants } from '../../../constants/smartConstants';
import { cs } from '../../../utility/Common';
import { IDailySummaryProps, IState, IWeek } from "../../types/SmartDashboard/HealthStatus/DailySummary.types";

export class DailySummary extends React.Component<IDailySummaryProps, any> {
  state: IState = {
    maxBarsRange: 0,
    week: [
      {
        day: 'Sun',
        data: null
      },
      {
        day: 'Mon',
        data: null
      },
      {
        day: 'Tue',
        data: null
      },
      {
        day: 'Wed',
        data: null
      },
      {
        day: 'Thu',
        data: null
      },
      {
        day: 'Fri',
        data: null
      },
      {
        day: 'Sat',
        data: null
      }
    ]
  };

  maxValue = (weekDays: IWeek[]) => {
    let max = 0;

    weekDays.forEach(day => {
      const currentValue = day.data ? day.data.totalUnits.currentValue : null;
      if (currentValue) {
        max = currentValue > max ? currentValue : max;
      }
    });

    return max;
  }

  componentDidMount() {
    const { data } = this.props;
    const week = this.state.week;
    if (!data) {
      return;
    }
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < week.length; i++) {
      const keys = Object.keys(data);
      keys.forEach(key => {
        if (week[i].day === key) {
          week[i].data = data[key];
        }
      });
    }
    this.setState({ week });
    this.setState({
      maxBarsRange: this.maxValue(week)
    });
  }

  shouldComponentUpdate(nextProps: IDailySummaryProps, nextState: any) {
    if (!nextProps.data) {
      return false;
    }
    if (
      nextProps.data !== this.props.data ||
      nextState.maxBarsRange !== this.state.maxBarsRange ||
      nextProps.viewLastYear !== this.props.viewLastYear) {
      const week = this.state.week;
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < week.length; i++) {
        const keys = Object.keys(nextProps.data);
        keys.forEach(key => {
          if (week[i].day === key) {
            week[i].data = nextProps.data[key];
          }
        });
      }
      this.setState({ week });
      this.setState({
        maxBarsRange: this.maxValue(week)
      });

      return true;
    }
    return false;
  }

  isValue = (value: number | null, format?: string) => {
    if (value && format) {
      return `${value}${format}`;
    } else if (value && !format) {
      return value;
    } else {
      return DailySummaryConstants.NO_DATA;
    }
  }

  render() {
    const { viewLastYear } = this.props;

    return (
      <div className="daily-summary">{viewLastYear}
        <span className="daily-summary__last-year-info">{DailySummaryConstants.LAST_YEAR_INFO}</span>
        <div className="summary-grid">
          {this.state.week.map((element, i) => {
            const totalUnits = element.data ? element.data.totalUnits : null;
            const unitsReplenished = element.data ? element.data.unitsReplenished : null;
            const unitsNotReplenished = element.data ? element.data.unitsNotReplenished.currentValue : null;
            const queueWorkedPercentageActual = element.data ? element.data.queueWorkedPercentageActual.currentValue : null;

            return (
              <div className={cs("summary-grid__element", { "summary-grid__element--empty": !totalUnits })} key={i}>
                <VerticalBar
                  totalUnits={totalUnits}
                  unitsPicked={unitsReplenished}
                  weekDay={element.day}
                  maxUnits={this.state.maxBarsRange}
                  maxBarHeight={250}
                  viewLastYear={viewLastYear}
                />
                {
                  i === 0 && DailySummaryRowsTitles ? (
                    <>
                      <div className="data-cell-wrapper">
                        <div className="row-badge">
                          <span>{DailySummaryRowsTitles.queueWorkedPercentageActual}</span>
                        </div>
                        <div className="data-cell">
                          {this.isValue(queueWorkedPercentageActual, '%')}
                        </div>
                      </div>
                      <div className="data-cell-wrapper">
                        <div className="row-badge">
                          <span>{DailySummaryRowsTitles.unitsNotReplenished}</span>
                        </div>
                        <div className="data-cell">
                          {this.isValue(unitsNotReplenished)}
                        </div>
                      </div>
                    </>
                  ) : (
                      <>
                        <div className="data-cell">
                          {this.isValue(queueWorkedPercentageActual, '%')}
                        </div>
                        <div className="data-cell">
                          {this.isValue(unitsNotReplenished)}
                        </div>
                      </>
                    )
                }

              </div>
            );
          })}
        </div>
      </div >
    );
  }
}

const mapStateToProps = (state: any) => ({
  dailySummaryData: state.smartHealthStatus.dailySummaryData,
  viewLastYear: state.smartHealthStatus.viewLastYear
});

export default connect<any, any, any>(
  mapStateToProps,
  null
)(DailySummary);