import * as moment from "moment";
import { GEOKEYS } from "../constants/omniConstants";
import { TripStatus } from "../constants";
import { getGroupedTrips } from "../utility/dashboard/inboundFreight";
import { formatDate } from "./Date";
import { AppRoutes } from "../constants/routes";

interface IObject {
  [key: string]: any;
}

export function groupBy(xs: any[], key: string) {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export function sortByDate(objectArray: any[], dateKey: string) {
  objectArray.sort((tripA: any, tripB: any) => {
    if (new Date(tripB[dateKey]) < new Date(tripA[dateKey])) {
      return -1;
    }
    if (new Date(tripB[dateKey]) > new Date(tripA[dateKey])) {
      return 1;
    }
    return 0;
  });
  return objectArray;
}

export function sortArrayByKey(objectArray: any[], valueKey: string) {
  if (!objectArray) {
    return objectArray;
  }
  objectArray.sort((a, b) => a[valueKey] - b[valueKey]);
  return objectArray;
}

export function findInArrayByKey(objectArray: any[], key: string, value: any) {
  return objectArray.find((o: any) => o[key] === value);
}

export function isInRange(x: number, min: number, max: number) {
  return x >= min && x <= max;
}

const hasOwn = {}.hasOwnProperty;
export function cs(...args: any[]) {
  const classes = [];

  for (const arg of args) {
    if (!arg) {
      continue;
    }

    const argType = typeof arg;

    if (argType === 'string' || argType === 'number') {
      classes.push(arg);
    } else if (argType === 'object') {
      for (const key in arg) {
        if (hasOwn.call(arg, key) && arg[key]) {
          classes.push(key);
        }
      }
    }
  }
  return classes.join(' ');
}

export const flattenArray = (acc: any = {}, objectArray: any[], parentInfo: any, keysInfo: any, ...keys: string[]) => {
  if (!parentInfo) {
    parentInfo = {};
  }
  const [rootKey, nextKey, ...restKeys] = keys;

  if (objectArray) {
    objectArray.forEach((item) => {
      if (!acc[rootKey]) {
        acc[rootKey] = [];
      }
      if (!nextKey) {
        acc[rootKey].push({
          ...item,
          ...parentInfo
        });
        return;
      }
      const { [nextKey]: nextNested, ...rest } = item;
      const itemInfo = { ...rest, ...parentInfo };
      acc[rootKey].push(itemInfo);

      flattenArray(acc, nextNested, { ...parentInfo, [keysInfo[rootKey].ValueKey]: item[keysInfo[rootKey].Value] }, keysInfo, nextKey, ...restKeys);
    });
  }

  return acc;
};

export const flattenGeoData = (geoData: any) => {
  if (!geoData) {
    return {};
  }

  return sortGeoData(flattenArray({}, geoData.territories, null, GEOKEYS, 'territories', 'regions', 'districts', 'stores'));
};

export const sortGeoData = (geoData: any) => {
  for (const key in geoData) {
    if (geoData.hasOwnProperty(key) && GEOKEYS[key] && GEOKEYS[key].Value) {
      geoData[key] = sortArrayByKey(geoData[key], GEOKEYS[key].Value);
    }
  }
  return geoData;
};


/**
 * This method can be used to extract values of objects with deep nesting.
 * params: 
 *     path: String
 *     obj: Object from which we want to extract the value 
 * 
 * Example Usage:
 *   1) a = {b:{c:1}}
 *       getValue('b.c', a); Output: 1
 *   2) a = {b: {c: ['one','two']}}
 *       getValue('b.c.0', a); Output: 'one'
 *       getValue('b.c.1', a); Output: 'two'
 *   3) If any property is not present it returns null
 */
export const getValue = (path: string, obj?: IObject | any[], defaultValue: any = null) => (
  path
    .split('.')
    .reduce((acc: any, x: any) =>
      (acc && (acc[x] || acc[x] === 0)) ? acc[x] : defaultValue, obj
    )
);

/**
 * To chcek whether value is not null or undefined.
 */
export const hasValue = (v: any) => typeof v !== "undefined" && v !== null && v !== "Invalid date";

export const getFormattedTimeStamp = (lastUpdatedOn?: any) => {
  // const momentObject = moment.utc(lastUpdatedOn).subtract({ hours: -6 }); // Removing UTC conversion as per PRODST-1716
  // return `${momentObject.format("hh:mm")} CST ${momentObject.format("MM/DD/YY")}`;
  const timeStampString = lastUpdatedOn ? new Date(lastUpdatedOn).toLocaleString() : new Date().toLocaleString();
  return timeStampString;
};

export const nextTrailerDetails = (trips: any) => {
  const scheduledDateArray: any = trips.map((element: any) => element.scheduledDate);

  const sortScheduleDateArray = scheduledDateArray.sort((a: any, b: any) => a.localeCompare(b));

  const filteredScheduleDateArray = sortScheduleDateArray.filter((date: any) => !moment(date).isBefore(formatDate(new Date()), 'day'));

  const tripsByDate = getGroupedTrips(trips);

  const trailerObject: any = tripsByDate[filteredScheduleDateArray[0]];

  const { forecasted, manifested, manifestedPercent, delivered } = trailerObject.projections;
  const units = delivered ? delivered.units : manifested ? manifested.units : forecasted.units;

  const scheduledDate = moment(getValue("scheduledDate", trailerObject)).format('ddd, MM/DD');

  return {
    nextTrailerDate: hasValue(scheduledDate) ? scheduledDate : 'TBD',
    nextTrailerStatus: hasValue(scheduledDate) ? TripStatus[getValue("tripStatus", trailerObject)].label : '-',
    nextTrailerUnits: hasValue(scheduledDate) ? units : '-',
    nextTrailerPlanPercent: hasValue(scheduledDate) ? manifested ? `${manifestedPercent}%` : '' : '-'
  };
};

export const validateRoute = (userData: object | any, path: string) => {
  const { userRole } = userData;
  const routes = userRole && userRole.tools.filter((toolRoute: any) => (AppRoutes.ClientRoute + toolRoute.link) === path);
  if (routes && routes.length > 0) {
    return true;
  }
  return false;
};
export const valueFormatter = (value: number) => {
  const absValue = Math.abs(value);
  if (absValue > 999999) {
    const calculated: number = (Math.abs(value) / 1000000);
    return (Math.sign(value) * calculated).toFixed(1) + 'm';
  }
  if (absValue > 999) {
    const calculated: number = (Math.abs(value) / 1000);
    return (Math.sign(value) * calculated).toFixed(1) + 'k';
  }
  return value;
};

export const numberFormatter = (value: number) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const truncateDecimalPlaces = (value: number, decimalPlaces: number): number => {
  if (!value) {
    return value;
  }
  const regexPattern = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimalPlaces}})?`);
  const regexValue = value.toString().match(regexPattern);
  return regexValue ? parseFloat(regexValue[0]) : value;
};

export const calculateTrendVariation = (originalValue: any, variationPercent: any) => {
  return Math.round(Number(originalValue) + Number((originalValue * variationPercent)) / 100);
};

export const arrayToObject = (arr: any[], key: string) => {
  return arr.reduce((map, obj) => {
    map[obj[key]] = obj;
    return map;
  }, {});
};