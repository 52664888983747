import { Dispatch } from 'redux';
import * as moment from 'moment';
import { IAppState } from '../../types';
import { DateSelectionTypes } from '../../constants/DateRangePickerConstant';
import * as ActionTypes from '../actionTypes';
import { omniDashboardApi } from '../../api/omniDashboardApi';
import { getValue, arrayToObject } from '../../utility/Common';
import { formatMomentDate, DateFormats } from '../../utility/Date';
import { AnyAction } from 'redux';
import { AxiosError } from 'axios';
import { IOmniNotification } from '../reducers/types/omni.types';
import { GEOKEYS, AGGREGATION_OPTION_VALUES } from '../../constants/omniConstants';
import { VIEW_BY_OMNI_CONSTANTS } from '../../constants/commonConstants';
import { getViewBySelectedFilters, getSelectedFilters } from '../../utility/dashboard/filters';

export const startEndDate = (selectedDayType: any, fiscalDates?: any): any => {
  const previousDay = moment().subtract(1, 'days').format(DateFormats.YYYYMMDD);
  switch (selectedDayType) {
    case DateSelectionTypes.Today:
      return {
        startDate: formatMomentDate(),
        endDate: formatMomentDate()
      };
    case DateSelectionTypes.Yesterday:
      return {
        startDate: previousDay,
        endDate: previousDay
      };
    case DateSelectionTypes.WeekToDate:
      return {
        startDate: getValue('week.beginDate', fiscalDates),
        endDate: getValue('week.beginDate', fiscalDates) === previousDay ? formatMomentDate() : previousDay
      };
    case DateSelectionTypes.MonthToDate:
      return {
        startDate: getValue('month.beginDate', fiscalDates),
        endDate: getValue('month.beginDate', fiscalDates) === previousDay ? formatMomentDate() : previousDay
      };
    case DateSelectionTypes.QuarterToDate:
      return {
        startDate: getValue('quarter.beginDate', fiscalDates),
        endDate: getValue('quarter.beginDate', fiscalDates) === previousDay ? formatMomentDate() : previousDay
      };
    case DateSelectionTypes.YearToDate:
      return {
        startDate: getValue('year.beginDate', fiscalDates),
        endDate: getValue('year.beginDate', fiscalDates) === previousDay ? formatMomentDate() : previousDay
      };
    case DateSelectionTypes.PeakToDate:
      return {
        startDate: getValue('peak.beginDate', fiscalDates),
        endDate: getValue('peak.endDate', fiscalDates)
      };
    case DateSelectionTypes.TWO_WEEKS_FROM_TODAY:
      return {
        startDate: moment().day(0).format(DateFormats.YYYYMMDD),
        endDate: moment().day(0).add(20, 'days').format(DateFormats.YYYYMMDD)
      };
    case DateSelectionTypes.CURRENT_MONTH:
      return {
        startDate: moment().format('YYYY-MM-01'),
        endDate: moment().format("YYYY-MM-") + moment().daysInMonth()
      };
  }
};

export function getAssociateLeaderboardMetaDataSchema(queryParam: any): AnyAction {
  return {
    type: ActionTypes.GET_ASSOCIATE_LEADERBOARD_METADATA,
    payload: omniDashboardApi.getLeaderboardMetaDataSchema(queryParam)
  };
}

const flattenArray = (a: any[], key: string) => {
  const o = {};
  a.forEach((ele: any) => {
    o[ele[key]] = ele;
  });
  return o;
};

export const flattenStoreAggregatedData = (aggData: any) => {
  return {
    company: { ...aggData.data[0], type: 'COMPANY', locationNumber: `Kohl's`, locationName: 'Average', territoryNumber: '- All', territoryName: '', regionNumber: '- All', regionName: '', districtNumber: '- All', districtName: '' },
    territories: flattenArray(aggData.territories, 'territoryNumber'),
    regions: flattenArray(aggData.regions, 'regionNumber'),
    districts: flattenArray(aggData.districts, 'districtNumber')
  };
};

export function getAssociateLeaderboardData(queryString: any): (dispatch: Dispatch<any>, getState: () => IAppState) => Promise<void> {
  return async (dispatch: Dispatch<any>, getState: () => IAppState) => {
    dispatch({ type: ActionTypes.GET_ASSOCIATE_DATA });
    const { omni } = getState();
    const requestParams = { ...queryString, dateType: omni.dateRangePicker.selectedDateType };
    omniDashboardApi.getCompanyAssociateLeaderboardData(requestParams)
      .then((aggregatedData: any) => {
        dispatch({
          type: ActionTypes.GET_ASSOCIATE_AGGREGATE_DATA_SUCCESS,
          payload: {
            company: aggregatedData.data[0],
            territories: arrayToObject(aggregatedData.territories, "territoryNumber"),
            regions: arrayToObject(aggregatedData.regions, "regionNumber"),
            districts: arrayToObject(aggregatedData.districts, "districtNumber"),
            stores: arrayToObject(aggregatedData.stores, "storeNumber")
          }
        });
      }).catch();
    return omniDashboardApi.getAssociateLeaderboardData(requestParams)
      .then((associateData: any) => {
        dispatch({ type: ActionTypes.GET_ASSOCIATE_DATA_SUCCESS, payload: associateData });
      }).catch(() => {
        dispatch({ type: ActionTypes.GET_ASSOCIATE_DATA_FAILED, payload: null });
      });
  };
}

export function getStoreLeaderboardMetaDataSchema(queryParam: any): AnyAction {
  return {
    type: ActionTypes.GET_STORE_LEADERBOARD_METADATA,
    payload: omniDashboardApi.getLeaderboardMetaDataSchema(queryParam)
  };
}

export function getStoreLeaderboardData(requestParams: any): (dispatch: Dispatch<any>, getState: () => IAppState) => Promise<void> {
  return async (dispatch: Dispatch<any>, getState: () => IAppState) => {

    const { omni } = getState();
    if (!requestParams.homeScreen) {
      requestParams = { ...requestParams, dateType: omni.dateRangePicker.selectedDateType };
    }
    dispatch({ type: ActionTypes.GET_STORE_DATA });

    if (!requestParams.homeScreen) {
      omniDashboardApi.getCompanyStoreLeaderboardData(requestParams)
        .then((aggregatedData) => {
          dispatch({
            type: ActionTypes.GET_STORE_AGGREGATE_DATA_SUCCESS,
            payload: flattenStoreAggregatedData(aggregatedData)
          });
        });
    }

    return omniDashboardApi.getStoreLeaderboardData(requestParams)
      .then((storeData: any) => {
        dispatch({ type: ActionTypes.GET_STORE_DATA_SUCCESS, payload: storeData });
      }).catch(() => {
        dispatch({ type: ActionTypes.GET_STORE_DATA_FAILED, payload: null });
      });
  };
}
const getOmniNotificationApiParams = (locationType: any, location: any, dateType: string) => {
  const locationTypeValue = locationType ? locationType.value : AGGREGATION_OPTION_VALUES.Store;
  const locationKey = GEOKEYS[locationTypeValue].ValueKey;
  return {
    dateType,
    [locationKey]: [location[locationKey]],
    viewBy: [locationType ? VIEW_BY_OMNI_CONSTANTS[locationTypeValue] : VIEW_BY_OMNI_CONSTANTS.stores]
  };
};
export function getNotificationsData(queryString: any, location: any, locationType: any, dateType: string = DateSelectionTypes.MonthToDate): (dispatch: Dispatch<any>) => Promise<void> {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: ActionTypes.GET_NOTIFICATION_DATA });
    return omniDashboardApi.getOmniNotifications(queryString)
      .then((notifications: IOmniNotification[]) => {
        dispatch<any>(getOmniNotificationsData(getOmniNotificationApiParams(locationType, location, dateType), location, locationType, notifications));
      }).catch(() => {
        dispatch<any>(getOmniNotificationsData(getOmniNotificationApiParams(locationType, location, dateType), location, locationType, null));
      });
  };
}

function getOmniNotificationsData(queryString: any, location: any, locationType: any, notificationsData?: IOmniNotification[] | null): (dispatch: Dispatch<any>) => Promise<void> {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: ActionTypes.GET_NOTIFICATION_DATA });
    return omniDashboardApi.getOmniPositiveNotifications(queryString)
      .then((positiveNotifications: IOmniNotification[]) => {
        let notifications = notificationsData;
        if (positiveNotifications && positiveNotifications.length > 0) {
          positiveNotifications = positiveNotifications.map(n => ({ ...n, isPositive: true }));
          notifications = notifications ? notifications.concat(positiveNotifications) : positiveNotifications;
        }
        dispatch({ type: ActionTypes.GET_NOTIFICATION_SUCCESS, payload: { notifications, location, locationType } });
      }).catch((err: AxiosError) => {
        if (notificationsData) {
          dispatch({ type: ActionTypes.GET_NOTIFICATION_SUCCESS, payload: { notifications: notificationsData, location, locationType } });
        } else {
          dispatch({ type: ActionTypes.GET_NOTIFICATION_FAILED, payload: { notificationsError: err.message, location, locationType } });
        }
      });
  };
}

export const getLeaderboardNotifications = () => (dispatch: Dispatch<any>, getState: () => IAppState) => {
  const { omni } = getState();
  const { filters, dateRangePicker } = omni;
  const { viewBy } = filters;

  const selectedFilters = getSelectedFilters(viewBy, filters);
  const apiParams = {
    dateType: dateRangePicker.selectedDateType,
    viewBy: getViewBySelectedFilters(viewBy, VIEW_BY_OMNI_CONSTANTS),
    ...selectedFilters
  };
  dispatch(
    getOmniNotificationsData(apiParams, null, null, null)
  );
};

export const removeNotificationItem = (id: any) => (dispatch: Dispatch<AnyAction>) => {
  dispatch({
    type: ActionTypes.REMOVE_NOTIFICATION_ITEM,
    payload: {
      itemId: id
    }
  });
};

export const removeAllNotificationItem = () => (dispatch: Dispatch<AnyAction>) => {
  dispatch({
    type: ActionTypes.REMOVE_ALL_NOTIFICATION_ITEMS,
    payload: {
    }
  });
};

export const markItemSelected = (id: any) => (dispatch: Dispatch<AnyAction>) => {
  dispatch({
    type: ActionTypes.MARK_NOTIFICATION_ITEM_SELECTED,
    payload: {
      itemId: id
    }
  });
};

export const updateNotificationData = () => (dispatch: Dispatch<AnyAction>) => {
  dispatch({
    type: ActionTypes.UPDATE_NOTIFICATION_DATA,
    payload: {
    }
  });
};

export const resetOmniNotificationData = () => (dispatch: Dispatch<AnyAction>) => {
  dispatch({ type: ActionTypes.RESET_NOTIFICATION_DATA });
};

export const sortAssociateLeaderboardData = (sortColumn: string, sortDirection: string) => {
  return {
    type: ActionTypes.SORT_ASSOCIATE_LEADERBOARD_DATA,
    payload: {
      sortColumn,
      sortDirection
    }
  };
};

export const sortStoreLeaderboardData = (sortColumn: string, sortDirection: string) => {
  return {
    type: ActionTypes.SORT_STORE_LEADERBOARD_DATA,
    payload: {
      sortColumn,
      sortDirection
    }
  };
};