import * as moment from "moment";
import { Moment, isMoment } from "moment";

export const DateFormats = {
  "YYYYMMDD": 'YYYY-MM-DD',
  "dddMMDD": 'ddd, MM/DD',
  "MMDD": 'MM/DD'
};

/**
 * returns formatted date as string
 * @param date Should be string or instance of Date
 * @param format Default: YYYY-MM-DD, Use constant -> DateFormats
 * @param separator Default: "-"
 * @param useUtc Default: true
 */
export function formatDate(date: string | Date | Moment = '', format: string = DateFormats.YYYYMMDD, separator: string = '-', useUtc: boolean = false) {

  let dateToFormat;
  if (date && typeof date === 'string') {
    dateToFormat = new Date(date);
  } else if (date instanceof Date) {
    dateToFormat = date;
  } else if (isMoment(date)) {
    return date.format(format);
  } else {
    dateToFormat = new Date();
  }

  switch (format) {
    case DateFormats.YYYYMMDD:
      return formatToYyyyMmDd(dateToFormat, separator, useUtc);
    case DateFormats.dddMMDD:
      return formatTodddMMDD(dateToFormat, separator, useUtc);
    case DateFormats.MMDD:
      return formatToMMDD(dateToFormat, separator, useUtc);
  }
  return '';
}

function formatToMMDD(date: Date, separator: string, useUtc: boolean) {
  const day = useUtc ? date.getUTCDate() : date.getDate();
  const month = (useUtc ? date.getUTCMonth() : date.getMonth()) + 1;
  return `${month < 10 ? '0' + month : month}${separator}${day < 10 ? '0' + day : day}`;
}

function formatTodddMMDD(date: Date, separator: string, useUtc: boolean) {
  const day = useUtc ? date.getUTCDay() : date.getDay();
  const monthDate = useUtc ? date.getUTCDate() : date.getDate();
  const month = (useUtc ? date.getUTCMonth() : date.getMonth()) + 1;
  return `${getWeekDay(day)}, ${month < 10 ? '0' + month : month}${separator}${monthDate < 10 ? '0' + monthDate : monthDate}`;
}

const weekdays = new Array(
  "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"
);

function getWeekDay(day: number) {
  return weekdays[day];
}

function formatToYyyyMmDd(date: Date, separator: string, useUtc: boolean) {
  const day = useUtc ? date.getUTCDate() : date.getDate();
  const month = (useUtc ? date.getUTCMonth() : date.getMonth()) + 1;
  const year = useUtc ? date.getUTCFullYear() : date.getFullYear();
  return `${year}${separator}${month < 10 ? '0' + month : month}${separator}${day < 10 ? '0' + day : day}`;
}


export const TimeFormats = {
  'HHMM': 'HH:MM',
  'DDHHMM': 'DD:HH:MM'
};

export const TimeFormatInputTypes = {
  SECONDS: 'SECONDS',
  MINUTES: 'MINUTES',
  HHMMSS: 'HH:MM:SS'
};

export function formatTime(input: string | number, inputType: string, format: string) {
  switch (inputType) {
    case TimeFormatInputTypes.SECONDS:
      if (typeof input === 'string') {
        input = Number.parseInt(input, 10);
      }
      return formatTimeFromSeconds(input, format);
    case TimeFormatInputTypes.MINUTES:
      if (typeof input === 'string') {
        input = Number.parseInt(input, 10);
      }
      return formatTimeFromMins(input, format);
    case TimeFormatInputTypes.HHMMSS:
      return formatTimeFromSeconds(hmsToSeconds(input.toString()), format);
  }
  return '';
}

function formatTimeFromSeconds(seconds: number, format: string) {
  const mins = Math.floor(seconds / 60);
  return formatTimeFromMins(mins, format);
}

function formatTimeFromMins(mins: number, format: string) {
  const fn = (num: number) => {
    return (num < 10 ? '0' : '') + num;
  };
  let d;
  let h;
  let m;
  switch (format) {
    case TimeFormats.HHMM:
      h = Math.floor(mins / 60);
      m = Math.floor(mins % 60);
      return `${fn(h)}:${fn(m)}`;
    case TimeFormats.DDHHMM:
    default:
      d = Math.floor(mins / 24 / 60);
      h = Math.floor(mins / 60) % 24;
      m = Math.floor(mins % 60);
      return `${d > 0 ? fn(d) + ':' : ''}${fn(h)}:${fn(m)}`;
  }
}

/**
 * Takes time string HH:MM:SS as input and return number of seconds
 * Eg: "01:00:00" will return 3600
 * Eg: "06:30:00" will return (6 * 3600 + 30 * 60) = 23400
 * @param s Time String in HH:MM:SS format
 * @returns Seconds
 */
export function hmsToSeconds(s: string): number {
  const b: any[] = s.split(':');
  return b[0] * 3600 + b[1] * 60 + (+b[2] || 0);
}

export function sortByDate(s: any[]): any[] {
  s.sort((a, b) => {
    const x = new Date(a.scheduledDate);
    const y = new Date(b.scheduledDate);
    return x.getTime() - y.getTime();
  });
  return s;
}

/** 
 * This function returns value in format "YYYY-MM-DD".
 * Returns today's date if no argument passed.
 */
export const formatMomentDate = (date?: string | Moment) => {
  let dateToFormat;
  if (!date) {
    dateToFormat = moment();
  } else if (isMoment(date)) {
    dateToFormat = date;
  } else {
    dateToFormat = moment(date);
  }

  return dateToFormat.format(DateFormats.YYYYMMDD);
};