import * as React from 'react';
import * as moment from 'moment';

import '../../assets/styles/DateRangePicker.scss';
import CalendarPicker from './CalendarPicker';
import { DateSelection, DateSelectionTypes } from '../../constants/DateRangePickerConstant';
import AdvancedSelect from '../../components/dashboards/ManifestOverview/AdvancedSelect';

class DateRangePicker extends React.PureComponent<any, any>{
  static defaultProps = {
    numberOfMonths: 2
  };
  constructor(props: any) {
    super(props);
    this.state = {
      isCalendarOpen: false
    };
  }

  hideCalendar = () => {
    this.setState({ isCalendarOpen: false });
  }

  handleDateChange = ({ value: selectedOption }: any) => {
    if (!selectedOption) {
      return;
    }
    if (selectedOption.value === DateSelectionTypes.CUSTOM_DATE_RANGE) {
      this.setState({ isCalendarOpen: true });
    } else {
      this.setState({ isCalendarOpen: false });
      this.props.updateDateSelectionTypes(selectedOption.value);
    }
  }

  labelFormatter = (option: any) => {
    const { startDate, endDate, selectedDateType } = this.props;
    const momentStartDate = startDate && moment(startDate).format("MM/DD/YYYY");
    const momentEndDate = endDate && moment(endDate).format("MM/DD/YYYY");

    return (option.value === DateSelectionTypes.CUSTOM_DATE_RANGE && selectedDateType === DateSelectionTypes.CUSTOM_DATE_RANGE) ? (
      <span>
        {option.label}
        {startDate && endDate && (
          <span style={{ fontSize: "10px", display: "block", textOverflow: "ellipsis", overflow: "hidden" }}>
            {momentStartDate} - {momentEndDate}
          </span>
        )}
      </span>
    ) : (option.label);
  }

  render() {
    const { startDate, endDate, selectedDateType, updateCalendarStartEndDate, numberOfMonths } = this.props;
    const { isCalendarOpen } = this.state;

    return (
      <div className="custom-date-picker">
        <AdvancedSelect
          name="select-date"
          options={DateSelection}
          labelKey="label"
          valueKey="value"
          value={selectedDateType || ''}
          isClearable={false}
          isSearchable={false}
          onChange={this.handleDateChange}
          onMenuOpen={this.hideCalendar}
          labelFormatter={this.labelFormatter}
          styles={{
            control: () => ({
              borderWidth: '0',
              display: 'flex',
              justifyContent: 'flex-end'
            }),
            indicatorSeparator: () => ({
              color: 'white'
            }),
            menuList: () => ({
              minHeight: '320px'
            }),
            valueContainer: () => ({
              display: 'flex',
              alignItems: 'center'
            }),
            singleValue: () => ({}),
            menu: (defaultStyle: any) => ({ ...defaultStyle, minWidth: '150px', right: 0 })
          }}
        />
        {isCalendarOpen &&
          <CalendarPicker
            startDate={startDate}
            endDate={endDate}
            numberOfMonths={numberOfMonths}
            selectedDateType={selectedDateType}
            updateCalendarStartEndDate={updateCalendarStartEndDate}
            onBackButtonClick={this.hideCalendar}
          />}
      </div>
    );
  }
}

export default DateRangePicker;