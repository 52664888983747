import * as React from 'react';
import { FixedSizeList as List } from 'react-window';
import { cs } from '../../../utility/Common';
import { CustomCellValue, SubColumnHeaderCell } from 'cx-ppd-client-components';

const ITEM_SIZE = 65;
const ROW_CLASS = "mobile-table-row";

export const renderBaseRow = ({ column, row, rowIdx }: any) => {
  return column.children.map((subColumn: any) => {
    return (
      <div key={subColumn.key} className="mobile-table-cell">
        <CustomCellValue column={subColumn} row={row} rowIdx={rowIdx} />
      </div>
    );
  });
};

export const RowRenderer = (props: any) => {
  const { index: rowIdx, data, style } = props;
  const { column, rowGetter, rowRenderer } = data;

  const row = rowGetter(rowIdx);

  if (!row) {
    return null;
  }

  return rowRenderer ?
    rowRenderer({ column, row, renderBaseRow, className: ROW_CLASS, style }) : (
      <div key={rowIdx} className={ROW_CLASS} style={style}>
        {renderBaseRow({ column, row, rowIdx })}
      </div>
    );
};

export interface IMobileDataTableProps {
  columns: any[];
  sortColumn: string;
  sortDirection: string;
  onGridSort: () => void;
  rowsData: any[];
  stickyRows?: any[];
  rowRenderer?: (props: any) => React.ReactElement;
}
class MobileDataTable extends React.Component<IMobileDataTableProps, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      activeKey: props.columns && props.columns[0].key
    };
  }

  rowGetter = (i: number) => {
    const { rowsData } = this.props;

    return rowsData ? rowsData[i] : {};
  }

  render() {
    const { columns, rowRenderer, rowsData, stickyRows, sortColumn, sortDirection, onGridSort } = this.props;
    const { activeKey } = this.state;
    const activeColumnIndex = columns.findIndex((element: any) => element.key === activeKey);
    const activeColumn = columns[activeColumnIndex];

    return (
      <div className="mobile-data-table">
        <div className="column-headers">
          {columns.map((column: any) => {
            return (
              <div
                key={column.key}
                className="headerIcon"
                onClick={() => this.setState({ activeKey: column.key })}
              >
                {column.image && <img
                  src={require(`../../../assets/images/mobile-table-icon/${column.image}${activeKey === column.key ? `-active` : ""}.png`)}
                />}
              </div>
            );
          })}
        </div>
        <div className="slider-tab" style={{ left: ((activeColumnIndex) * 100 / columns.length) + '%', width: (100 / columns.length) + '%' }} />
        {
          activeColumn && (
            <div className="sub-columns">
              {activeColumn.children.map((childColumn: any) => {
                return (
                  <SubColumnHeaderCell
                    key={childColumn.key}
                    className="subColumn-header"
                    column={childColumn}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    onSort={onGridSort}
                  />);
              })}
            </div>
          )
        }
        {
          stickyRows && stickyRows.length > 0 && stickyRows.map((row: any, rowIdx: number) => {
            return rowRenderer ?
              rowRenderer({ column: activeColumn, row, renderBaseRow, className: ROW_CLASS, style: { height: ITEM_SIZE } }) : (
                <div key={rowIdx} className={ROW_CLASS}>
                  {renderBaseRow({ column: activeColumn, row, rowIdx })}
                </div>
              );
          })
        }
        <div className={cs("table-row-container", { "no-data-height": rowsData.length === 0 })}>
          {rowsData.length > 0 ? (
            <List
              height={rowsData.length > 6 ? 500 : 150}
              width={100 + '%'}
              itemCount={rowsData.length}
              itemSize={ITEM_SIZE}
              itemData={{
                column: activeColumn,
                rowGetter: this.rowGetter,
                rowRenderer
              }}
            >
              {RowRenderer}
            </List>
          ) : <div className="noDataFound">No results found</div>
          }
        </div>
      </div>
    );
  }
}

export default MobileDataTable;