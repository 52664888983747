
const isPromise = (promise: any) => promise && typeof promise.then === "function";

export function asyncActions({ dispatch }: any) {
  return (next: any) => (action: any) => {
    if (isPromise(action.payload)) {
      action.payload
        .then(
          (response: any) => dispatch({
            type: action.type + '_SUCCESS',
            payload: action.onSuccess ? action.onSuccess(response) : response
          })
        )
        .catch(
          (error: any) => dispatch({
            type: action.type + '_FAILED',
            error
          })
        );
    }

    return next(action);
  };
}
