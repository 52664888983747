import { Dispatch, Action } from "redux";
import { getmanageToolsData, getmanageRolesData, getUserInfo, postTools } from "../api/index";
import * as actionTypes from './actionTypes';

export interface IActionWithPayload extends Action {
  payload?: any;
}

export function createReduxAction(type: any, payload: any): IActionWithPayload {
  return { type, payload };
}

export function getToolsData(): (dispatch: Dispatch<IActionWithPayload>) => Promise<void> {
  return async (dispatch: Dispatch<IActionWithPayload>) => {
    getmanageToolsData().then(data => {
      dispatch(createReduxAction(actionTypes.GET_MANAGE_TOOLS_DATA, data));
    });
  };
}

export function getRolesData(): (dispatch: Dispatch<IActionWithPayload>) => Promise<void> {
  return async (dispatch: Dispatch<IActionWithPayload>) => {
    getmanageRolesData().then(data => {
      dispatch(createReduxAction(actionTypes.GET_MANAGE_ROLES_DATA, data));
    });
  };
}

export function getUserInfoData(): (dispatch: Dispatch<IActionWithPayload>) => Promise<void> {
  return async (dispatch: Dispatch<IActionWithPayload>) => {
    dispatch(createReduxAction(actionTypes.GET_USER_INFO_DATA, null));
    return getUserInfo()
      .then(data => {
        if (data.error) {
          dispatch(createReduxAction(actionTypes.GET_USER_INFO_DATA_ERROR, data));
        } else {
          dispatch(createReduxAction(actionTypes.GET_USER_INFO_DATA_SUCCESS, data));
        }
      }).catch(error => {
        dispatch(createReduxAction(actionTypes.GET_USER_INFO_DATA_ERROR, error));
      });
  };
}

export function updateTools(roleId: any, requestData: any): (dispatch: Dispatch<IActionWithPayload>) => Promise<void> {
  return async (dispatch: Dispatch<IActionWithPayload>) => {
    postTools(roleId, requestData).then(data => {
      dispatch(createReduxAction(actionTypes.PUT_TOOLS_DATA, data));
    });
  };
}
