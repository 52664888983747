import { RecordType } from "./associateLeaderboard";
import { sortRows } from "cx-ppd-client-components";
import { AGGREGATION_OPTION_VALUES } from "../../constants/omniConstants";
import { IPpdDashboardFilters, IViewBy } from "../../store/reducers/types/omni.types";

const LeaderboardRankKpis = [
  "overallRank",
  "speedRank",
  "sfsUphRank",
  "bopusUphRank",
  "bossUphRank",
  "accuracyRank",
  "sfsResourceRateRank",
  "bopusCancelRateRank",
  "executionRank",
  "sfsUnitsProcessedIn1DayRateRank",
  "bopusUnitsProcessedIn1HourRateRank"
];

export const createStoreLeaderboardTableData = (storesData: any[] | null, aggregatedData: any, filters: IPpdDashboardFilters, sortColumn?: string, sortDirection?: string) => {
  if (!storesData) {
    storesData = [];
  }

  const rows: any[] = [{ ...aggregatedData.company, ...getOmniVirtualFields(aggregatedData.company) }];
  const { viewBy } = filters;
  if (viewBy.find((viewByOption: IViewBy) => viewByOption.value === AGGREGATION_OPTION_VALUES.Volume)) {
    const sortedVolumes = getByVolume(aggregatedData, sortDirection);
    sortedVolumes.forEach((volume: any) => {
      const { volumeNumber, volumeName } = volume;
      const aggregatedDataByVolume = { ...volume, type: RecordType.VOLUME, locationName: volumeNumber, locationNumber: `Volume`, regionName: ' All -', territoryNumber: '', territoryName: ' All -', regionNumber: '', districtNumber: '', districtName: ' All -', storeNumber: '', storeName: ' All -', volumeNumber, volumeName };
      if (Object.keys(aggregatedDataByVolume).length > 0) {
        rows.push(aggregatedDataByVolume);
      }
    });
  }
  return rows.concat(getByTerritories(storesData, aggregatedData, filters, sortColumn, sortDirection));
};

export const unionArrayByKey = (superSet: any[], subSet: any[], key: string) => {
  return superSet.map((a: any) => subSet[a[key]] ? { ...a, ...subSet[a[key]] } : a);
};

const getApplicableRankType = (locationType: string, viewBy: IViewBy[]) => {
  if (locationType === RecordType.REGION) {
    if (viewBy.find((viewByOption) => viewByOption.value === AGGREGATION_OPTION_VALUES.Territory)) {
      return RecordType.TERRITORY;
    }
  }
  if (locationType === RecordType.DISTRICT) {
    if (viewBy.find((viewByOption) => viewByOption.value === AGGREGATION_OPTION_VALUES.Region)) {
      return RecordType.REGION;
    }
    if (viewBy.find((viewByOption) => viewByOption.value === AGGREGATION_OPTION_VALUES.Territory)) {
      return RecordType.TERRITORY;
    }
  }
  if (locationType === RecordType.STORE) {
    if (viewBy.find((viewByOption) => viewByOption.value === AGGREGATION_OPTION_VALUES.District)) {
      return RecordType.DISTRICT;
    }
    if (viewBy.find((viewByOption) => viewByOption.value === AGGREGATION_OPTION_VALUES.Region)) {
      return RecordType.REGION;
    }
    if (viewBy.find((viewByOption) => viewByOption.value === AGGREGATION_OPTION_VALUES.Territory)) {
      return RecordType.TERRITORY;
    }
  }
  return RecordType.NONE;
};

function getApplicableRankKey<T>(origKey: T, rankType?: RecordType): T | string {
  switch (rankType) {
    case RecordType.TERRITORY:
      return `${origKey}InTerritory`;
    case RecordType.REGION:
      return `${origKey}InRegion`;
    case RecordType.DISTRICT:
      return `${origKey}InDistrict`;
    default:
      return origKey;
  }
}

const getLocationKey = (recordType?: RecordType) => {
  switch (recordType) {
    case RecordType.TERRITORY:
      return `territoryNumber`;
    case RecordType.REGION:
      return `regionNumber`;
    case RecordType.DISTRICT:
      return `districtNumber`;
    case RecordType.STORE:
      return `storeNumber`;
  }
  return;
};

const getSortColumnKey = (origKey?: string, rankType?: RecordType, recordType?: RecordType) => {
  switch (origKey) {
    case "locationNumber":
      return getLocationKey(recordType);
    case "overallRank":
    case "speedRank":
    case "sfsUphRank":
    case "bopusUphRank":
    case "bossUphRank":
    case "accuracyRank":
    case "sfsResourceRateRank":
    case "bopusCancelRateRank":
    case "executionRank":
    case "sfsUnitsProcessedIn1DayRateRank":
    case "bopusUnitsProcessedIn1HourRateRank":
      return getApplicableRankKey(origKey, rankType);
    default:
      return origKey;
  }
};

const getOmniVirtualFields = (row?: any, rankType?: RecordType) => {
  if (!row) {
    return {};
  }

  const virtualFields = {};
  LeaderboardRankKpis.forEach(rankKpiKey => {
    if (typeof row[rankKpiKey] === "undefined") {
      return;
    }
    virtualFields[`${rankKpiKey}Virtual`] = row[getApplicableRankKey(rankKpiKey, rankType)];
  });
  return virtualFields;
};

const getByVolume = (aggregatedData: any, sortDirection: any) => {
  const { volumes } = aggregatedData;
  if (!volumes) {
    return [];
  }
  const volumesArray: any[] = [];
  for (const v in volumes) {
    if (volumes.hasOwnProperty(v)) {
      volumesArray.push(volumes[v]);
    }
  }
  const sortedVolumes = sortRows(volumesArray, "volumeNumber", sortDirection);
  return sortedVolumes;
};

const getByTerritories = (storesData: any[], aggregatedData: any, filters: IPpdDashboardFilters, sortColumn?: string, sortDirection?: string) => {
  const { territories, viewBy } = filters;
  if (territories.length === 0) {
    return [];
  }

  if (!viewBy.find((viewByOption: IViewBy) => viewByOption.value === AGGREGATION_OPTION_VALUES.Territory)) {
    return getByRegions(storesData, aggregatedData, filters, sortColumn, sortDirection);
  }
  const rankType = RecordType.NONE;
  let rows: any[] = [];
  const allTerritories = unionArrayByKey(territories, aggregatedData.territories, "territoryNumber");
  const sortedTerritories = sortRows(allTerritories, getSortColumnKey(sortColumn, rankType, RecordType.TERRITORY), sortDirection);

  sortedTerritories.forEach((territory: any) => {
    const { territoryNumber, territoryName } = territory;
    const aggregatedDataByTerritory = { ...aggregatedData.territories[territoryNumber], type: RecordType.TERRITORY, locationNumber: `T${territoryNumber}`, locationName: 'Average', regionNumber: '', regionName: ' All -', districtNumber: '', districtName: ' All -', storeNumber: '', storeName: ' All -', territoryNumber, territoryName, ...getOmniVirtualFields(aggregatedData.territories[territoryNumber], rankType) };

    const stores = getByRegions(storesData, aggregatedData, filters, sortColumn, sortDirection, territoryNumber);

    if (Object.keys(aggregatedDataByTerritory).length > 0) {
      rows.push(aggregatedDataByTerritory);
    }
    if (stores) {
      rows = rows.concat(stores);
    }
  });

  return rows;
};

const getByRegions = (storesData: any[], aggregatedData: any, filters: IPpdDashboardFilters, sortColumn?: string, sortDirection?: string, territoryNumber?: number) => {
  const { regions, viewBy } = filters;
  if (regions.length === 0) {
    return [];
  }

  /**
   * If view by regions is not selected, return districts
   */
  if (!viewBy.find((viewByOption: IViewBy) => viewByOption.value === AGGREGATION_OPTION_VALUES.Region)) {
    return getByDistricts(storesData, aggregatedData, filters, sortColumn, sortDirection, territoryNumber);
  }

  const rankType = getApplicableRankType(RecordType.REGION, viewBy);
  let rows: any[] = [];
  const allRegions = unionArrayByKey(regions, aggregatedData.regions, "regionNumber");
  const filteredRegions = territoryNumber ? allRegions.filter((region: any) => region.territoryNumber === territoryNumber) : allRegions;
  const sortedRegions = sortRows(filteredRegions, getSortColumnKey(sortColumn, rankType, RecordType.REGION), sortDirection);

  sortedRegions.forEach((region: any) => {
    const regionNumber = region.regionNumber;
    const aggregatedDataByRegion = { ...aggregatedData.regions[regionNumber], type: RecordType.REGION, locationNumber: `R${regionNumber}`, locationName: 'Average', districtNumber: '', districtName: ' All -', storeNumber: '', storeName: ' All -', ...getOmniVirtualFields(aggregatedData.regions[regionNumber], rankType) };

    const stores = getByDistricts(storesData, aggregatedData, filters, sortColumn, sortDirection, territoryNumber, regionNumber);
    if (Object.keys(aggregatedDataByRegion).length > 0) {
      rows.push(aggregatedDataByRegion);
    }
    if (stores) {
      rows = rows.concat(stores);
    }
  });

  return rows;
};

const getByDistricts = (storesData: any[], aggregatedData: any, filters: IPpdDashboardFilters, sortColumn?: string, sortDirection?: string, territoryNumber?: number, regionNumber?: number) => {
  const { districts, viewBy } = filters;
  if (districts.length === 0) {
    return [];
  }

  if (!viewBy.find((viewByOption: IViewBy) => viewByOption.value === AGGREGATION_OPTION_VALUES.District)) {
    return getStores(storesData, filters, sortColumn, sortDirection, territoryNumber, regionNumber);
  }

  const rankType = getApplicableRankType(RecordType.DISTRICT, viewBy);
  let rows: any[] = [];
  const allDistricts = unionArrayByKey(districts, aggregatedData.districts, "districtNumber");
  const filteredDistricts = regionNumber ? allDistricts.filter((district: any) => district.regionNumber === regionNumber) : (territoryNumber ? allDistricts.filter((district: any) => district.territoryNumber === territoryNumber) : allDistricts);
  const sortedDistricts = sortRows(filteredDistricts, getSortColumnKey(sortColumn, rankType, RecordType.DISTRICT), sortDirection);

  sortedDistricts.forEach((district: any) => {
    const districtNumber = district.districtNumber;
    const aggregatedDataByDistrict = { ...aggregatedData.districts[districtNumber], type: RecordType.DISTRICT, locationNumber: `D${districtNumber}`, locationName: 'Average', storeNumber: '', storeName: ' All -', ...getOmniVirtualFields(aggregatedData.districts[districtNumber], rankType) };
    const stores = getStores(storesData, filters, sortColumn, sortDirection, territoryNumber, regionNumber, districtNumber);

    if (Object.keys(aggregatedDataByDistrict).length > 0) {
      rows.push(aggregatedDataByDistrict);
    }
    if (stores) {
      rows = rows.concat(stores);
    }
  });

  return rows;
};

const getStores = (storesData: any[], filters: IPpdDashboardFilters, sortColumn?: string, sortDirection?: string, territoryNumber?: number, regionNumber?: number, districtNumber?: number) => {
  const { stores, viewBy } = filters;

  if (!viewBy.find((viewByOption: IViewBy) => viewByOption.value === AGGREGATION_OPTION_VALUES.Store) || stores.length === 0) {
    return [];
  }

  const rankType = getApplicableRankType(RecordType.STORE, viewBy);
  let storeRows = [];
  if (districtNumber) {
    storeRows = storesData.filter((a: any) => a.districtNumber === districtNumber && (stores.length > 0 ? stores.findIndex((filterStore: any) => a.storeNumber === filterStore.storeNumber) > -1 : true));
  } else if (regionNumber) {
    storeRows = storesData.filter((a: any) => a.regionNumber === regionNumber && (stores.length > 0 ? stores.findIndex((filterStore: any) => a.storeNumber === filterStore.storeNumber) > -1 : true));
  } else if (territoryNumber) {
    storeRows = storesData.filter((a: any) => a.territoryNumber === territoryNumber && (stores.length > 0 ? stores.findIndex((filterStore: any) => a.storeNumber === filterStore.storeNumber) > -1 : true));
  } else {
    storeRows = storesData.filter((store: any) => stores.findIndex((filterStore: any) => store.storeNumber === filterStore.storeNumber) > -1);
  }

  return sortRows(storeRows, getSortColumnKey(sortColumn, rankType, RecordType.STORE), sortDirection).map((store: any) => ({ ...store, ...getOmniVirtualFields(store, rankType) }));
};
