import * as React from "react";
import { Row, Col } from 'react-bootstrap';
import { PPDPane } from 'cx-ppd-client-components';
import { MetricValueAdvance } from "./Common";
import { HealthStatusDashboardConstants } from "../../../constants/smartConstants";
import { NoData } from '../../OmniDashboard/HealthStatus/NoData';
import { IHealthKPI } from "../../../store/reducers/types/smartHealthStatus.types";
import { DateSelectionTypes } from "../../../constants/DateRangePickerConstant";
import { AGGREGATION_OPTION_VALUES } from "../../../constants/omniConstants";

export interface IReplenishmentProps {
  data?: IHealthKPI;
  selectedDateType?: string;
  viewLastYear: boolean;
  locationType: any;
}

export class Replenishment extends React.Component<IReplenishmentProps, any> {

  getEstimatedWorklodActualGoalKey = () => {
    const { locationType } = this.props;
    return locationType === AGGREGATION_OPTION_VALUES.Store ? 'estimatedWorkloadActualGoalSingle' : 'estimatedWorkloadActualGoalMulti';
  }

  isBannerVisible = () => {
    const { selectedDateType } = this.props;
    return selectedDateType !== DateSelectionTypes.Today;
  }

  render() {
    const {
      data,
      viewLastYear
    } = this.props;

    if (!data) {
      return <NoData />;
    }

    return (
      <div className="health-status-section">
        {this.isBannerVisible() &&
          <div className="replanishment-banner">Highlighted metrics do not change when filtering by timeframe</div>
        }
        <Row>
          <Col xs="12" sm="6">
            <Row>
              <Col xs="12" sm="6">
                <PPDPane>
                  <PPDPane.Title>Speed</PPDPane.Title>
                  <PPDPane.Body>
                    <MetricValueAdvance
                      dataKey="uph"
                      goalKey="goalUph"
                      data={data}
                      viewLastYear={viewLastYear}
                      fieldsConfig={HealthStatusDashboardConstants.FieldsConfig.Replenishment}
                    />
                  </PPDPane.Body>
                </PPDPane>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <PPDPane>
                  <PPDPane.Title>Accuracy</PPDPane.Title>
                  <PPDPane.Body>
                    <Row>
                      <Col sm="3">
                        <MetricValueAdvance
                          dataKey="replenAuditCompletedPercentageActual"
                          goalKey="replenAuditCompletedPercentageGoal"
                          data={data}
                          viewLastYear={viewLastYear}
                          fieldsConfig={HealthStatusDashboardConstants.FieldsConfig.Replenishment}
                        />
                      </Col>
                      <Col sm="3">
                        <MetricValueAdvance
                          dataKey="replenAuditsCreatedActual"
                          data={data}
                          viewLastYear={viewLastYear}
                          fieldsConfig={HealthStatusDashboardConstants.FieldsConfig.Replenishment}
                        />
                      </Col>
                      <Col sm="3">
                        <MetricValueAdvance
                          dataKey="replenAuditsCompletedActual"
                          data={data}
                          viewLastYear={viewLastYear}
                          fieldsConfig={HealthStatusDashboardConstants.FieldsConfig.Replenishment}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="3">
                        <MetricValueAdvance
                          dataKey="auditCompletedPercentageActual"
                          goalKey="auditCompletedPercentageGoal"
                          data={data}
                          viewLastYear={viewLastYear}
                          fieldsConfig={HealthStatusDashboardConstants.FieldsConfig.Replenishment}
                        />
                      </Col>
                      <Col sm="3">
                        <MetricValueAdvance
                          dataKey="auditsCreatedActual"
                          data={data}
                          viewLastYear={viewLastYear}
                          fieldsConfig={HealthStatusDashboardConstants.FieldsConfig.Replenishment}
                        />
                      </Col>
                      <Col sm="3">
                        <MetricValueAdvance
                          dataKey="auditsCompletedActual"
                          data={data}
                          viewLastYear={viewLastYear}
                          fieldsConfig={HealthStatusDashboardConstants.FieldsConfig.Replenishment}
                        />
                      </Col>
                    </Row>
                  </PPDPane.Body>
                </PPDPane>
              </Col>
            </Row>
          </Col>
          <Col xs="12" sm="6">
            <PPDPane>
              <PPDPane.Title>Execution</PPDPane.Title>
              <PPDPane.Body>
                <Row className="row-background">
                  <Col sm="3">
                    <MetricValueAdvance
                      dataKey="unitsReplenished"
                      data={data}
                      viewLastYear={viewLastYear}
                      fieldsConfig={HealthStatusDashboardConstants.FieldsConfig.Replenishment}
                    />
                  </Col>
                  <Col sm="3">
                    <MetricValueAdvance
                      dataKey="unitsInQueue"
                      data={data}
                      viewLastYear={viewLastYear}
                      fieldsConfig={HealthStatusDashboardConstants.FieldsConfig.Replenishment}
                    />
                  </Col>
                  <Col sm="3">
                    <MetricValueAdvance
                      dataKey="estimatedWorkloadGoal"
                      goalKey="goalUph"
                      data={data}
                      viewLastYear={viewLastYear}
                      fieldsConfig={HealthStatusDashboardConstants.FieldsConfig.Replenishment}
                    />
                  </Col>
                  <Col sm="3">
                    <MetricValueAdvance
                      dataKey="estimatedWorkloadActual"
                      goalKey={this.getEstimatedWorklodActualGoalKey()}
                      data={data}
                      viewLastYear={viewLastYear}
                      fieldsConfig={HealthStatusDashboardConstants.FieldsConfig.Replenishment}
                    />
                  </Col>
                </Row>
                <Row className="row-background">
                  <Col sm="3">
                    <MetricValueAdvance
                      dataKey="queueWorkedPercentageActual"
                      goalKey="queueWorkedPercentageGoal"
                      data={data}
                      viewLastYear={viewLastYear}
                      fieldsConfig={HealthStatusDashboardConstants.FieldsConfig.Replenishment}
                    />
                  </Col>
                  <Col sm="3">
                    <MetricValueAdvance
                      dataKey="skuNsfCount"
                      data={data}
                      viewLastYear={viewLastYear}
                      fieldsConfig={HealthStatusDashboardConstants.FieldsConfig.Replenishment}
                    />
                  </Col>
                  <Col sm="3">
                    <MetricValueAdvance
                      dataKey="skuNsfPercentageActual"
                      goalKey="skuNsfPercentageGoal"
                      data={data}
                      viewLastYear={viewLastYear}
                      fieldsConfig={HealthStatusDashboardConstants.FieldsConfig.Replenishment}
                    />
                  </Col>
                  <Col sm="3">
                    <MetricValueAdvance
                      dataKey="valueAtRisk"
                      data={data}
                      viewLastYear={viewLastYear}
                      fieldsConfig={HealthStatusDashboardConstants.FieldsConfig.Replenishment}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <MetricValueAdvance
                      dataKey="avgDailyQueueSize"
                      data={data}
                      viewLastYear={viewLastYear}
                      fieldsConfig={HealthStatusDashboardConstants.FieldsConfig.Replenishment}
                    />
                  </Col>
                  <Col sm="3">
                    <MetricValueAdvance
                      dataKey="avgWeeklyPercentageQueueActual"
                      goalKey="avgWeeklyPercentageQueueGoal"
                      data={data}
                      viewLastYear={viewLastYear}
                      fieldsConfig={HealthStatusDashboardConstants.FieldsConfig.Replenishment}
                    />
                  </Col>
                  <Col sm="3">
                    <MetricValueAdvance
                      dataKey="priorityBatchesWorkedPercentageActual"
                      data={data}
                      viewLastYear={viewLastYear}
                      fieldsConfig={HealthStatusDashboardConstants.FieldsConfig.Replenishment}
                    />
                  </Col>
                </Row>
              </PPDPane.Body>
            </PPDPane>
          </Col>
        </Row>
      </div>
    );
  }
}
export default Replenishment;
